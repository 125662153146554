import {
  query,
  orderBy,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase-config";
import moment from "moment";
import * as apiService from "../api-service";

// EVENTS
// Get all events
export const getAllEvents = (companyId) => {
  return getDocs(query(collection(firestore, "companies", companyId, "events")));
};

export const requestNewHoliday = (
  userToken,
  companyData,
  companyId,
  title,
  dateStart,
  dateEnd,
  createdBy,
  employees,
  extraEmployeeIds
) => {
  return addDoc(collection(firestore, "companies", companyId, "events"), {
    title: title,
    date_start: moment(dateStart).startOf("day").toDate(),
    date_end: dateEnd ? moment(dateEnd).endOf("day").toDate() : moment(dateStart).startOf("day").toDate(),
    created_by: createdBy,
    state: 2,
    type: "holiday",
    approved_by: [],
    approve_by_extra: extraEmployeeIds,
    created: serverTimestamp(),
    updated: serverTimestamp(),
  }).then(() =>
    apiService.postSendEmailRaw({
      userIdToken: userToken,
      templateId: companyData["sendgrid"]["template_ids"]["holiday_request"],
      toEmails: [
        ...((companyData["employee_approvals_from"] ?? [])?.filter((e) => e.id === createdBy)[0]?.admins ?? []),
        extraEmployeeIds.map((id) => {
          return employees.filter((emp) => emp.uid === id)[0].email;
        })[0],
      ].map((admin) => {
        return employees
          .filter((em) => admin === em.uid)
          .map((a) => {
            return a.email;
          });
      })[0],
      ccEmails: companyData["emails_cc"],
      holidayStart: moment(dateStart).format("ddd, MMM D YYYY"),
      holidayEnd: dateEnd ? moment(dateEnd).format("ddd, MMM D YYYY") : moment(dateStart).format("ddd, MMM D YYYY"),
      fromName: title,
      fromEmail: companyData["sendgrid"]["from"],
      apiKey: companyData["sendgrid"]["key"],
    })
  );
};

export const approveHoliday = (
  userToken,
  companyData,
  companyId,
  documentId,
  dateStart,
  dateEnd,
  approvedBy,
  complete,
  employees,
  createdBy
) => {
  if (complete) {
    return updateDoc(doc(collection(firestore, "companies", companyId, "events"), documentId), {
      approved_by: approvedBy,
      state: 1,
      updated: serverTimestamp(),
    }).then(() =>
      apiService.postSendEmailRaw({
        userIdToken: userToken,
        templateId: companyData["sendgrid"]["template_ids"]["holiday_approved"],
        toEmails: (
          (companyData["employee_approvals_from"] ?? [])?.filter((e) => e.id === createdBy)[0]?.admins ?? []
        ).map((admin) => {
          return employees
            .filter((em) => admin === em.uid)
            .map((a) => {
              return a.email;
            });
        })[0],
        holidayStart: moment(dateStart).format("ddd, MMM D YYYY"),
        holidayEnd: dateEnd ? moment(dateEnd).format("ddd, MMM D YYYY") : moment(dateStart).format("ddd, MMM D YYYY"),
        ccEmails: companyData["emails_cc"],
        fromEmail: companyData["sendgrid"]["from"],
        apiKey: companyData["sendgrid"]["key"],
      })
    );
  } else {
    return updateDoc(doc(collection(firestore, "companies", companyId, "events"), documentId), {
      approved_by: approvedBy,
      updated: serverTimestamp(),
    });
  }
};
export const declineHoliday = (
  userToken,
  companyData,
  companyId,
  documentId,
  dateStart,
  dateEnd,
  currentUser,
  reason,
  employees,
  createdBy
) => {
  return updateDoc(doc(collection(firestore, "companies", companyId, "events"), documentId), {
    state: 0,
    declined_by: { id: currentUser.uid, name: currentUser.displayName, message: reason },
    updated: serverTimestamp(),
  }).then(() =>
    apiService.postSendEmailRaw({
      userIdToken: userToken,
      templateId: companyData["sendgrid"]["template_ids"]["holiday_declined"],
      toEmails: (
        (companyData["employee_approvals_from"] ?? [])?.filter((e) => e.id === createdBy)[0]?.admins ?? []
      ).map((admin) => {
        return employees
          .filter((em) => admin === em.uid)
          .map((a) => {
            return a.email;
          });
      })[0],
      holidayStart: moment(dateStart).format("ddd, MMM D YYYY"),
      holidayEnd: dateEnd ? moment(dateEnd).format("ddd, MMM D YYYY") : moment(dateStart).format("ddd, MMM D YYYY"),
      fromName: currentUser.displayName,
      declineReason: reason,
      ccEmails: companyData["emails_cc"],
      fromEmail: companyData["sendgrid"]["from"],
      apiKey: companyData["sendgrid"]["key"],
    })
  );
};

export const remindHoliday = (
  userToken,
  companyData,
  companyId,
  documentId,
  title,
  dateStart,
  dateEnd,
  employees,
  createdBy
) => {
  return updateDoc(doc(collection(firestore, "companies", companyId, "events"), documentId), {
    updated: serverTimestamp(),
  }).then(() =>
    apiService.postSendEmailRaw({
      userIdToken: userToken,
      templateId: companyData["sendgrid"]["template_ids"]["holiday_remind"],
      toEmails: (
        (companyData["employee_approvals_from"] ?? [])?.filter((e) => e.id === createdBy)[0]?.admins ?? []
      ).map((admin) => {
        return employees
          .filter((em) => admin === em.uid)
          .map((a) => {
            return a.email;
          });
      })[0],
      fromName: title,
      holidayStart: moment(dateStart).format("ddd, MMM D YYYY"),
      holidayEnd: dateEnd ? moment(dateEnd).format("ddd, MMM D YYYY") : moment(dateStart).format("ddd, MMM D YYYY"),
      ccEmails: companyData["emails_cc"],
      fromEmail: companyData["sendgrid"]["from"],
      apiKey: companyData["sendgrid"]["key"],
    })
  );
};

export const addNewParking = (companyId, title, dateStart, dateEnd, createdBy, spaceId, bookedByAdminName) => {
  var payload = {
    title: title,
    date_start: dateStart,
    date_end: dateEnd,
    created_by: createdBy,
    space: spaceId,
    type: "parking",
    created: serverTimestamp(),
  };

  if (bookedByAdminName.length > 0) {
    payload["created_by_admin_name"] = bookedByAdminName;
  }

  return addDoc(collection(firestore, "companies", companyId, "events"), payload);
};

export const addNewDesk = (companyId, title, dateStart, dateEnd, roomId, createdBy, createdAdmin, createdAdminName) => {
  if (createdAdmin && createdAdminName) {
    return addDoc(collection(firestore, "companies", companyId, "events"), {
      title: title,
      date_start: moment(dateStart).startOf("day").toDate(),
      date_end: dateEnd ? moment(dateEnd).endOf("day").toDate() : moment(dateStart).startOf("day").toDate(),
      created_by: createdBy,
      created_by_admin: createdAdmin,
      created_by_admin_name: createdAdminName,
      room: roomId,
      type: "desk",
      created: serverTimestamp(),
    });
  } else {
    return addDoc(collection(firestore, "companies", companyId, "events"), {
      title: title,
      date_start: moment(dateStart).startOf("day").toDate(),
      date_end: dateEnd ? moment(dateEnd).endOf("day").toDate() : moment(dateStart).startOf("day").toDate(),
      created_by: createdBy,
      room: roomId,
      type: "desk",
      created: serverTimestamp(),
    });
  }
};

export const deleteEvent = (companyId, id) => {
  return deleteDoc(doc(collection(firestore, "companies", companyId, "events"), id));
};

// ANNOUNCEMENTS
// Getting all announcements
export const getAnnouncements = (companyId) => {
  const col = collection(firestore, "companies", companyId, "blog");
  return getDocs(query(col, orderBy("created", "desc")));
};
// Posting a new announcement
export const addAnnouncement = (companyId, title, body, image, color, tags, type, author) => {
  const col = collection(firestore, "companies", companyId, "blog");
  return addDoc(col, {
    title: title,
    body: body,
    image: image,
    color: color,
    tags: tags,
    type: type,
    author: author,
    created: serverTimestamp(),
  });
};
