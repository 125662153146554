import { UnstyledButton, Group, Avatar, Text, createStyles, Modal } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";
import { useState } from "react";
import Profile from "../pages/Users/Profile";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}));

export function UserButton({ didNavigate, image, name, email, icon, currentUser, ...others }) {
  const { classes } = useStyles();

  const [isUserOpen, setUserOpen] = useState(false);

  return (
    <>
      <Modal opened={isUserOpen} onClose={() => setUserOpen(false)} title="Edit Profile" centered size="50%">
        <Profile currentUser={currentUser} />
      </Modal>
      <UnstyledButton className={classes.user} {...others} onClick={() => setUserOpen(true)}>
        <Group>
          <Avatar src={image} radius="xl" />
          <div style={{ flex: 1, maxWidth: 145 }}>
            <Text size="sm" weight={500} lineClamp={1}>
              {name}
            </Text>
            <Text color="dimmed" size="xs" lineClamp={1}>
              {email.substring(0, 30)} {email.length >= 30 && "..."}
            </Text>
          </div>
          {icon || <IconChevronRight color="gray" size="1.25rem" stroke={2} />}
        </Group>
      </UnstyledButton>
    </>
  );
}
