import React from "react";
import { Title, Text, Stack, Flex, Paper, Loader, Button, Group, Accordion, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCircleCheck } from "@tabler/icons";
import moment from "moment";
import { deleteEvent } from "../../../../services/Firestore";
import { ShowNotificationSuccess } from "../../../../components/Notifications";

export default function DrawItemPending({
  companyId,
  companyData,
  currentUser,
  allHolidays,
  employees,
  employeeApprovalsFrom,
  approvalsFrom,
  fetchEvents,
}) {
  const allPendingEvents = allHolidays
    .filter((d) => d.data().state === 2)
    .filter((d) => !approvalsFrom.includes(d.data().created_by))
    .sort(function (a, b) {
      if (a.data().created.toDate() < b.data().created.toDate()) return -1;
      if (a.data().created.toDate() > b.data().created.toDate()) return 1;
      return 0;
    });

  return (
    <>
      {allPendingEvents.length > 0 && (
        <Accordion.Item value="pending">
          <Accordion.Control>
            <Flex justify="space-between" align="center">
              <Stack spacing={0}>
                <Title order={4} weight={800}>
                  Pending
                </Title>
                <Text size="sm" color="dimmed">
                  Holiday you've requested off.
                </Text>
              </Stack>
              <Badge size="lg" color="dark">
                {allPendingEvents.length}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {allPendingEvents.map((d) => {
              return (
                <Paper key={d.id} p="md" withBorder mb="md">
                  <Stack>
                    {moment(d.data().date_end.toDate()).format("DDMMYYYY") ===
                      moment(d.data().date_start.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("dddd, DD MMM yyyy")}
                      </Title>
                    )}
                    {moment(d.data().date_start.toDate()).format("DDMMYYYY") !==
                      moment(d.data().date_end.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("DD MMM yyyy")} -{" "}
                        {moment(d.data().date_end.toDate()).format("DD MMM yyyy")}
                      </Title>
                    )}
                    <Group position="center">
                      {[
                        ...(employeeApprovalsFrom.filter((e) => e.id === d.data().created_by)[0]?.admins ?? []),
                        (companyData["emails_cc"] ?? []).map((rEmail) => {
                          return employees.filter((emp) => emp.email === rEmail)[0]?.uid;
                        })[0],
                      ].map((admin) => {
                        return employees
                          .filter((em) => admin === em.uid)
                          .map((a) => {
                            return (
                              <Flex key={a.uid} align="center" justify="center" direction="column" gap={4}>
                                <Text fw="bold">{a.displayName}</Text>
                                {d.data().approved_by.includes(a.uid) && <IconCircleCheck color="green" />}
                                {!d.data().approved_by.includes(a.uid) && <Loader size="sm" color="orange" />}
                              </Flex>
                            );
                          });
                      })}
                    </Group>

                    {d.data()?.state === 1 && (
                      <Text size="sm" color="dimmed">
                        Approved by{" "}
                        <Text span color="black" fw="bold">
                          {d.data().approved_by_name}
                        </Text>{" "}
                        on{" "}
                        <Text span color="black" fw="bold" align="center">
                          {moment(d.data().updated.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                        </Text>
                      </Text>
                    )}

                    {/* DATE */}
                    {d.data()?.state === 2 && d.data().approved_by.length === 0 && (
                      <Text size="sm" color="dimmed" align="center">
                        Requested on {moment(d.data().created.toDate()).format("ddd, DD MMM YYYY, HH:mm.")}
                      </Text>
                    )}
                    {d.data()?.state === 2 && d.data().approved_by.length > 0 && (
                      <Text size="sm" color="dimmed" align="center">
                        Last update on {moment(d.data().updated.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                      </Text>
                    )}

                    {moment().isBefore(moment(d.data().date_start.toDate())) &&
                      d.data().created_by === currentUser?.uid && (
                        <Button
                          color="red"
                          onClick={() =>
                            modals.openConfirmModal({
                              title: <Text weight={700}>Cancel Holiday</Text>,
                              centered: true,
                              closeOnClickOutside: false,
                              withCloseButton: false,
                              children: (
                                <Text>
                                  You can book these dates again, however, will need to be approved by all admins.
                                </Text>
                              ),
                              labels: { confirm: "Cancel Holiday", cancel: "Keep Holiday" },
                              confirmProps: { color: "red" },
                              onConfirm: () =>
                                deleteEvent(companyId, d.id).then(() => {
                                  modals.closeAll();
                                  fetchEvents();
                                  ShowNotificationSuccess("Holiday Cancelled");
                                }),
                            })
                          }
                        >
                          Cancel Holiday
                        </Button>
                      )}
                  </Stack>
                </Paper>
              );
            })}
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
}
