import { getValue } from "firebase/remote-config";
import { remoteConfig } from "../firebase-config";

export const RemotePrams = {
  HTML_ALLOWED_TAGS: "html_allowed_tags",
  HTML_NOT_ALLOWED_TAGS: "html_invalid_words",
  MAX_PARKING: "max_parking",
  MAX_ROOMS: "max_rooms"
};

export const RemoteDefaultConfig = {
  max_parking: 10,
  max_rooms: 10,
  html_allowed_tags: [
    "HTML",
    "HEAD",
    "BODY",
    "META",
    "STYLE",
    "P",
    "DIV",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "UL",
    "OL",
    "LI",
    "BLOCKQUOTE",
    "B",
    "I",
    "S",
    "U",
    "O",
    "SUP",
    "INS",
    "DEL",
    "STRONG",
    "STRIKE",
    "TT",
    "CODE",
    "BIG",
    "SMALL",
    "BR",
    "SPAN",
    "EM",
  ],
  html_invalid_words: [
    "Apple Wallet Pay",
    "Apple Pay Virtual Wallet",
    "Apple Pay Wallet",
    "�",
    "@font-face",
    "font-family",
    "@list",
    "<!--[if",
    "<![endif]-->",
    ".css",
    ".js",
    ".json",
    ".php",
    ".html",
    ".htm",
    ".aspx",
  ],
};

export function getRemoteString(config) {
  return getValue(remoteConfig, config).asString();
}
export function getRemoteNumber(config) {
  return getValue(remoteConfig, config).asNumber();
}

export function getRemoteBoolean(config) {
  return JSON.parse(getValue(remoteConfig, config).asBoolean());
}

export function getRemoteJSON(config) {
  return JSON.parse(getValue(remoteConfig, config).asString());
}
