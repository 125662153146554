import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  Title,
  Flex,
  Button,
  Stack,
  ActionIcon,
  Group,
  Text,
  Menu,
  Modal,
  HoverCard,
  Tooltip,
  ScrollArea,
  Drawer,
  Loader,
  UnstyledButton,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { DatePickerInput } from "@mantine/dates";
import { deleteEvent, getAllEvents } from "../../services/Firestore";
import moment from "moment";
import {
  IconCalendarDue,
  IconChevronLeft,
  IconChevronRight,
  IconArmchair,
  IconX,
  IconSun,
  IconParking,
  IconCircleCheck,
} from "@tabler/icons";
import dayjs from "dayjs";
import BookerCreateDesk from "./book/desk";
import { capitalizeFirstLetter } from "../../components/Extensions";
import BookerCreateParking from "./book/parking";
import BookerCreateHoliday from "./book/holiday";
import BookerHolidayDraw from "./modules/holidayDraw";
import * as apiService from "../../api-service";
import { isMobile } from "react-device-detect";

export default function Booker2({ companyId, companyData, userClaims, currentUser, userToken, navButton }) {
  const [allEvents, setAllEvents] = useState([]);
  const [isWeekHovered, setWeekHovered] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(moment().toDate());
  const [employees, setEmployees] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bookingTypes = [
    { title: "desk", icon: <IconArmchair />, onClick: () => setIsBookDeskOpen(true) },
    { title: "parking", icon: <IconParking />, onClick: () => setIsBookParkingOpen(true) },
    { title: "holiday", icon: <IconSun />, onClick: () => setIsBookHolidayOpen(true) },
  ];

  useEffect(() => {
    document.title = "Booker - Cell Software Portal";
    setIsHolidayDrawOpen(window.location.href.includes("#manage"));
    navButton(
      <Group spacing={8} grow={isMobile}>
        <Button variant="outline" size="md" color="violet" onClick={() => setIsHolidayDrawOpen(true)}>
          Holidays
        </Button>
        <Menu shadow="md" position="bottom-end" withArrow arrowPosition="center">
          <Menu.Target>
            <Button size="md">Book</Button>
          </Menu.Target>

          <Menu.Dropdown>
            {bookingTypes.map((t) => {
              return (
                <Menu.Item key={t.title} icon={t.icon} onClick={t.onClick}>
                  {capitalizeFirstLetter(t.title)}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </Group>
    );

    return () => {
      navButton(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navButton]);

  useEffect(() => {
    if (companyId) {
      reloadEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    apiService
      .getEmployees({
        userIdToken: userToken,
        companyId: companyId,
      })
      .then((e) =>
        setEmployees(
          e.sort(function (a, b) {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1;
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
            return 0;
          })
        )
      );
  }, [companyId, userToken]);

  const reloadEvents = () =>
    getAllEvents(companyId)
      .then((snapshot) => setAllEvents(snapshot.docs ?? []))
      .catch(console.error);

  const [isBookDeskOpen, setIsBookDeskOpen] = useState(false);
  const [isBookParkingOpen, setIsBookParkingOpen] = useState(false);
  const [isBookHolidayOpen, setIsBookHolidayOpen] = useState(false);
  const [isHolidayDrawOpen, setIsHolidayDrawOpen] = useState(false);

  const [holidaySelectedTab, setHolidaySelectedTab] = useState("approve");

  const [parkingSpaces] = useState(companyData["parking_spaces"] ?? []);
  const [rooms] = useState(companyData["rooms"] ?? []);

  const currentWeek = () => {
    var currentDate = moment(selectedWeek);
    var weekStart = currentDate.clone().startOf("isoWeek");

    var days = [];
    for (var i = 0; i < 5; i++) {
      days.push(moment(weekStart).add(i, "days"));
    }
    return days;
  };
  function getDay(date) {
    const day = date.getDay();
    return day === 0 ? 6 : day - 1;
  }
  function startOfWeek(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - getDay(date) - 1);
  }
  function endOfWeek(date) {
    return dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate() + (4 - getDay(date))))
      .endOf("date")
      .toDate();
  }
  function isInWeekRange(date, value) {
    return value && dayjs(date).isBefore(endOfWeek(value)) && dayjs(date).isAfter(startOfWeek(value));
  }

  return (
    <>
      <Drawer
        opened={isHolidayDrawOpen}
        onClose={() => setIsHolidayDrawOpen(false)}
        title="Manage Holidays"
        position="right"
      >
        <BookerHolidayDraw
          userToken={userToken}
          allEvents={allEvents}
          currentUser={currentUser}
          companyData={companyData}
          companyId={companyId}
          employees={employees}
          fetchEvents={() => reloadEvents()}
          closeDraw={() => setIsHolidayDrawOpen(false)}
          initSelectedTab={holidaySelectedTab}
        />
      </Drawer>
      <Modal
        opened={isBookHolidayOpen}
        closeOnClickOutside={false}
        onClose={() => setIsBookHolidayOpen(false)}
        title="Request Holiday"
        centered
        overlayProps={{
          opacity: 0.7,
          blur: 4,
        }}
      >
        <BookerCreateHoliday
          userToken={userToken}
          companyId={companyId}
          companyData={companyData}
          rooms={rooms}
          userClaims={userClaims}
          currentUser={currentUser}
          allEvents={allEvents}
          employees={employees}
          selectedWeek={selectedWeek}
          onCompletion={() => {
            reloadEvents();
            setIsBookHolidayOpen(false);
          }}
        />
      </Modal>
      <Modal
        opened={isBookDeskOpen}
        closeOnClickOutside={false}
        onClose={() => setIsBookDeskOpen(false)}
        title="Book A Desk"
        centered
        overlayProps={{
          opacity: 0.7,
          blur: 4,
        }}
      >
        <BookerCreateDesk
          companyId={companyId}
          rooms={rooms}
          userClaims={userClaims}
          currentUser={currentUser}
          allEvents={allEvents}
          userToken={userToken}
          selectedWeek={selectedWeek}
          employees={employees}
          onCompletion={() => {
            setIsBookDeskOpen(false);
            reloadEvents();
          }}
        />
      </Modal>
      <Modal
        opened={isBookParkingOpen}
        closeOnClickOutside={false}
        onClose={() => setIsBookParkingOpen(false)}
        title="Book A Parking Space"
        centered
        overlayProps={{
          opacity: 0.7,
          blur: 4,
        }}
      >
        <BookerCreateParking
          companyId={companyId}
          parkingSpace={parkingSpaces}
          allEvents={allEvents}
          onCompletion={() => {
            setIsBookParkingOpen(false);
            reloadEvents();
          }}
          userClaims={userClaims}
          selectedWeek={selectedWeek}
          currentUser={currentUser}
          employees={employees}
        />
      </Modal>
      <Stack>
        <Paper shadow="md" p="md" withBorder>
          <Flex justify={isMobile ? "space-evenly" : "space-between"} align="center">
            <Group spacing={8}>
              <Button variant="light" leftIcon={<IconCalendarDue />} onClick={() => setSelectedWeek(moment().toDate())}>
                Today
              </Button>
              <Text fw={900}>
                <DatePickerInput
                  value={selectedWeek}
                  valueFormat="MMMM"
                  variant="default"
                  excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                  onChange={setSelectedWeek}
                  withCellSpacing={false}
                  getDayProps={(date) => {
                    const isHovered = isInWeekRange(date, isWeekHovered);
                    const isSelected = isInWeekRange(date, selectedWeek);
                    const isInRange = isHovered || isSelected;
                    return {
                      onMouseEnter: () => setWeekHovered(date),
                      onMouseLeave: () => setWeekHovered(null),
                      inRange: isInRange,
                      firstInRange: isInRange && date.getDay() === 1,
                      lastInRange: isInRange && date.getDay() === 5,
                      selected: isSelected,
                      onClick: () => setSelectedWeek(date),
                    };
                  }}
                />
              </Text>
              <Group spacing={0}>
                <ActionIcon
                  variant="subtle"
                  color="dimmed"
                  size="lg"
                  onClick={() => {
                    var currentDate = moment(selectedWeek).subtract(1, "week");
                    setSelectedWeek(currentDate.clone().startOf("isoWeek").toDate());
                  }}
                >
                  <IconChevronLeft />
                </ActionIcon>
                <ActionIcon
                  variant="subtle"
                  color="dimmed"
                  size="lg"
                  onClick={() => {
                    var currentDate = moment(selectedWeek).add(1, "week");
                    setSelectedWeek(currentDate.clone().startOf("isoWeek").toDate());
                  }}
                >
                  <IconChevronRight />
                </ActionIcon>
              </Group>
            </Group>
          </Flex>
        </Paper>
        <ScrollArea>
          <Table withColumnBorders withBorder>
            <thead>
              <tr>
                <th />
                {currentWeek().map((d) => {
                  return (
                    <th
                      key={moment(d).format("DDMMYYYY")}
                      style={{
                        backgroundColor: `${
                          moment(d).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "#FFF5F5" : "transparent"
                        }`,
                      }}
                    >
                      <Stack spacing={0}>
                        <Title
                          color={moment(d).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "red" : "black"}
                          fw={moment(d).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "bolder" : "bold"}
                        >
                          {d.format("DD")}
                        </Title>
                        <Title
                          color={moment(d).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "red" : "dimmed"}
                          order={3}
                          fw={moment(d).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "bold" : "normal"}
                        >
                          {d.format("dddd")}
                        </Title>
                      </Stack>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {bookingTypes.map((t) => {
                return (
                  <tr key={t.title}>
                    <td>
                      <Flex gap={8} justify="center" align="center">
                        {t.icon}
                        <Title order={3}>{capitalizeFirstLetter(t.title)}</Title>
                      </Flex>
                    </td>
                    {currentWeek().map((wd) => {
                      return (
                        <td
                          key={moment(wd).format("DDMMYYYY")}
                          style={{
                            padding: 16,
                            backgroundColor: `${
                              moment(wd).format("DDMMYYYY") === moment().format("DDMMYYYY") ? "#FFF5F5" : "transparent"
                            }`,
                          }}
                        >
                          <Stack justify="flex-start" spacing="xs">
                            {/* DESK */}

                            {rooms
                              .sort(function (a, b) {
                                if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
                                if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
                                return 0;
                              })
                              .map((rs) => {
                                return (
                                  allEvents
                                    .filter((d) => d.data().type === t.title)
                                    .filter(
                                      (d) =>
                                        moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                                          moment(wd).format("DDMMYYYY") ||
                                        moment(wd).isBetween(
                                          moment(d.data().date_start.toDate()),
                                          moment(d.data().date_end.toDate())
                                        )
                                    )
                                    .filter((d) => d.data().room === rs.id).length !== 0 && (
                                    <Paper
                                      key={rs.id}
                                      radius="md"
                                      sx={() => ({
                                        backgroundColor: `#${rs.color.replace("#", "")}1A`,
                                        "&:hover": {
                                          // backgroundColor: `#${rs.color.replace("#", "")}66`,
                                        },
                                      })}
                                      withBorder
                                      p="xs"
                                    >
                                      <Flex justify="space-between" align="center">
                                        <Text fw={900} size="md" color="dimmed">
                                          {rs.title}
                                        </Text>
                                      </Flex>
                                      {allEvents
                                        .filter((d) => d.data().type === t.title)
                                        .filter(
                                          (d) =>
                                            moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                                              moment(wd).format("DDMMYYYY") ||
                                            moment(wd).isBetween(
                                              moment(d.data().date_start.toDate()),
                                              moment(d.data().date_end.toDate())
                                            )
                                        )
                                        .filter((d) => d.data()?.state !== 2 && d.data()?.state !== 0)
                                        .filter((d) => d.data().room === rs.id)
                                        .map((desk) => {
                                          return (
                                            <HoverCard
                                              key={desk.id}
                                              shadow="lg"
                                              withArrow
                                              offset={2}
                                              radius="md"
                                              arrowPosition="side"
                                              position="left"
                                              arrowSize={10}
                                              arrowRadius={2}
                                            >
                                              <HoverCard.Target>
                                                <Flex align="center" gap={4}>
                                                  {((currentUser.uid === desk.data().created_by &&
                                                    moment(wd).startOf("day").format("DDMMYY") >=
                                                      moment().startOf("day").format("DDMMYY")) ||
                                                    userClaims["access"] !== "employee") && (
                                                    <Tooltip
                                                      color="red"
                                                      openDelay={1000}
                                                      withArrow
                                                      label="Cancel Booking"
                                                    >
                                                      <ActionIcon
                                                        variant="subtle"
                                                        color="red"
                                                        size="sm"
                                                        onClick={() =>
                                                          modals.openConfirmModal({
                                                            title: <Text weight={700}>Cancel Booking</Text>,
                                                            centered: true,
                                                            closeOnClickOutside: false,
                                                            withCloseButton: false,
                                                            children: (
                                                              <>
                                                                {moment(wd).startOf("day").format("DDMMYY") >=
                                                                moment().startOf("day").format("DDMMYY") ? (
                                                                  <Text>
                                                                    You can book this again (subject to availability).
                                                                  </Text>
                                                                ) : (
                                                                  <Text color="red">
                                                                    You are not able to book dates in the past.
                                                                  </Text>
                                                                )}
                                                              </>
                                                            ),
                                                            labels: { confirm: "Cancel Booking", cancel: "Dismiss" },
                                                            confirmProps: { color: "red" },
                                                            onConfirm: () =>
                                                              deleteEvent(companyId, desk.id).then(() => {
                                                                reloadEvents();
                                                                modals.closeAll();
                                                              }),
                                                          })
                                                        }
                                                      >
                                                        <IconX size="1.5rem" color="red" stroke={2} />
                                                      </ActionIcon>
                                                    </Tooltip>
                                                  )}
                                                  <Text fw={600}>{desk.data().title}</Text>
                                                </Flex>
                                              </HoverCard.Target>
                                              <HoverCard.Dropdown>
                                                <Text size="sm" color="dimmed">
                                                  Created by{" "}
                                                  <Text span color="black" fw="bolder">
                                                    {desk.data().created_by_admin_name ?? desk.data().title}
                                                  </Text>{" "}
                                                  on{" "}
                                                  <Text span color="black" fw="bold">
                                                    {moment(desk.data().created.toDate()).format(
                                                      "ddd, DD MMM YYYY - HH:mm"
                                                    )}
                                                  </Text>
                                                </Text>
                                              </HoverCard.Dropdown>
                                            </HoverCard>
                                          );
                                        })}
                                    </Paper>
                                  )
                                );
                              })}

                            {/* PARKING */}

                            {parkingSpaces
                              .sort(function (a, b) {
                                if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
                                if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
                                return 0;
                              })
                              .map((ps) => {
                                return (
                                  allEvents
                                    .filter((d) => d.data().type === t.title)
                                    .filter(
                                      (d) =>
                                        moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                                          moment(wd).format("DDMMYYYY") ||
                                        moment(wd).isBetween(
                                          moment(d.data().date_start.toDate()),
                                          moment(d.data().date_end.toDate())
                                        )
                                    )
                                    .filter((d) => d.data().space === ps.id).length !== 0 && (
                                    <Paper
                                      key={ps.id}
                                      radius="md"
                                      sx={() => ({
                                        backgroundColor: `#${ps.color.replace("#", "")}1A`,
                                        "&:hover": {
                                          // backgroundColor: `#${ps.color.replace("#", "")}66`,
                                        },
                                      })}
                                      withBorder
                                      p="xs"
                                    >
                                      <Flex justify="space-between" align="center">
                                        <Text fw={900} size="md" color="dimmed">
                                          {ps.title}
                                        </Text>
                                      </Flex>
                                      {allEvents
                                        .filter((d) => d.data().type === t.title)
                                        .filter(
                                          (d) =>
                                            moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                                              moment(wd).format("DDMMYYYY") ||
                                            moment(wd).isBetween(
                                              moment(d.data().date_start.toDate()),
                                              moment(d.data().date_end.toDate())
                                            )
                                        )
                                        .filter((d) => d.data().space === ps.id)
                                        .map((d) => {
                                          return (
                                            <HoverCard
                                              key={d.id}
                                              shadow="lg"
                                              withArrow
                                              offset={2}
                                              radius="md"
                                              arrowPosition="side"
                                              position="left"
                                              arrowSize={10}
                                              arrowRadius={2}
                                            >
                                              <HoverCard.Target>
                                                <Flex align="center" gap={4}>
                                                  {((currentUser.uid === d.data().created_by &&
                                                    moment(wd).startOf("day").format("DDMMYY") >=
                                                      moment().startOf("day").format("DDMMYY")) ||
                                                    userClaims["access"] !== "employee") && (
                                                    <Tooltip
                                                      color="red"
                                                      openDelay={1000}
                                                      withArrow
                                                      label="Cancel Booking"
                                                    >
                                                      <ActionIcon
                                                        variant="subtle"
                                                        color="red"
                                                        size="sm"
                                                        onClick={() =>
                                                          modals.openConfirmModal({
                                                            title: <Text weight={700}>Cancel Parking Space</Text>,
                                                            centered: true,
                                                            closeOnClickOutside: false,
                                                            withCloseButton: false,
                                                            children: (
                                                              <>
                                                                {moment(wd).startOf("day").format("DDMMYY") >=
                                                                moment().startOf("day").format("DDMMYY") ? (
                                                                  <Text>
                                                                    You can book this space again (subject to
                                                                    availability).
                                                                  </Text>
                                                                ) : (
                                                                  <Text color="red">
                                                                    You are not able to book dates in the past.
                                                                  </Text>
                                                                )}
                                                              </>
                                                            ),
                                                            labels: { confirm: "Cancel Booking", cancel: "Dismiss" },
                                                            confirmProps: { color: "red" },
                                                            onConfirm: () =>
                                                              deleteEvent(companyId, d.id).then(() => {
                                                                reloadEvents();
                                                                modals.closeAll();
                                                              }),
                                                          })
                                                        }
                                                      >
                                                        <IconX size="1.5rem" color="red" stroke={2} />
                                                      </ActionIcon>
                                                    </Tooltip>
                                                  )}
                                                  <Text fw={600}>
                                                    {d.data().title}
                                                    {
                                                      <Text color="dimmed" size="xs">
                                                        {moment(d.data().date_start.toDate()).format("HH:mm")} -{" "}
                                                        {moment(d.data().date_end.toDate()).format("HH:mm")}
                                                      </Text>
                                                    }
                                                  </Text>
                                                </Flex>
                                              </HoverCard.Target>
                                              <HoverCard.Dropdown>
                                                <Text size="sm" color="dimmed">
                                                  Booked by{" "}
                                                  <Text span color="black" fw="bold">
                                                    {d.data().created_by_admin_name ?? d.data().title}
                                                  </Text>{" "}
                                                  on{" "}
                                                  <Text span color="black" fw="bold">
                                                    {moment(d.data().created.toDate()).format(
                                                      "ddd, DD MMM YYYY - HH:mm"
                                                    )}
                                                  </Text>
                                                </Text>
                                              </HoverCard.Dropdown>
                                            </HoverCard>
                                          );
                                        })}
                                    </Paper>
                                  )
                                );
                              })}

                            {/* HOLIDAY */}

                            {allEvents
                              .filter((d) => d.data().type === t.title)
                              .filter(
                                (d) =>
                                  moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                                    moment(wd).format("DDMMYYYY") ||
                                  moment(wd).isBetween(
                                    moment(d.data().date_start.toDate()),
                                    moment(d.data().date_end.toDate())
                                  )
                              )
                              .filter((d) => d.data().type === "holiday")
                              .filter(
                                (d) =>
                                  d.data().state === 1 ||
                                  ((d.data().state === 2 || d.data().state === 1) &&
                                    d.data().created_by === currentUser.uid)
                              )
                              .sort(function (a, b) {
                                if (a.data().title?.toLowerCase() < b.data().title?.toLowerCase()) return -1;
                                if (a.data().title?.toLowerCase() > b.data().title?.toLowerCase()) return 1;
                                return 0;
                              })
                              .map((e) => {
                                return (
                                  <HoverCard
                                    key={e.id}
                                    shadow="lg"
                                    withArrow
                                    offset={2}
                                    radius="md"
                                    arrowPosition="side"
                                    position="left"
                                    arrowSize={10}
                                    arrowRadius={2}
                                  >
                                    <HoverCard.Target>
                                      <UnstyledButton
                                        sx={() => ({
                                          cursor: e.data().created_by === currentUser.uid ? "pointer" : "default",
                                        })}
                                        onClick={() => {
                                          setHolidaySelectedTab(e.data().state === 1 ? "approved" : "pending");
                                          setIsHolidayDrawOpen(e.data().created_by === currentUser.uid);
                                        }}
                                      >
                                        <Paper
                                          key={e.id}
                                          radius="md"
                                          sx={() => ({
                                            backgroundColor: `#7bed9f1A`,
                                            "&:hover": {
                                              // backgroundColor: `#${ps.color.replace("#", "")}66`,
                                            },
                                          })}
                                          withBorder
                                          p="xs"
                                          opacity={e.data().state === 2 ? 0.6 : 1}
                                        >
                                          <Flex align="center" gap="xs" justify="space-between">
                                            <Flex align="center" gap="xs">
                                              {e.data().state === 2 && <Loader size="xs" color="grey" />}
                                              {e.data().state === 1 && <IconCircleCheck color="teal" />}
                                              <Text fw={600}>{e.data().title}</Text>
                                            </Flex>
                                            {e.data().created_by === currentUser.uid && (
                                              <IconChevronRight color="black" />
                                            )}
                                          </Flex>
                                        </Paper>
                                      </UnstyledButton>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                      <Text size="sm" color="dimmed">
                                        <Text span color="black" fw="bold">
                                          {e.data().state === 2 && "Pending"}
                                          {e.data().state === 1 && "Approved"}
                                        </Text>{" "}
                                        - Created by{" "}
                                        <Text span color="black" fw="bolder">
                                          {e.data().created_by_admin_name ?? e.data().title}
                                        </Text>{" "}
                                        on{" "}
                                        <Text span color="black" fw="bold">
                                          {moment(e.data().created.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                                        </Text>
                                      </Text>
                                    </HoverCard.Dropdown>
                                  </HoverCard>
                                );
                              })}
                          </Stack>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ScrollArea>
      </Stack>
    </>
  );
}
