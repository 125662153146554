import { Paper, Title } from "@mantine/core";

export default function ModuleGoogleReleases() {
  return (
    <Paper shadow="sm" radius="md" p="sm" withBorder miw={200}>
      <Title order={2} weight={800} lineClamp={1}>
        Android 14
      </Title>
      <Title order={5} weight={300} color="dimmed">
        Wed, 4 Oct 2023
      </Title>
    </Paper>
  );
}
