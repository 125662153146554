import React from "react";
import { Title, Text, Stack, Flex, Paper, Loader, Group, Accordion, Badge } from "@mantine/core";
import { IconCircleCheck, IconCircleMinus } from "@tabler/icons";
import moment from "moment";

export default function DrawItemHistory({ allHolidays, employees }) {
  const allHistory = allHolidays
    .filter(
      (d) => (d.data().state === 1 || d.data().state === 0) && moment().isAfter(moment(d.data().date_end.toDate()))
    )
    .sort(function (a, b) {
      if (a.data().date_start.toDate() < b.data().date_start.toDate()) return -1;
      if (a.data().date_start.toDate() > b.data().date_start.toDate()) return 1;
      return 0;
    });
  return (
    <>
      {allHistory.length > 0 && (
        <Accordion.Item value="history">
          <Accordion.Control>
            <Flex justify="space-between" align="center">
              <Stack spacing={0}>
                <Title order={4} weight={800} color="dimmed">
                  History
                </Title>
                <Text size="sm" color="dimmed"></Text>
              </Stack>
              <Badge size="lg" color="dark">
                {allHistory.length}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {allHistory.map((d) => {
              return (
                <Paper key={d.id} p="md" withBorder mb="md">
                  <Stack>
                    {moment(d.data().date_end.toDate()).format("DDMMYYYY") ===
                      moment(d.data().date_start.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("dddd, DD MMM yyyy")}
                      </Title>
                    )}
                    {moment(d.data().date_start.toDate()).format("DDMMYYYY") !==
                      moment(d.data().date_end.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("DD MMM yyyy")} -{" "}
                        {moment(d.data().date_end.toDate()).format("DD MMM yyyy")}
                      </Title>
                    )}

                    <Group position="center">
                      {d.data().approved_by.map((admin) => {
                        return employees
                          .filter((em) => admin === em?.uid)
                          .map((a) => {
                            return (
                              <Flex key={a.uid} align="center" justify="center" direction="column" gap={4}>
                                <Text fw="bold">{a.displayName}</Text>
                                {d.data().approved_by.includes(a.uid) && <IconCircleCheck color="green" />}
                                {!d.data().approved_by.includes(a.uid) &&
                                  (moment(d.data().date_start.toDate()).format("DDMMYYYY") >=
                                  moment().format("DDMMYYYY") ? (
                                    <IconCircleMinus color="grey" />
                                  ) : (
                                    <Loader size="sm" color="orange" />
                                  ))}
                              </Flex>
                            );
                          });
                      })}
                    </Group>

                    {d.data()?.state === 1 && (
                      <Text size="sm" color="dimmed" align="center">
                        Approved on {moment(d.data().updated.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                      </Text>
                    )}
                  </Stack>
                </Paper>
              );
            })}
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
}
