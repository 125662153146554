import React, { useState, forwardRef } from "react";
import { Button, Stack, Group, Text, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { addNewDesk } from "../../../services/Firestore";
import moment from "moment";

export default function BookerCreateDesk({
  companyId,
  rooms,
  onCompletion,
  userClaims,
  currentUser,
  allEvents,
  employees,
  selectedWeek,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(selectedWeek).day() === 0
      ? moment(selectedWeek).add(1, "day")
      : moment(selectedWeek).day() === 6
      ? moment(selectedWeek).add(2, "day")
      : moment(selectedWeek)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [assignedToSelected, setAssignedToSelected] = useState(currentUser.uid);
  const [assignedTo, setAssignedTo] = useState({ name: currentUser.displayName, id: currentUser.uid });

  const [isDateAlreadyBooked, setDateAlreadyBooked] = useState(
    allEvents.filter(
      (d) =>
        d.data().type === "desk" &&
        moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment().format("DDMMYYYY") &&
        d.data().created_by === currentUser.uid
    ).length > 0
  );
  const [isUserAlreadyBooked, setUserAlreadyBooked] = useState(false);
  const [isRoomFull, setRoomFull] = useState(false);

  const dayRoomsBooked = allEvents
    .filter((d) => d.data().type === "desk")
    .filter((d) => moment(d.data().date_start.toDate()).format("DDMMYY") === moment(selectedStartDate).format("DDMMYY"))
    .map((d) => d.data().room);

  var rs = rooms.map((r) => {
    return {
      label: r.title,
      description: r.max_bookings,
      value: r.id,
      disabled: dayRoomsBooked.filter((r) => r === r.id).length >= r.max_bookings,
    };
  });

  const [selectedRoom, setSelectedRoom] = useState(null);
  const SelectItem = forwardRef(({ image, label, description, value, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            Seats remaining: {description - dayRoomsBooked.filter((r) => r === value).length}
          </Text>
        </div>
      </Group>
    </div>
  ));

  return (
    <Stack>
        <DatePickerInput
          label="Start Date"
          valueFormat="DD MMMM YYYY"
          dropdownType="modal"
          value={selectedStartDate}
          error={isDateAlreadyBooked ? "You have already have already booked a desk for this date. Please select another date." : null}
          disabled={isLoading}
          excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
          onChange={(date) => {
            setSelectedStartDate(date);
            setSelectedRoom(null);
            setUserAlreadyBooked(null);

            if (userClaims["access"] !== "employee") {
              setDateAlreadyBooked(
                allEvents.filter(
                  (d) =>
                    d.data().type === "desk" &&
                    moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment(date).format("DDMMYYYY") &&
                    d.data().created_by === assignedTo.id
                ).length > 0
              );
            } else {
              setDateAlreadyBooked(
                allEvents.filter(
                  (d) =>
                    d.data().type === "desk" &&
                    moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment(date).format("DDMMYYYY") &&
                    d.data().created_by === currentUser.uid
                ).length > 0
              );
            }
            setAssignedToSelected(currentUser.uid);
          }}
          getDayProps={(date) => {
            if (
              userClaims["access"] === "employee" &&
              allEvents.filter(
                (d) =>
                  d.data().type === "desk" &&
                  moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment(date).format("DDMMYYYY") &&
                  d.data().created_by === currentUser.uid
              ).length > 0
            ) {
              return { disabled: true };
            }
            return {};
          }}
          minDate={moment().startOf("day").toDate()}
          maxDate={moment().startOf("day").add(6, "month").toDate()}
          popoverProps={{ zIndex: 1000000 }}
          required
        />

        <DatePickerInput
          label="End Date"
          valueFormat="DD MMMM YYYY"
          dropdownType="modal"
          excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
          value={selectedEndDate}
          disabled={isLoading}
          onChange={(date) => setSelectedEndDate(date)}
          minDate={moment(selectedStartDate).startOf("day").add(1, "day").toDate()}
          maxDate={moment(selectedStartDate).startOf("day").add(6, "month").toDate()}
          popoverProps={{ zIndex: 1000000 }}
          clearable
        />
      <Select
        label="Select Room"
        description="Select the room you'll be working in."
        value={selectedRoom}
        data={rs}
        itemComponent={SelectItem}
        error={isRoomFull ? "This room is full. Please select another room." : null}
        disabled={isLoading}
        searchable
        onChange={(i) => {
          setSelectedRoom(i);
          setRoomFull(dayRoomsBooked.filter((r) => r === i).length >= rooms.filter((r) => r.id === i)[0].max_bookings);
        }}
        maxDropdownHeight={150}
        required
      />
      {userClaims["access"] !== "employee" && (
        <Select
          label="Assigned To Employee"
          value={assignedToSelected}
          error={isUserAlreadyBooked ? `Please select another employee.` : null}
          maxDropdownHeight={150}
          searchable
          disabled={isLoading}
          data={employees.map((r) => {
            return {
              label: r.displayName,
              value: r.uid,
            };
          })}
          onChange={(id) => {
            setAssignedToSelected(id);
            setAssignedTo({ name: employees.filter((e) => e.uid === id)[0].displayName, id: id });
            setDateAlreadyBooked(false);
            setUserAlreadyBooked(
              allEvents.filter(
                (d) =>
                  d.data().type === "desk" &&
                  moment(d.data().date_start.toDate()).format("DDMMYYYY") ===
                    moment(selectedStartDate).format("DDMMYYYY") &&
                  d.data().created_by === id
              ).length > 0
            );
          }}
          required
        />
      )}
      <Button
        mt={16}
        size="lg"
        disabled={
          assignedTo.length === 0 || isRoomFull || selectedRoom == null || isDateAlreadyBooked || isUserAlreadyBooked
        }
        loading={isLoading}
        onClick={() => {
          setIsLoading(true);
          if (userClaims["access"] !== "employee") {
            addNewDesk(
              companyId,
              assignedTo.name,
              selectedStartDate,
              selectedEndDate,
              selectedRoom,
              assignedTo.id,
              currentUser.uid,
              currentUser.displayName
            ).then(() => onCompletion());
          } else {
            addNewDesk(
              companyId,
              assignedTo.name,
              selectedStartDate,
              selectedEndDate,
              selectedRoom,
              assignedTo.id
            ).then(() => onCompletion());
          }
        }}
      >
        Book Desk
      </Button>
    </Stack>
  );
}
