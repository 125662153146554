import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase-config";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import {
  Button,
  Input,
  Paper,
  Stack,
  Tabs,
  Text,
  Avatar,
  Switch,
  Title,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconAt,
  IconTerminal2,
  IconMessageCircle,
  IconListCheck,
  IconLayoutBoard,
  IconCalendarEvent,
  IconMail,
} from "@tabler/icons";
import BusinessEditSMS from "./modules/sms";
import BusinessEventEdits from "./modules/booker";
import BusinessEditJira from "./modules/jira";
import { ShowNotificationSuccess } from "../../components/Notifications";
import BusinessEditSendGrid from "./modules/sendgrid";

export default function BusinessEdit({
  companyId,
  companyData,
  userToken,
  currentUser,
}) {
  const [newName, setNewName] = useState(companyData["name"]);
  const [newAddress, setNewAddress] = useState(companyData["address"]);

  const [isAutomationEnabled, setAutomationEnabled] = useState(
    companyData["azure"]["automation_enabled"] ?? false
  );
  const [azureAccessToken, setAzureAccessToken] = useState(
    companyData["azure"]["access_token"] ?? ""
  );
  const [automationId, setAutomationId] = useState(
    companyData["azure"]["automation_id"] ?? ""
  );

  const [isTestrailEnabled, setTestrailEnabled] = useState(
    companyData["testrail"]["enabled"]
  );
  const [testrailAPIKey, setTestrailApiKey] = useState(
    companyData["testrail"]["api_key"]
  );
  const [testrailEmail, setTestrailEmail] = useState(
    companyData["testrail"]["email"]
  );
  const [testrailDomain, setTestrailDomain] = useState(
    companyData["testrail"]["id"]
  );

  const [newEmailDomain, setEmailDomain] = useState(
    companyData["email_domain"]
  );
  const [newAvatar] = useState(companyData["avatar"]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Edit Company Details - Cell Software Portal";
  }, [companyData, newName]);

  async function submitBusinessUpdates() {
    setLoading(true);

    updateDoc(doc(firestore, "companies", companyId), {
      name: newName,
      address: newAddress,
      email_domain: newEmailDomain,
      updated: serverTimestamp(),
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("Details Updated");
    });
  }

  async function submitAutomationUpdates() {
    setLoading(true);

    updateDoc(doc(firestore, "companies", companyId), {
      azure: {
        access_token: azureAccessToken,
        automation_id: automationId,
        automation_enabled: isAutomationEnabled,
      },
      updated: serverTimestamp(),
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("Automation Updated");
    });
  }

  async function submitTestrailUpdates() {
    setLoading(true);

    updateDoc(doc(firestore, "companies", companyId), {
      testrail: {
        email: testrailEmail,
        id: testrailDomain,
        api_key: testrailAPIKey,
        enabled: isTestrailEnabled,
      },
      updated: serverTimestamp(),
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("TestRail Updated");
    });
  }

  return (
    <Paper shadow="md" p="lg" withBorder>
      {/* Parent slack */}

      <Tabs defaultValue="details" variant="outline" orientation="vertical">
        <Tabs.List>
          <Tabs.Tab value="details" icon={<IconAlertCircle size={16} />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="automation" icon={<IconTerminal2 size={16} />}>
            Automations
          </Tabs.Tab>
          <Tabs.Tab value="jira" icon={<IconLayoutBoard size={16} />}>
            Jira Boards
          </Tabs.Tab>
          <Tabs.Tab value="testrail" icon={<IconListCheck size={16} />}>
            TestRail
          </Tabs.Tab>
          <Tabs.Tab value="sms" icon={<IconMessageCircle size={16} />}>
            SMS Messaging
          </Tabs.Tab>
          <Tabs.Tab value="booker" icon={<IconCalendarEvent size={16} />}>
            Booker
          </Tabs.Tab>
          <Tabs.Tab value="sendgrid" icon={<IconMail size={16} />}>
            SendGrid
          </Tabs.Tab>
        </Tabs.List>

        {/* Tab Content */}

        {/* Details */}

        <Tabs.Panel value="details">
          <Stack p={16}>
            <Title order={2}>Business Details</Title>
            <Input.Wrapper label="Avatar">
              <Avatar
                m={8}
                radius="xl"
                size="xl"
                color="blue"
                src={newAvatar}
              />
            </Input.Wrapper>
            <Input.Wrapper label="Name">
              <Input
                placeholder={companyData["name"]}
                disabled={isLoading}
                value={newName}
                onChange={(e) => setNewName(e.currentTarget.value)}
                autoComplete="no"
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper label="Address">
              <Input
                placeholder={companyData["address"]}
                disabled={isLoading}
                value={newAddress}
                onChange={(e) => setNewAddress(e.currentTarget.value)}
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper
              label="Employee Email Domain"
              description="When creating employees, their emails must match this domain."
            >
              <Input
                placeholder={companyData["email_domain"]}
                disabled={isLoading}
                value={newEmailDomain}
                onChange={(e) => setEmailDomain(e.currentTarget.value.trim())}
                radius="lg"
                m={8}
                icon={<IconAt size={14} />}
              />
            </Input.Wrapper>

            <Input.Wrapper
              label="Registered Company Number"
              description="This is not changable."
            >
              <Text m={8} size={15}>
                {companyId}
              </Text>
            </Input.Wrapper>

            <Button
              disabled={
                newName.trim() === companyData["name"] &&
                newEmailDomain.trim() === companyData["email_domain"] &&
                newAddress.trim() === companyData["address"]
              }
              onClick={submitBusinessUpdates}
            >
              Update Details
            </Button>
          </Stack>
        </Tabs.Panel>

        {/* Automation */}
        <Tabs.Panel value="automation">
          <Stack p={16}>
            <Title order={2}>Automations</Title>
            <Input.Wrapper
              label="Primary User Personal Access Token"
              description="The primary user to be used to gain access to Azure."
              required
            >
              <Input
                placeholder="0xxx0xxxxxx0xxx0xxxxxx"
                disabled={isLoading}
                value={azureAccessToken}
                onChange={(e) =>
                  setAzureAccessToken(e.currentTarget.value.trim())
                }
                autoComplete="no"
                radius="lg"
                m={8}
              />

              <Input.Wrapper
                label="Primary User Organization ID"
                description="The Organization ID where the automation is located."
                required
              >
                <Input
                  placeholder={automationId}
                  disabled={isLoading}
                  value={automationId}
                  onChange={(e) =>
                    setAutomationId(e.currentTarget.value.trim())
                  }
                  autoComplete="no"
                  radius="lg"
                  m={8}
                />
              </Input.Wrapper>
            </Input.Wrapper>

            <Input.Wrapper label="Enable Automation" mb={16}>
              <Switch
                checked={isAutomationEnabled}
                onChange={(e) => setAutomationEnabled(e.currentTarget.checked)}
              />
            </Input.Wrapper>

            <Button
              disabled={
                automationId === companyData["azure"]["automation_id"] &&
                azureAccessToken === companyData["azure"]["access_token"] &&
                isAutomationEnabled ===
                  companyData["azure"]["automation_enabled"]
              }
              onClick={submitAutomationUpdates}
            >
              Update Details
            </Button>
          </Stack>
        </Tabs.Panel>

        {/* TestRail */}
        <Tabs.Panel value="testrail">
          <Stack p={16}>
            <Title order={2}>TestRail</Title>
            <Input.Wrapper
              label="Primary User API Key"
              description="The primary user to be used to gain access to Jira."
              required
            >
              <Input
                disabled={isLoading}
                value={testrailAPIKey}
                onChange={(e) =>
                  setTestrailApiKey(e.currentTarget.value.trim())
                }
                autoComplete="no"
                radius="lg"
                m={8}
              />

              <Input.Wrapper
                label="Primary User Email"
                required
                description="Must be the email the API key was generated from."
              >
                <Input
                  disabled={isLoading || !isTestrailEnabled}
                  value={testrailEmail}
                  onChange={(e) =>
                    setTestrailEmail(e.currentTarget.value.trim())
                  }
                  autoComplete="email"
                  radius="lg"
                  m={8}
                />
              </Input.Wrapper>
            </Input.Wrapper>

            <Input.Wrapper
              label="TestRail Domain"
              description="The domain where your TestRail is hosted. E.g. 'xxxx.testrail.io'"
              required
            >
              <Input
                disabled={isLoading || !isTestrailEnabled}
                value={testrailDomain}
                onChange={(e) =>
                  setTestrailDomain(e.currentTarget.value.trim())
                }
                autoComplete="no"
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper label="Enable TestRail" mb={16}>
              <Switch
                checked={isTestrailEnabled}
                onChange={(e) => setTestrailEnabled(e.currentTarget.checked)}
              />
            </Input.Wrapper>

            <Button
              disabled={
                isTestrailEnabled === companyData["testrail"]["enabled"] &&
                testrailAPIKey === companyData["testrail"]["api_key"] &&
                testrailEmail === companyData["testrail"]["email"] &&
                testrailDomain === companyData["testrail"]["id"]
              }
              onClick={submitTestrailUpdates}
            >
              Update Details
            </Button>
          </Stack>
        </Tabs.Panel>

        {/* JIRA */}
        <Tabs.Panel value="jira">
          <BusinessEditJira
            companyData={companyData}
            companyId={companyId}
            userToken={userToken}
          />
        </Tabs.Panel>

        {/* SMS Messages */}
        <Tabs.Panel value="sms">
          <BusinessEditSMS companyData={companyData} companyId={companyId} />
        </Tabs.Panel>

        {/* EVENTS */}
        <Tabs.Panel value="booker">
          <BusinessEventEdits
            companyData={companyData}
            companyId={companyId}
            userToken={userToken}
            currentUser={currentUser}
          />
        </Tabs.Panel>

        {/* SENDGRID */}
        <Tabs.Panel value="sendgrid">
          <BusinessEditSendGrid
            companyData={companyData}
            companyId={companyId}
          />
        </Tabs.Panel>
      </Tabs>
    </Paper>
  );
}
