import React, { useEffect, useState } from "react";
import { Grid, ScrollArea } from "@mantine/core";
import TestrailProjectsModule from "./modules/projects";
import TestrailModuleRuns from "./modules/runs";
import TestrailRunTests from "./modules/tests";

export default function Testrail({ userToken, companyId, userClaims }) {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedRunInfo, setSelectedRunInfo] = useState(null);
  const [selectedSuiteId, setselectedSuiteId] = useState(null);

  useEffect(() => {
    document.title = "TestRail - Cell Software Portal";
  },[]);

  const scrollHeight = 800;

  useEffect(() => {
    if (selectedProject) {
      setSelectedRunInfo(null);
    }
  }, [selectedProject]);

  return (
    <Grid>
      <Grid.Col xs={3}>
          <TestrailProjectsModule
            userToken={userToken}
            companyId={companyId}
            userClaims={userClaims}
            selectedProject={setSelectedProject}
            selectedRun={setSelectedRun}
            suiteId={setselectedSuiteId}
            runInfo={setSelectedRunInfo}
          />
      </Grid.Col>
      <Grid.Col xs={4}>
        <ScrollArea h={scrollHeight} scrollbarSize={8} offsetScrollbars type="hover">
          <TestrailModuleRuns
            userToken={userToken}
            companyId={companyId}
            projectId={selectedProject}
            selectedRun={setSelectedRun}
            suiteId={setselectedSuiteId}
            runInfo={setSelectedRunInfo}
          />
        </ScrollArea>
      </Grid.Col>
      <Grid.Col xs={5}>
        <ScrollArea h={scrollHeight} type="always" offsetScrollbars scrollbarSize={8} typeof="hover">
          <TestrailRunTests
            userToken={userToken}
            companyId={companyId}
            projectId={selectedProject}
            runId={selectedRun}
            suiteId={selectedSuiteId}
            runInfo={selectedRunInfo}
          />
        </ScrollArea>
      </Grid.Col>
    </Grid>
  );
}
