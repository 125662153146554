import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  Modal,
  Center,
  Loader,
  Text,
  ActionIcon,
  Badge,
  Avatar,
  Group,
  ScrollArea,
  createStyles,
  rem,
  Select,
  Checkbox,
  Flex,
  CopyButton,
  Tooltip,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import EmployeeCreate from "./Create";
import * as apiService from "../../api-service";
import { IconCheck, IconCopy, IconTrash } from "@tabler/icons";
import { add, format } from "date-fns";
import {
  ShowNotificationInfo,
  ShowNotificationSuccess,
} from "../../components/Notifications";

export default function Employees({
  userToken,
  companyId,
  companyData,
  userAccess,
  navButton,
}) {
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const [members, setMembers] = useState([]);

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",
      zIndex: 1,
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },
  }));
  const { classes } = useStyles();

  const setCreateModalVisible = (bool, isCreated = true) => {
    setCreateOpen(bool);

    if (!isCreated) {
      // when user deletes
      setIsUserDeleted(!isUserDeleted);
    }

    ShowNotificationSuccess(
      isCreated ? "Employee Created" : "Employee Deleted"
    );

    if (isCreated) {
      ShowNotificationInfo("Password copied to clipboard");
    }
  };

  useEffect(() => {
    document.title = "Employees - Cell Software Portal";

    if (userAccess !== "employee") {
      navButton(
        <Button size="md" onClick={() => setCreateOpen(true)}>
          New Employee
        </Button>
      );
    }

    return () => {
      navButton(null);
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reloadUsers(), []);

  const reloadUsers = () => {
    if (userToken) {
      apiService
        .getEmployees({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setMembers(data);
          setIsLoading(false);
          setIsUpdatingUser(false);
        })
        .catch((e) => console.error(e));
    }
  };

  // Full process of deleting a user
  const confirmDelete = ({ uid }) => {
    openConfirmModal({
      title: <Text weight={700}>Delete Employee?</Text>,
      centered: true,
      children: (
        <Text size="sm">
          Once deleted, they will no longer be able to login.
        </Text>
      ),
      labels: { confirm: "Delete Employee", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        // Get index of item
        deleteEmployee({ uid });
      },
    });
  };

  function deleteEmployee({ uid }) {
    setIsLoading(true);
    if (userToken) {
      apiService
        .deleteUser({
          userIdToken: userToken,
          companyId: companyId,
          uid,
        })
        .then(() => setCreateModalVisible(false, false))
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  }

  const jobColors = {
    admin: "violet",
    employee: "blue",
    super: "yellow",
  };

  const hasLoggedIn = (creationTime, lastSignInTime) => {
    const dateLastSignedIn = new Date(lastSignInTime);
    const futureDate = add(new Date(creationTime), { seconds: 5 });

    if (dateLastSignedIn < futureDate) {
      return "N/A";
    } else {
      return format(dateLastSignedIn, "d MMM yy • HH:mm:ss");
    }
  };

  const updateDurianMember = (userId, enabled) => {
    if (userToken && companyId && userId && userAccess !== "employee") {
      setIsUpdatingUser(true);
      apiService
        .updateUser({
          userIdToken: userToken,
          companyId: companyId,
          uid: userId,
          durian_member: enabled,
        })
        .then(() => reloadUsers())
        .catch((e) => {
          console.error(e);
          setIsUpdatingUser(false);
        });
    }
  };
  const updateEmailVerified = (userId, enabled) => {
    if (
      userToken &&
      companyId &&
      userId &&
      enabled &&
      userAccess !== "employee"
    ) {
      setIsUpdatingUser(true);
      apiService
        .updateUser({
          userIdToken: userToken,
          companyId: companyId,
          uid: userId,
          verified: enabled,
        })
        .then(() => reloadUsers())
        .catch((e) => {
          console.error(e);
          setIsUpdatingUser(false);
        });
    }
  };
  const updateMemberRole = (userId, role) => {
    if (userToken && companyId && userId && role && userAccess !== "employee") {
      setIsUpdatingUser(true);
      apiService
        .updateUser({
          userIdToken: userToken,
          companyId: companyId,
          uid: userId,
          access: role,
        })
        .then(() => reloadUsers())
        .catch((e) => {
          console.error(e);
          setIsUpdatingUser(false);
        });
    }
  };

  return (
    <>
      <Modal
        centered
        closeOnClickOutside={false}
        opened={isCreateOpen}
        onClose={() => setCreateOpen(false)}
        title="New Employee"
      >
        <EmployeeCreate
          userToken={userToken}
          companyId={companyId}
          emailDomain={
            companyData.data() === undefined
              ? ""
              : companyData.data()["email_domain"]
          }
          setModalVisible={setCreateModalVisible}
        />
      </Modal>
      <Paper shadow="md" p="lg" withBorder>
        {isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <ScrollArea style={{ height: 700 }} type="always" offsetScrollbars>
            <Table verticalSpacing="sm">
              <thead className={classes.header}>
                <tr>
                  <th>Employee</th>
                  <th>
                    <Text align="center">
                      Email
                      <br />
                      Verified
                    </Text>
                  </th>
                  <th>Role</th>
                  <th>
                    <Text align="center">
                      Durian
                      <br />
                      Member
                    </Text>
                  </th>
                  {userAccess !== "employee" && (
                    <>
                      <th>Last Active</th>
                      <th>Created</th>
                    </>
                  )}
                  {userAccess !== "employee" && <th />}
                </tr>
              </thead>
              <tbody>
                {members
                  .sort(function (a, b) {
                    if (
                      a.displayName.toLowerCase() < b.displayName.toLowerCase()
                    )
                      return -1;
                    if (
                      a.displayName.toLowerCase() > b.displayName.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  .map(
                    ({
                      uid,
                      displayName,
                      email,
                      customClaims,
                      emailVerified,
                      metadata,
                    }) => (
                      <tr key={uid}>
                        <td>
                          <Group spacing="sm">
                            <Avatar
                              size={40}
                              src={companyData.data()["avatar"]}
                              radius={40}
                            />
                            <div>
                              <Text fz="sm" fw={500}>
                                {displayName}
                              </Text>
                              <Text fz="xs" c="dimmed">
                                {email}
                              </Text>
                            </div>
                          </Group>
                        </td>
                        <td>
                          <Center>
                            <Checkbox
                              color="teal"
                              checked={emailVerified}
                              disabled={
                                userAccess === "employee" ||
                                isUpdatingUser ||
                                emailVerified
                              }
                              onChange={(event) =>
                                updateEmailVerified(
                                  uid,
                                  event.currentTarget.checked
                                )
                              }
                            />
                          </Center>
                        </td>
                        <td>
                          {userAccess !== "employee" ? (
                            <Select
                              data={[
                                { value: "employee", label: "Employee" },
                                { value: "admin", label: "Admin" },
                                {
                                  value: "super",
                                  label: "Super",
                                  disabled: userAccess !== "super",
                                },
                              ]}
                              disabled={isUpdatingUser}
                              value={customClaims["access"]}
                              onChange={(event) => updateMemberRole(uid, event)}
                            />
                          ) : (
                            <Badge
                              color={jobColors[customClaims["access"]]}
                              variant={"light"}
                            >
                              {customClaims["access"]}
                            </Badge>
                          )}
                        </td>
                        <td>
                          <Center>
                            <Checkbox
                              color="teal"
                              disabled={
                                userAccess === "employee" || isUpdatingUser
                              }
                              checked={customClaims["durian_member"]}
                              onChange={(event) =>
                                updateDurianMember(
                                  uid,
                                  event.currentTarget.checked
                                )
                              }
                            />
                          </Center>
                        </td>
                        {(userAccess === "super" || userAccess === "admin") && (
                          <>
                            <td>
                              {hasLoggedIn(
                                metadata.creationTime,
                                metadata.lastSignInTime
                              )}
                            </td>
                            <td>
                              {format(
                                new Date(metadata.creationTime),
                                "d MMM yy • HH:mm:ss"
                              )}
                            </td>
                            <td>
                              <Flex gap={8}>
                                <CopyButton value={uid} timeout={2000}>
                                  {({ copied, copy }) => (
                                    <Tooltip
                                      label={
                                        copied ? "Copied" : "Copy Users ID"
                                      }
                                      withArrow
                                      openDelay={500}
                                    >
                                      <ActionIcon
                                        color={copied ? "teal" : "gray"}
                                        onClick={copy}
                                        disabled={isUpdatingUser}
                                      >
                                        {copied ? (
                                          <IconCheck size="1.5rem" />
                                        ) : (
                                          <IconCopy size="1.25rem" />
                                        )}
                                      </ActionIcon>
                                    </Tooltip>
                                  )}
                                </CopyButton>
                                <Tooltip
                                  label="Delete Employee"
                                  withArrow
                                  color="red"
                                  openDelay={500}
                                >
                                  <ActionIcon
                                    variant="subtle"
                                    color="red"
                                    disabled={isUpdatingUser}
                                    onClick={() => confirmDelete({ uid })}
                                  >
                                    <IconTrash size="1.25rem" />
                                  </ActionIcon>
                                </Tooltip>
                              </Flex>
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </ScrollArea>
        )}
      </Paper>
    </>
  );
}
