import React, { useEffect, useState } from "react";
import { Grid, ScrollArea, SimpleGrid, Skeleton, Stack, Title, Input, CloseButton } from "@mantine/core";
import * as apiService from "../../../api-service";
import { ProjectButton } from "../../../components/ProjectButton";
import JiraBoardColumns from "./BoardColumns";
import JiraBoardTicket from "./BoardTicket";
import { IconSearch } from "@tabler/icons";

export default function JiraBoards({ userToken, companyId, userClaims }) {
  const [isLoading, setIsLoading] = useState(true);

  const [allProjects, setAllProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState([]);

  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [selectedTicketData, setTicketData] = useState(null);

  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    document.title = "Jira Boards - Cell Software Portal";
  },[]);

  const scrollHeight = 750;

  useEffect(() => {
    if (userToken) {
      apiService
        .getJiraBoards({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setAllProjects(data);

          if (userClaims["jira_boards"]) {
            setCurrentProjects(userClaims["jira_boards"]);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, [userToken, companyId, userClaims]);

  useEffect(() => {
    if (selectedBoardId) {
      setTicketData(null);
    }
  }, [selectedBoardId]);

  const clientProjects = currentProjects.map((id) =>
    allProjects
      .filter((p) => p.location.projectId === id)
      .filter((p) => p.location?.projectName?.toLowerCase().includes(filterBy.toLowerCase()))
      .map((proj) => {
        return (
          <ProjectButton
            key={id}
            isActive={selectedBoardId === proj.id}
            title={proj.location.projectName}
            image={proj.location.avatarURI}
            description={proj.location.projectKey}
            didClick={() => setSelectedBoardId(proj.id)}
          />
        );
      })
  );

  const employeeProjects = allProjects
    .filter((p) => p.location?.projectName?.toLowerCase().includes(filterBy.toLowerCase()))
    .map(({ id, location }) => (
      <ProjectButton
        key={id}
        isActive={selectedBoardId === id}
        title={location?.projectName}
        image={location?.avatarURI}
        description={location?.projectKey}
        didClick={() => setSelectedBoardId(id)}
      />
    ));

  return (
    <Grid>
      <Grid.Col xs={3}>
          <Input
          mb={16}
            icon={<IconSearch />}
            placeholder="Search..."
            onChange={(e) => setFilterBy(e.currentTarget.value)}
            value={filterBy}
            disabled={isLoading}
            rightSection={
              filterBy.length > 0 && (
                <CloseButton
                  aria-label="clear field"
                  onClick={() => {
                    setFilterBy("");
                    setTicketData(null);
                    setSelectedBoardId(null);
                    setTicketData(null);
                  }}
                />
              )
            }
          />
          {isLoading ? (
            <SimpleGrid cols={1}>
              {[0, 1, 2, 3, 4].map((index) => {
                return <Skeleton key={index} radius="md" height={70} />;
              })}
            </SimpleGrid>
          ) : (
            <ScrollArea mah={scrollHeight} w="auto" h="100%" type="always" offsetScrollbars>
              {userClaims["access"] !== "client" ? (
                employeeProjects.length > 0 ? (
                  <SimpleGrid cols={1}>{employeeProjects}</SimpleGrid>
                ) : (
                  <Stack mt={32}>
                    <Title order={2} align="center">
                      No Projects Available
                    </Title>
                    <Title order={5} color="gray" align="center">
                      Please contact the company admin.
                    </Title>
                  </Stack>
                )
              ) : clientProjects.length > 0 ? (
                <SimpleGrid cols={1}>{clientProjects}</SimpleGrid>
              ) : (
                <Stack mt={32}>
                  <Title order={2} align="center">
                    No Projects Available
                  </Title>
                  <Title order={5} color="gray" align="center">
                    Please contact the company admin.
                  </Title>
                </Stack>
              )}
            </ScrollArea>
          )}
      </Grid.Col>
      <Grid.Col xs={4}>
        <ScrollArea mah={scrollHeight} mx="auto" offsetScrollbars h="100%" type="always">
          <JiraBoardColumns
            userToken={userToken}
            companyId={companyId}
            userClaims={userClaims}
            boardId={selectedBoardId}
            selectedTicket={setTicketData}
          />
        </ScrollArea>
      </Grid.Col>
      <Grid.Col xs={5}>
        {selectedTicketData && (
          <ScrollArea mah={scrollHeight} mx="auto" offsetScrollbars h="100%" type="hover">
            <JiraBoardTicket
              userToken={userToken}
              companyId={companyId}
              userClaims={userClaims}
              ticketData={selectedTicketData}
            />
          </ScrollArea>
        )}
      </Grid.Col>
    </Grid>
  );
}
