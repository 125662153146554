import React, { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase-config";
import {
  Button,
  Stack,
  Switch,
  Text,
  Table,
  ActionIcon,
  Flex,
  Title,
  TextInput,
  Tabs,
  NumberInput,
  ColorInput,
  Center,
  Group,
  Avatar,
  MultiSelect,
  ScrollArea,
  Checkbox,
  Tooltip,
} from "@mantine/core";
import { IconTrash, IconPlus, IconCar, IconBuildingWarehouse, IconCheckbox } from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { RemotePrams, getRemoteNumber } from "../../../services/RemoteConfig";
import * as apiService from "../../../api-service";
import { ShowNotificationError, ShowNotificationSuccess } from "../../../components/Notifications";

export default function BusinessEventEdits({ companyData, companyId, userToken, currentUser }) {
  const [isLoading, setLoading] = useState(false);

  const [holidayBlackoutDays, setHolidayBlackoutDays] = useState(companyData["holiday_review_days"] ?? 1);
  const [annualLeave, setAnnualLeave] = useState(companyData["annual_leave"] ?? 28);
  const [ccEmails, setCcEmails] = useState(companyData["emails_cc"] ?? []);
  const [parkingSpaces, setParkingSpaces] = useState(companyData["parking_spaces"]);
  const [rooms, setRooms] = useState(companyData["rooms"]);

  const rows = parkingSpaces
    .sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1;
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1;
      return 0;
    })
    .map(({ active, id, title, color }) => (
      <tr key={id}>
        <td>
          <TextInput
            value={title}
            placeholder={title}
            disabled={!active || isLoading}
            onChange={(event) => {
              const space = parkingSpaces[parkingSpaces.findIndex((p) => p.id === id)];
              const ps = parkingSpaces.filter((i) => i.id !== id);
              setParkingSpaces([...ps, { ...space, title: event.currentTarget.value }]);
            }}
          />
        </td>
        <td>
          <ColorInput
            defaultValue={color ?? "#fff"}
            disabled={isLoading || !active}
            onChange={(n) => {
              const space = parkingSpaces[parkingSpaces.findIndex((p) => p.id === id)];
              const ps = parkingSpaces.filter((i) => i.id !== id);
              setParkingSpaces(
                [...ps, { ...space, color: n }].sort(function (a, b) {
                  if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1;
                  if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1;
                  return 0;
                })
              );
            }}
          />
        </td>
        <td>
          <Center>
            <Switch
              checked={active}
              onChange={(event) => {
                const space = parkingSpaces[parkingSpaces.findIndex((p) => p.id === id)];
                const ps = parkingSpaces.filter((i) => i.id !== id);
                setParkingSpaces(
                  [...ps, { ...space, active: event.currentTarget.checked }].sort(function (a, b) {
                    if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1;
                    if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1;
                    return 0;
                  })
                );
              }}
            />
          </Center>
        </td>
        <td>
          <ActionIcon
            variant="light"
            color="red"
            disabled={isLoading}
            onClick={() =>
              openConfirmModal({
                title: <Text weight={700}>Delete {title}?</Text>,
                centered: true,
                children: <Text size="sm">Changes are applied when you click 'Update'.</Text>,
                labels: { confirm: "Delete", cancel: "Cancel" },
                confirmProps: { color: "red" },
                onConfirm: () => setParkingSpaces(parkingSpaces.filter((i) => i.id !== id)),
              })
            }
          >
            <IconTrash size="1rem" color="red" stroke={1.5} />
          </ActionIcon>
        </td>
      </tr>
    ));

  async function updateParking() {
    const titles = parkingSpaces.map((p) => p.title.toLowerCase());
    let findDuplicates = titles.filter((item, index) => titles.indexOf(item) !== index);

    if (titles.includes("")) {
      ShowNotificationError("All Spaces must have a name.");
    } else if (findDuplicates.length > 0) {
      ShowNotificationError(`'${findDuplicates[0]}' already exists, please enter another.`);
    } else {
      setLoading(true);
      updateDoc(doc(firestore, "companies", companyId), {
        parking_spaces: parkingSpaces,
      }).then(() => {
        // complete
        setLoading(false);
        ShowNotificationSuccess("Parking Spaces Updated");
      });
    }
  }

  //   ROOMS
  // ROOM ROWS
  const roomRow = rooms
    .sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1;
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1;
      return 0;
    })
    .map(({ id, title, max_bookings, color, is_meeting }) => (
      <tr key={id}>
        <td>
          <TextInput
            value={title}
            placeholder={title}
            disabled={isLoading}
            onChange={(event) => {
              const space = rooms[rooms.findIndex((p) => p.id === id)];
              const ps = rooms.filter((i) => i.id !== id);
              setRooms([...ps, { ...space, title: event.currentTarget.value }]);
            }}
          />
        </td>
        <td>
          <Flex justify="center">
            <Tooltip label="Mark this as a meeting room." withArrow color="blue" openDelay={500}>
              <Checkbox
                checked={is_meeting ?? false}
                onChange={(event) => {
                  const space = rooms[rooms.findIndex((p) => p.id === id)];
                  const ps = rooms.filter((i) => i.id !== id);
                  setRooms([...ps, { ...space, is_meeting: event.currentTarget.checked }]);
                }}
              />
            </Tooltip>
          </Flex>
        </td>
        <td>
          <ColorInput
            defaultValue={color ?? "#fff"}
            disabled={isLoading}
            onChange={(n) => {
              const space = rooms[rooms.findIndex((p) => p.id === id)];
              const ps = rooms.filter((i) => i.id !== id);
              setRooms([...ps, { ...space, color: n }]);
            }}
          />
        </td>
        <td>
          <NumberInput
            defaultValue={max_bookings ?? 0}
            min={1}
            max={50}
            disabled={isLoading}
            onChange={(n) => {
              const space = rooms[rooms.findIndex((p) => p.id === id)];
              const ps = rooms.filter((i) => i.id !== id);
              setRooms([...ps, { ...space, max_bookings: n }]);
            }}
          />
        </td>
        <td>
          <ActionIcon
            disabled={isLoading}
            variant="light"
            color="red"
            onClick={() =>
              openConfirmModal({
                title: <Text weight={700}>Delete '{title}'?</Text>,
                centered: true,
                children: <Text size="sm">Changes are applied when you click 'Update'.</Text>,
                labels: { confirm: "Delete", cancel: "Cancel" },
                confirmProps: { color: "red" },
                onConfirm: () => {
                  setRooms(rooms.filter((i) => i.id !== id));
                },
              })
            }
          >
            <IconTrash size="1rem" color="red" stroke={1.5} />
          </ActionIcon>
        </td>
      </tr>
    ));

  async function updateRooms() {
    const titles = rooms.map((p) => p.title.toLowerCase());
    let findDuplicates = titles.filter((item, index) => titles.indexOf(item) !== index);

    if (titles.includes("")) {
      ShowNotificationError("All rooms must have a name.");
    } else if (findDuplicates.length > 0) {
      ShowNotificationError(`'${findDuplicates[0]}' already exists, please enter another.`);
    } else {
      setLoading(true);
      updateDoc(doc(firestore, "companies", companyId), {
        rooms: rooms,
      }).then(() => {
        // complete
        setLoading(false);
        ShowNotificationSuccess("Rooms Updated");
      });
    }
  }

  const [employees, setEmployees] = useState([]);
  const [employeeApprovalsFrom, setEmployeeApprovalsFrom] = useState(companyData["employee_approvals_from"] ?? []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reloadUsers(), []);

  const reloadUsers = () => {
    if (userToken) {
      apiService
        .getEmployees({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) =>
          setEmployees(
            data.sort(function (a, b) {
              if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1;
              if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
              return 0;
            })
          )
        );
    }
  };

  async function saveChanges() {
    setLoading(true);
    updateDoc(doc(firestore, "companies", companyId), {
      employee_approvals_from: employeeApprovalsFrom,
      annual_leave: annualLeave,
      holiday_review_days: holidayBlackoutDays,
      emails_cc: ccEmails,
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("Holiday Approvals Updated");
    });
  }

  return (
    <Stack p="md">
      <Title order={2}>Booker Settings</Title>

      <Tabs variant="pills" defaultValue="rooms">
        <Tabs.List mb="md">
          <Tabs.Tab value="rooms" icon={<IconBuildingWarehouse size="1.2rem" />}>
            <Text fz="md">Rooms</Text>
          </Tabs.Tab>
          <Tabs.Tab value="parking" icon={<IconCar size="1.2rem" />}>
            <Text fz="md">Parking</Text>
          </Tabs.Tab>
          <Tabs.Tab value="approvals" icon={<IconCheckbox size="1.2rem" />}>
            <Text fz="md">Holiday Approvals</Text>
          </Tabs.Tab>
        </Tabs.List>

        {/* ROOMS */}

        <Tabs.Panel value="rooms" pt="xs">
          <Table verticalSpacing="xs" withBorder>
            <thead>
              <tr>
                <th>Name</th>
                <th>
                  <Flex justify="center">Meeting Room</Flex>
                </th>
                <th>Color</th>
                <th>Max Bookings</th>
                <th>
                  <ActionIcon
                    variant="filled"
                    color="blue"
                    radius={"lg"}
                    disabled={isLoading || rooms.length >= getRemoteNumber(RemotePrams.MAX_ROOMS)}
                    onClick={() => setRooms([...rooms, { id: uuidv4(), title: "", max_bookings: 1 }])}
                  >
                    <IconPlus size="1.3rem" stroke={1.5} />
                  </ActionIcon>
                </th>
              </tr>
            </thead>
            <tbody>{roomRow}</tbody>
          </Table>
          <Flex mt="lg">
            <Button loading={isLoading} disabled={_.isEqual(rooms, companyData["rooms"])} onClick={updateRooms}>
              Update Rooms
            </Button>
          </Flex>
        </Tabs.Panel>

        {/* PARKING */}

        <Tabs.Panel value="parking" pt="xs">
          <Table verticalSpacing="xs" withBorder>
            <thead>
              <tr>
                <th>Name</th>
                <th>Color</th>
                <th>
                  <Text align="center"> Available</Text>
                </th>
                <th>
                  <ActionIcon
                    variant="filled"
                    color="blue"
                    radius="lg"
                    disabled={isLoading || parkingSpaces.length >= getRemoteNumber(RemotePrams.MAX_PARKING)}
                    onClick={() => {
                      setParkingSpaces([...parkingSpaces, { id: uuidv4(), title: "", active: true }]);
                    }}
                  >
                    <IconPlus size="1.3rem" stroke={1.5} />
                  </ActionIcon>
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          <Flex mt="lg">
            <Button
              loading={isLoading}
              disabled={_.isEqual(parkingSpaces, companyData["parking_spaces"])}
              onClick={updateParking}
            >
              Update Parking
            </Button>
          </Flex>
        </Tabs.Panel>

        {/* HOLIDAY APPROVALS */}

        <Tabs.Panel value="approvals" pt="md">
          <Group>
            <NumberInput
              label="Annual Leave Entitlement"
              description="Number of days all employees are entitled to."
              placeholder="25"
              min={25}
              value={annualLeave}
              onChange={setAnnualLeave}
            />
            <NumberInput
              label="Holiday Review Period"
              description="Number of days before employees can book their holiday."
              min={1}
              max={168} // 6 months
              value={holidayBlackoutDays}
              onChange={setHolidayBlackoutDays}
            />
          </Group>
          <MultiSelect
            label="Reviewers"
            description="Reviewers will be included in all holiday requests"
            data={employees.map((e) => {
              return { value: e.email, label: e.displayName };
            })}
            value={ccEmails}
            placeholder="example@host.com..."
            searchable
            onChange={setCcEmails}
          />
          <Text py="sm">Assign admins to each employee to approve their holiday.</Text>

          <ScrollArea h={500}>
            <Table verticalSpacing="xs" withBorder>
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Holiday Offset</th>
                  <th>Admins To Approve Holidays</th>
                </tr>
              </thead>
              <tbody>
                {employees.map(({ uid, displayName, email }) => (
                  <tr key={uid}>
                    <td>
                      <Group spacing="sm">
                        <Avatar size={40} src={companyData["avatar"]} radius={40} />
                        <div>
                          <Text fz="sm" fw={500}>
                            {displayName}
                          </Text>
                          <Text fz="xs" c="dimmed">
                            {email}
                          </Text>
                        </div>
                      </Group>
                    </td>
                    <td>
                      <NumberInput
                        label={`Total Holiday Remaining: ${
                          annualLeave + (employeeApprovalsFrom?.filter((e) => e.id === uid)[0]?.offset ?? 0)
                        }`}
                        step={0.5}
                        precision={1}
                        value={employeeApprovalsFrom.filter((e) => e.id === uid)[0]?.offset ?? 0}
                        onChange={(value) => {
                          var space = employeeApprovalsFrom[employeeApprovalsFrom.findIndex((p) => p.id === uid)];
                          if (!space) {
                            space = { id: uid, admins: [], offset: value };
                          }
                          const ps = employeeApprovalsFrom.filter((i) => i.id !== uid);
                          setEmployeeApprovalsFrom([...ps, { ...space, offset: value }]);
                        }}
                      />
                    </td>
                    <td>
                      <MultiSelect
                        data={employees
                          .filter((d) => d.customClaims["access"] === "admin" || d.customClaims["access"] === "super")
                          .map((e) => {
                            return {
                              value: e.uid,
                              label: `${e.displayName}${ccEmails.includes(e.email) ? " (reviewer included)" : ""}`,
                              disabled:
                                ccEmails.includes(e.email) || (uid === currentUser.uid && e.uid === currentUser.uid),
                            };
                          })}
                        value={employeeApprovalsFrom.filter((e) => e.id === uid)[0]?.admins ?? []}
                        searchable
                        onChange={(value) => {
                          var space = employeeApprovalsFrom[employeeApprovalsFrom.findIndex((p) => p.id === uid)];
                          if (!space) {
                            space = { id: uid, admins: value };
                          }
                          const ps = employeeApprovalsFrom.filter((i) => i.id !== uid);
                          setEmployeeApprovalsFrom([...ps, { ...space, admins: value }]);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
          <Flex mt="lg">
            <Button
              loading={isLoading}
              disabled={
                _.isEqual(employeeApprovalsFrom, companyData["employee_approvals_from"]) &&
                annualLeave === companyData["annual_leave"] &&
                holidayBlackoutDays === companyData["holiday_review_days"] &&
                _.isEqual(ccEmails, companyData["emails_cc"])
              }
              onClick={saveChanges}
            >
              Update Changes
            </Button>
          </Flex>
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
}
