export const tagsHtml = [
    { id: "html", description: "The <html> tag represents the root of an HTML document." },
    {
      id: "head",
      description:
        "The <head> element is a container for metadata (data about data) and is placed between the <html> tag and the <body> tag. Metadata is data about the HTML document. Metadata is not displayed.",
    },
    {
      id: "body",
      description:
        "The <body> element contains all the contents of an HTML document, such as headings, paragraphs, images, hyperlinks, tables, lists, etc.",
    },
    {
      id: "meta",
      description:
        "The <meta> tag defines metadata about an HTML document. Metadata is data (information) about data. <meta> tags always go inside the <head> element, and are typically used to specify character set, page description, keywords, author of the document, and viewport settings. Metadata is used by browsers (how to display content or reload page), search engines (keywords), and other web services.",
      subtitle: "With attributes 'http-equiv' and 'content'",
    },
    {
      id: "style",
      description:
        "The <style> tag is used to define style information (CSS) for a document. Inside the <style> element you specify how HTML elements should render in a browser.",
      subtitle: "With selectors limited to supported HTML tags in this document",
    },
    { id: "p", description: "The <p> tag defines a paragraph." },
    {
      id: "div",
      description:
        "The <div> tag defines a division or a section in an HTML document. The <div> tag is used as a container for HTML elements - which is then styled with CSS or manipulated with JavaScript. The <div> tag is easily styled by using the class or id attribute. Any sort of content can be put inside the <div> tag!",
    },
    {
      id: "h1 h2 h3 h4 h5 h6",
      description:
        "The <header> element represents a container for introductory content or a set of navigational links. You can have several <header> elements in one HTML document. However, <header> cannot be placed within a <footer>, <address> or another <header> element.",
    },
    { id: "ul", description: "The <ul> tag defines an unordered (bulleted) list." },
    {
      id: "ol",
      description: "The <ol> tag defines an ordered list. An ordered list can be numerical or alphabetical.",
    },
    {
      id: "blockquote",
      description:
        "The <blockquote> tag specifies a section that is quoted from another source. Browsers usually indent <blockquote> elements (look at example below to see how to remove the indentation).",
    },
  ];

  export const tagsFormat = [
    { id: "b", description: "The <b> tag specifies bold text without any extra importance." },
    {
      id: "i",
      description:
        "The <i> tag defines a part of text in an alternate voice or mood. The content inside is typically displayed in italic.",
    },
    {
      id: "s",
      description:
        "The <s> tag specifies text that is no longer correct, accurate or relevant. The text will be displayed with a line through it.",
    },
    {
      id: "u",
      description:
        "The <u> tag represents some text that is unarticulated and styled differently from normal text, such as misspelled words or proper names in Chinese text. The content inside is typically displayed with an underline. You can change this with CSS (see example below).",
    },
    { id: "o", description: "" },
    {
      id: "sup",
      description:
        "The <sup> tag defines superscript text. Superscript text appears half a character above the normal line, and is sometimes rendered in a smaller font. Superscript text can be used for footnotes, like WWW.",
    },
    {
      id: "sub",
      description:
        "The <sub> tag defines subscript text. Subscript text appears half a character below the normal line, and is sometimes rendered in a smaller font. Subscript text can be used for chemical formulas, like H2O.",
    },
    {
      id: "ins",
      description:
        "The <ins> tag defines a text that has been inserted into a document. Browsers will usually underline inserted text.",
    },
    {
      id: "del",
      description:
        "The <del> tag defines text that has been deleted from a document. Browsers will usually strike a line through deleted text.",
    },
    {
      id: "strong",
      description:
        "The <strong> tag is used to define text with strong importance. The content inside is typically displayed in bold.",
    },
    { id: "strike", description: "The <strike> tag was used in HTML 4 to define strikethrough text." },
    { id: "tt", description: "The <tt> tag was used in HTML 4 to define teletype text." },
    {
      id: "code",
      description:
        "The <code> tag is used to define a piece of computer code. The content inside is displayed in the browser's default monospace font.",
    },
    { id: "big", description: "The <big> tag was used in HTML 4 to define bigger text." },
    { id: "small", description: "The <small> tag defines smaller text (like copyright and other side-comments)." },
    { id: "br", description: "The <br> tag inserts a single line break." },
    {
      id: "span",
      description: "The <span> tag is an inline container used to mark up a part of a text, or a part of a document.",
    },
    {
      id: "em",
      description:
        "The <em> tag is used to define emphasized text. The content inside is typically displayed in italic. A screen reader will pronounce the words in <em> with an emphasis, using verbal stress.",
    },
  ];