import React, { useEffect, useState } from "react";
import { Skeleton, Title, Stack, SimpleGrid, NavLink, Paper, Input, ScrollArea, CloseButton } from "@mantine/core";
import * as apiService from "../../../api-service";
import { IconBriefcase, IconChevronRight, IconSearch } from "@tabler/icons";

export default function TestrailProjectsModule({
  userToken,
  companyId,
  userClaims,
  selectedProject,
  suiteId,
  selectedRun,
  runInfo,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const [allProjects, setAllProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState([]);

  const [active, setActive] = useState(0);

  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (userToken) {
      apiService
        .getTestrailProjects({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setAllProjects(data);

          if (userClaims["testrail_projects"]) {
            setCurrentProjects(userClaims["testrail_projects"]);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, [userToken, companyId, userClaims]);

  const clientProjects = currentProjects.map((id) =>
    allProjects
      .filter((p) => p.id === id)
      .filter((p) => p.name.toLowerCase().includes(filterBy.toLowerCase()))
      .map((proj) => {
        return (
          <Paper key={proj.id} shadow="sm" radius="md">
            <NavLink
              active={active === id}
              label={proj.name}
              description={proj.announcement}
              icon={<IconBriefcase size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="1rem" stroke={1.5} />}
              onClick={() => {
                setActive(proj.id);
                selectedProject(proj.id);
                suiteId(null);
                selectedRun(null);
                runInfo(null);
              }}
              color="blue"
              variant="filled"
            />
          </Paper>
        );
      })
  );

  const allTestrail = allProjects
    .filter((p) => p.name.toLowerCase().includes(filterBy.toLowerCase()))
    .map(({ id, name, announcement }) => (
      <Paper key={id} shadow="sm" radius="md">
        <NavLink
          active={active === id}
          label={name}
          description={announcement}
          icon={<IconBriefcase size="1rem" stroke={1.5} />}
          rightSection={<IconChevronRight size="1rem" stroke={1.5} />}
          onClick={() => {
            setActive(id);
            selectedProject(id);
            suiteId(null);
            selectedRun(null);
            runInfo(null);
          }}
          color="blue"
          variant="filled"
        />
      </Paper>
    ));

  if (isLoading)
    return (
      <SimpleGrid cols={1}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
          return <Skeleton key={index} radius="md" height={54} />;
        })}
      </SimpleGrid>
    );

  if (userClaims["access"] !== "client") {
    // user is not a client
    return (
      <Stack>
        <Input
          icon={<IconSearch />}
          placeholder="Search..."
          onChange={(e) => setFilterBy(e.currentTarget.value)}
          value={filterBy}
          disabled={isLoading}
          rightSection={
            filterBy.length > 0 && (
              <CloseButton
                aria-label="clear field"
                onClick={() => {
                  setFilterBy("");
                  setActive(null);
                  selectedProject(null);
                  suiteId(null);
                  selectedRun(null);
                  runInfo(null);
                }}
              />
            )
          }
        />
        {allTestrail.length > 0 ? (
          <ScrollArea.Autosize mah={750} w="100%" type="always" offsetScrollbars>
            <SimpleGrid cols={1}>{allTestrail}</SimpleGrid>
          </ScrollArea.Autosize>
        ) : (
          <Stack mt={32}>
            <Title order={2} align="center">
              No Projects Available
            </Title>
            <Title order={5} color="gray" align="center">
              Please contact the company admin.
            </Title>
          </Stack>
        )}
      </Stack>
    );
  } else {
    // user is a cliet
    return (
      <Stack>
        <Input
          icon={<IconSearch />}
          placeholder="Search..."
          onChange={(e) => setFilterBy(e.currentTarget.value)}
          value={filterBy}
          disabled={isLoading}
          rightSection={
            filterBy.length > 0 && (
              <CloseButton
                aria-label="clear field"
                onClick={() => {
                  setFilterBy("");
                  setActive(null);
                  selectedProject(null);
                  suiteId(null);
                  selectedRun(null);
                  runInfo(null);
                }}
              />
            )
          }
        />
        {clientProjects.length > 0 ? (
          <ScrollArea.Autosize mah={750} w="100%" offsetScrollbars>
            <SimpleGrid cols={1}>{clientProjects}</SimpleGrid>
          </ScrollArea.Autosize>
        ) : (
          <Stack mt={32}>
            <Title order={2} align="center">
              No Projects Available
            </Title>
            <Title order={5} color="gray" align="center">
              Please contact the company admin.
            </Title>
          </Stack>
        )}
      </Stack>
    );
  }
}
