import "./App.css";

import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import { auth } from "./firebase-config";
import PageNotFound from "./pages/errors/NotFound";
import { Center, Flex, Loader } from "@mantine/core";
import Automations from "./pages/automations/Automations";
import SMSMonitor from "./pages/tools/sms";
import Employees from "./pages/Employees/Employees";
import Clients from "./pages/Clients/Clients";
import * as apiService from "./api-service";
import { firestore } from "./firebase-config";
import { doc, onSnapshot } from "firebase/firestore";
import BusinessEdit from "./pages/business/BusinessEdit";
// import BusinessCreate from "./pages/business/BusinessCreate";
import Action from "./pages/action";
import Dashboard from "./pages/dashboard";
import AutomationDetails from "./pages/automations/AutomationDetails";
import {
  AppShell,
  Navbar,
  Header,
  Burger,
  useMantineTheme,
  MediaQuery,
} from "@mantine/core";
import { AppNavBar } from "./components/AppNavBar";
import CardArtworkVerification from "./pages/tools/card-artwork-verification";
import HtmlVerification from "./pages/tools/html-verification";
import Testrail from "./pages/testrail/testrail";
import JiraBoards from "./pages/jira/boards/Boards";
import Booker2 from "./pages/booker/booker";
import { ReactComponent as CellSoftwareLogo } from "./images/cell-software-logo.svg";

function App() {
  const [loading, setLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserClaims, setCurrentUserClaims] = useState(null);
  const [currentUserToken, setCurrentUserToken] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setcompanyData] = useState(null);

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const [navBarButton, setNavBarButton] = useState();

  // Track whether the user is logged in or not
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((tokenId) => {
          setCurrentUserToken(tokenId);
          apiService
            .getUser({
              userIdToken: tokenId,
              uid: user.uid,
            })
            .then((data) => {
              if (data["claims"]["companyId"] && data["claims"]["access"]) {
                const companyId = data["claims"]["companyId"];

                setCurrentUserClaims(data["claims"]);

                onSnapshot(doc(firestore, "companies", companyId), (doc) => {
                  setcompanyData(doc);
                  setCompanyId(companyId);
                  setCurrentUser(user);
                  setLoading(false);
                });
              } else {
                setCurrentUser(null);
                setLoading(false);
              }
            })
            .catch((e) => {
              console.error(e);
              setCurrentUser(null);
              setLoading(false);
            });
        });
      } else {
        setLoading(false);
        // alert("Session has timed out. Please login again.");
      }
    });
  }, []);

  if (loading)
    return (
      <Center
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loader size="xl" />
      </Center>
    );

  return (
    <Router>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }}
        hidden={currentUserClaims === null}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        header={
          <Header height={{ base: 68 }} p="md">
            <Flex justify="space-between" align="center" mt={-4}>
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <CellSoftwareLogo height={28} />
              {navBarButton}
            </Flex>
          </Header>
        }
        navbar={
          <Navbar
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 300, lg: 300 }}
          >
            {currentUserClaims && (
              <AppNavBar
                currentUser={currentUser}
                userClaims={currentUserClaims}
                companyData={companyData?.data() ?? {}}
                isMenuOpen={opened}
                closeMenu={() => setOpened(false)}
              />
            )}
          </Navbar>
        }
        // layout="alt"
        // footer={<AppFooter />}
      >
        {/* The available paths on the site */}
        <Routes>
          {/* login only accessible when use is logged out */}

          {/* Dashboard */}
          {currentUser ? (
            currentUserClaims["access"] !== "client" ? (
              <Route path="/" element={<Dashboard companyId={companyId} />} />
            ) : (
              <Route path="/" element={<Navigate to="/automations" />} />
            )
          ) : (
            <Route path="/" element={<Login />} />
          )}

          {currentUser && currentUserClaims["access"] !== "client" && (
            <Route
              path="/booker"
              element={
                <Booker2
                  userToken={currentUserToken}
                  companyData={companyData.data()}
                  companyId={companyId}
                  userClaims={currentUserClaims}
                  currentUser={currentUser}
                  navButton={setNavBarButton}
                />
              }
            />
          )}

          {/* Automation */}
          {currentUser &&
            companyData.data()["azure"] &&
            (companyData.data()["azure"]["automation_enabled"] ?? false) && (
              <Route
                path="/automations"
                element={
                  <Automations
                    userToken={currentUserToken}
                    companyId={companyId}
                    userClaims={currentUserClaims}
                  />
                }
              />
            )}
          {currentUser &&
            companyData.data()["azure"] &&
            (companyData.data()["azure"]["automation_enabled"] ?? false) && (
              <Route
                path="/automations/:id"
                element={
                  <AutomationDetails
                    userToken={currentUserToken}
                    companyId={companyId}
                  />
                }
              />
            )}
          {currentUser &&
            companyData.data()["azure"] &&
            (companyData.data()["azure"]["automation_enabled"] ?? false) && (
              <Route
                path="/automations/:id"
                element={
                  <AutomationDetails
                    userToken={currentUserToken}
                    companyId={companyId}
                  />
                }
              />
            )}

          {/* TestRail */}

          {currentUser && companyData.data()["testrail"]["enabled"] && (
            <Route
              path="/testrail"
              element={
                <Testrail
                  userToken={currentUserToken}
                  companyId={companyId}
                  userClaims={currentUserClaims}
                />
              }
            />
          )}

          {/* JIRA BOARDS */}

          {currentUser &&
            (companyData?.data()["jira"]["boards_enabled"] ?? false) && (
              <Route
                path="/jira"
                element={
                  <JiraBoards
                    userToken={currentUserToken}
                    companyId={companyId}
                    userClaims={currentUserClaims}
                  />
                }
              />
            )}

          {/* SMS Messaging */}
          {currentUser &&
            currentUserClaims["access"] !== "client" &&
            companyData.data()["sms"]["enabled"] && (
              <Route
                path="/sms-messages"
                element={
                  <SMSMonitor
                    userToken={currentUserToken}
                    companyId={companyId}
                    companyData={companyData}
                  />
                }
              />
            )}

          {/* Employees */}
          {currentUser && currentUserClaims["access"] !== "client" && (
            <Route
              path="/employees"
              element={
                <Employees
                  userToken={currentUserToken}
                  userId={currentUser.uid}
                  companyId={companyId}
                  companyData={companyData}
                  userAccess={currentUserClaims["access"]}
                  navButton={setNavBarButton}
                />
              }
            />
          )}

          {/* Clients */}
          {currentUser && currentUserClaims["access"] !== "client" && (
            <Route
              path="/clients"
              element={
                <Clients
                  userId={currentUser.uid}
                  userToken={currentUserToken}
                  companyId={companyId}
                  companyData={companyData}
                  userAccess={currentUserClaims["access"]}
                  navButton={setNavBarButton}
                />
              }
            />
          )}

          {currentUser && (currentUserClaims["durian_member"] ?? false) && (
            <>
              <Route
                path="/card-artwork-verification"
                element={
                  <CardArtworkVerification companyData={companyData.data()} />
                }
              />
              <Route
                path="/html-verification"
                element={<HtmlVerification companyData={companyData.data()} />}
              />
            </>
          )}

          {/* Business Edit */}
          {currentUser &&
            (currentUserClaims["access"] === "admin" ||
              currentUserClaims["access"] === "super") && (
              <Route
                path="/company/edit"
                element={
                  <BusinessEdit
                    userToken={currentUserToken}
                    companyId={companyId}
                    companyData={companyData.data()}
                    currentUser={currentUser}
                  />
                }
              />
            )}

          {/* Buasiness Create */}
          {/* {currentUser && currentUserClaims["access"] === "super" && (
            <Route
              path="/company/create"
              element={
                <BusinessCreate userToken={currentUserToken} companyId={companyId} companyData={companyData.data()} />
              }
            />
          )} */}

          {/* ACTION */}
          <Route path="/action" element={<Action />} />

          {/* Invalid path go to a 404 page */}
          {currentUser !== null ? (
            <Route path="*" element={<PageNotFound />} />
          ) : (
            <Route path="*" element={<Login />} />
          )}
        </Routes>
      </AppShell>
    </Router>
  );
}

export default App;
