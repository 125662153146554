import React, { useEffect, useState } from "react";
import {
  Space,
  Tabs,
  Paper,
  Title,
  Text,
  Center,
  Card,
  Image,
  Group,
  Badge,
  Stack,
  TypographyStylesProvider,
  MediaQuery,
} from "@mantine/core";
import ModuleAppleReleases from "./releases/ModuleAppleReleases";
import ModuleGoogleReleases from "./releases/ModuleGoogleRelease";
import ModuleBrowserRelease from "./releases/ModuleBrowserRelease";

import { getAnnouncements } from "../services/Firestore";

function Dashboard({ companyId }) {
  useEffect(() => {
    document.title = "Dashboard - Cell Software Portal";
  }, []);

  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    if (companyId) {
      getAnnouncements(companyId)
        .then((snapshot) => {
          setAnnouncements(snapshot.docs ?? []);
        })
        .catch(() => {});
    }
  }, [companyId]);

  return (
    <Stack spacing="xl">
      {/* MAIN */}
      <MediaQuery smallerThan="md" styles={{ display: "none" }}>
        <Group grow>
          <ModuleBrowserRelease />
          <ModuleAppleReleases />
          <ModuleGoogleReleases />
        </Group>
      </MediaQuery>
      <MediaQuery largerThan="md" styles={{ display: "none" }}>
        <Group>
          <Group grow>
            <ModuleAppleReleases />
            <ModuleGoogleReleases />
          </Group>
          <ModuleBrowserRelease />
        </Group>
      </MediaQuery>

      <Tabs variant="pills" radius="xl" defaultValue="announcements">
        <Tabs.List>
          <Tabs.Tab value="announcements" color="cyan">
            Announcements
          </Tabs.Tab>
        </Tabs.List>
        <Space h="md" />
        <Tabs.Panel value="announcements" pt="xs">
          {announcements.length === 0 ? (
            <Paper shadow="sm" radius="md" p="md" withBorder>
              <Center>
                <Title order={3} weight={400}>
                  No Announcements
                </Title>
              </Center>
            </Paper>
          ) : (
            announcements.map((i) => {
              return (
                <Card key={i.id} shadow="sm" p="lg" radius="md" withBorder mb={24}>
                  {i.data().image && (
                    <Card.Section>
                      <Image src={i.data().image} height={160} alt={`${i.data().title} image`} />
                    </Card.Section>
                  )}

                  <Group position="apart" mt="md" mb="xs">
                    <Title order={3} color="cyan">
                      {i.data().title}
                    </Title>
                    <Text size="sm" color="dimmed">
                      {i.data().created.toDate().toUTCString().slice(0, -7)}
                    </Text>
                  </Group>

                  <TypographyStylesProvider>
                    <Text size="sm" dangerouslySetInnerHTML={{ __html: i.data().body }} />
                  </TypographyStylesProvider>

                  {i.data().tags &&
                    i.data().tags.map((t) => {
                      return (
                        <Badge key={t} color="cyan" variant="light" mt={16} mr={10}>
                          {t}
                        </Badge>
                      );
                    })}
                </Card>
              );
            })
          )}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
}

export default Dashboard;
