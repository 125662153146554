import React, { useEffect, useState } from "react";
import { SimpleGrid, Center, Loader, Title, Stack } from "@mantine/core";
import * as apiService from "../../api-service";
import CardItem from "../../components/CardItem";

function Automations({ userToken, companyId, userClaims }) {
  const [formLoaderIsVisible, setFormLoaderIsVisible] = useState(true);

  const [allProjects, setAllProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState([]);

  useEffect(() => {
    document.title = "Automation - Cell Software Portal";
  });

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationProjects({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setAllProjects(data);

          if (userClaims["automation_projects"]) {
            setCurrentProjects(userClaims["automation_projects"]);
          }
          setFormLoaderIsVisible(false);
        })
        .catch((e) => {
          console.error(e);
          setFormLoaderIsVisible(false);
        });
    }
  }, [userToken, companyId, userClaims]);

  const clientAutomations = currentProjects.map((id) =>
    allProjects
      .filter((p) => p.id === id)
      .map((proj) => {
        return (
          <CardItem
            key={proj.id}
            id={proj.id}
            title={proj.name}
            description={proj.description}
            imageUrl="https://images.unsplash.com/photo-1481026469463-66327c86e544?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1508&q=80"
            buttonTitle="View"
          />
        );
      })
  );

  const allAutomations = allProjects.map(({ id, name, description }) => (
    <CardItem
      key={id}
      id={id}
      title={name}
      description={description}
      imageUrl="https://images.unsplash.com/photo-1481026469463-66327c86e544?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1508&q=80"
      buttonTitle="View"
    />
  ));

  if (formLoaderIsVisible)
    return (
      <Center>
        <Loader />
      </Center>
    );

  if (userClaims["access"] !== "client") {
    // user is not a client
    return (
      <>
        {allAutomations.length > 0 ? (
          <SimpleGrid cols={3}>{allAutomations}</SimpleGrid>
        ) : (
          <Stack m={100}>
            <Title order={2} align="center">
              No Automations Available
            </Title>
            <Title order={5} color="gray" align="center">
              Please contact the company admin.
            </Title>
          </Stack>
        )}
      </>
    );
  } else {
    // user is a cliet
    return (
      <>
        {clientAutomations.length > 0 ? (
          <SimpleGrid cols={3}>{clientAutomations}</SimpleGrid>
        ) : (
          <Stack m={100}>
            <Title order={2} align="center">
              No Automations Available
            </Title>
            <Title order={5} color="gray" align="center">
              Please contact the company admin.
            </Title>
          </Stack>
        )}
      </>
    );
  }
}

export default Automations;
