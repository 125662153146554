import React, { useEffect, useState } from "react";
import { Paper, Tabs, Stack } from "@mantine/core";
import { IconListDetails, IconRocket } from "@tabler/icons";
import * as apiService from "../../api-service";
import AutomationBuildModule from "./Models/ModuleAutomationBuilds";
import AutomationPipelineModule from "./Models/ModuleAutomationPipeline";

export default function AutomationDetails({ userToken, companyId }) {
  useEffect(() => {
    document.title = `${project.name} - Cell Software Portal`;
  });

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});

  const projectId = window.location.href.split("/")[window.location.href.split("/").length - 1];

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationProject({
          userIdToken: userToken,
          companyId: companyId,
          projectId: projectId,
        })
        .then((data) => {
          setProject(data);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  }, [userToken, companyId, projectId]);

  return (
    <>
      <h1>{isLoading ? "Loading..." : project.name}</h1>
      {project.description && <p style={{ marginTop: "-24px" }}>{project.description}</p>}
      <Paper shadow="md" p="lg" withBorder>
        <Tabs defaultValue="pipelines" variant="pills" radius="xl">
          <Tabs.List>
            <Tabs.Tab value="pipelines" icon={<IconRocket size={16} />}>
              Pipelines
            </Tabs.Tab>
            <Tabs.Tab value="builds" icon={<IconListDetails size={16} />}>
              Builds
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="pipelines">
            <Stack p={16}>
              <AutomationPipelineModule userToken={userToken} companyId={companyId} projectId={projectId} />
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value="builds">
            <AutomationBuildModule userToken={userToken} companyId={companyId} projectId={projectId} />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
}
