import React, { useState } from "react";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase-config";
import {
  Button,
  Input,
  Stack,
  Switch,
  Text,
  Table,
  ActionIcon,
  Flex,
  Tooltip,
  Modal,
  Title,
} from "@mantine/core";
import { IconTrash, IconInfoCircle, IconPlus, IconPencil } from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import SMSAdd from "./sms-add";
import _ from "lodash";
import { ShowNotificationSuccess } from "../../../components/Notifications";

export default function BusinessEditSMS({ companyData, companyId }) {
  const [isLoading, setLoading] = useState(false);

  const [isSMSEnabled, setSMSEnabled] = useState(companyData["sms"]["enabled"]);
  const [smsUserKey, setSMSUserKey] = useState(companyData["sms"]["user_key"]);
  const [smsSims, setSmsSims] = useState(companyData["sms"]["sims"] ?? []);
  const [smsAccessToken, setSMSAccessToken] = useState(
    companyData["sms"]["access_token"]
  );

  const [isNewNumberOpen, setNewNumberOpen] = useState(false);

  const [isEditNumberOpen, setEditNumberOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);

  async function submitSMSUpdates() {
    setLoading(true);

    updateDoc(doc(firestore, "companies", companyId), {
      sms: {
        access_token: smsAccessToken,
        enabled: isSMSEnabled,
        user_key: smsUserKey,
        sims: smsSims,
      },
      updated: serverTimestamp(),
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("SMS History Updated");
    });
  }

  const rows = smsSims.map(({ label, value, group }) => (
    <tr key={value}>
      <td>
        <Text>{label}</Text>
      </td>
      <td>
        <Text>{value}</Text>
      </td>
      <td>
        <Text>{group && group !== "" ? group : "-"}</Text>
      </td>
      <td>
        {isSMSEnabled && (
          <Flex align={"center"} gap={8}>
            <ActionIcon
              onClick={() => {
                setEditItem({
                  description: value,
                  group: group,
                  label: label,
                  value: value,
                });
                setEditNumberOpen(true);
              }}
            >
              <IconPencil size="1rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon onClick={() => confirmDelete(label, value)}>
              <IconTrash size="1rem" color="red" stroke={1.5} />
            </ActionIcon>
          </Flex>
        )}
      </td>
    </tr>
  ));

  const confirmDelete = (name, number) => {
    openConfirmModal({
      title: <Text weight={700}>Delete {name}?</Text>,
      centered: true,
      children: (
        <Text size="sm">Changes are applied when you click 'Update'.</Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        setSmsSims(smsSims.filter((i) => i.value !== number));
      },
    });
  };

  return (
    <>
      <Modal
        centered
        closeOnClickOutside={false}
        opened={isNewNumberOpen}
        onClose={() => setNewNumberOpen(!isNewNumberOpen)}
        title="New Number"
      >
        <SMSAdd
          sims={smsSims}
          completed={(d) => {
            smsSims.push(d);
            setNewNumberOpen(false);
          }}
        />
      </Modal>
      <Modal
        centered
        closeOnClickOutside={false}
        opened={isEditNumberOpen}
        onClose={() => setEditNumberOpen(!isEditNumberOpen)}
        title="Edit Number"
      >
        <SMSAdd
          sims={smsSims}
          completed={(d) => {
            setSmsSims(smsSims.filter((i) => i.value !== d.value).concat(d));
            setEditNumberOpen(false);
          }}
          editItem={editItem}
        />
      </Modal>
      <Stack p={16}>
        <Title order={2}>SMS Messaging</Title>

        <Input.Wrapper
          label="Primary User API Key"
          description="The primary user API key to be used to gain access to TextAnywhere."
          required
        >
          <Input
            placeholder={smsAccessToken}
            disabled={isLoading}
            value={smsAccessToken}
            onChange={(e) => setSMSAccessToken(e.currentTarget.value.trim())}
            autoComplete="no"
            radius="lg"
            m={8}
          />

          <Input.Wrapper
            label="Primary User Key"
            description="Must be the User Key from the same account the API key was generated from."
            required
          >
            <Input
              placeholder={smsUserKey}
              disabled={isLoading}
              value={smsUserKey}
              onChange={(e) => setSMSUserKey(e.currentTarget.value.trim())}
              autoComplete="no"
              type={"number"}
              radius="lg"
              m={8}
            />
          </Input.Wrapper>
        </Input.Wrapper>

        <Input.Wrapper label="Enable SMS Messages" mb={8}>
          <Switch
            mt={8}
            checked={isSMSEnabled}
            onChange={(e) => setSMSEnabled(e.currentTarget.checked)}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Available SIMs">
          <Table verticalSpacing="sm" withBorder mb={8} mt={8} striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>
                  <Flex align={"center"}>
                    <Text>Group Name</Text>
                    <Tooltip
                      label="For SIMs to be gropped together, they must have the same name."
                      color="grey"
                      withArrow
                      multiline
                      width={300}
                    >
                      <ActionIcon variant="transparent">
                        <IconInfoCircle size="1rem" stroke={1.5} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </th>
                <th>
                  {isSMSEnabled && (
                    <ActionIcon
                      variant="outline"
                      color="blue"
                      onClick={() => setNewNumberOpen(true)}
                    >
                      <IconPlus size="1.3rem" stroke={1.5} />
                    </ActionIcon>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Input.Wrapper>

        <Button
          mt={16}
          disabled={
            smsAccessToken === companyData["sms"]["access_token"] &&
            smsUserKey === companyData["sms"]["user_key"] &&
            isSMSEnabled === companyData["sms"]["enabled"] &&
            _.isEqual(companyData["sms"]["sims"] ?? [], smsSims)
          }
          onClick={submitSMSUpdates}
        >
          Update Details
        </Button>
      </Stack>
    </>
  );
}
