import React, { useEffect, useState } from "react";
import { Tabs, Stack, Input, ScrollArea, Loader } from "@mantine/core";
import * as apiService from "../../../api-service";
import { IconCircleCheck, IconCircleX, IconCircleMinus } from "@tabler/icons";

export default function AutomationPipelineRunsModule({ userToken, companyId, projectId, pipelineId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [runs, setRuns] = useState([]);

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationPipelineRuns({
          userIdToken: userToken,
          companyId: companyId,
          projectId: projectId,
          pipelineId: pipelineId,
        })
        .then((data) => {
          setRuns(data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, [userToken, companyId, projectId, pipelineId]);

  const buildResultIcon = (result) => {
    if (result) {
      if (result === "canceled") {
        return <IconCircleMinus color="gray" size={20} />;
      } else if (result === "succeeded") {
        return <IconCircleCheck color="green" size={20} />;
      }
    }
    return <IconCircleX color="red" size={20} />;
  };

  const buildTabs = runs.map(({ id, name, state, result }) => (
    <Tabs.Tab
      key={`${id}`}
      value={`${id}`}
      icon={state === "inProgress" ? <Loader color="yellow" size="xs" /> : buildResultIcon(result)}
    >
      {name}
    </Tabs.Tab>
  ));

  const buildContent = runs.map(({ id, createdDate }) => (
    <Tabs.Panel key={`${id}`} value={`${id}`} pl="xs">
      <Stack pl={16} pr={16}>
        <Input.Wrapper label="Created">
          <p>{createdDate}</p>
        </Input.Wrapper>
      </Stack>
    </Tabs.Panel>
  ));

  return (
    <Stack p={16}>
      {!isLoading ? (
        runs.length > 0 ? (
          <Tabs variant="outline" orientation="vertical" defaultValue={`${runs[0].id}`}>
            <Tabs.List>
              <ScrollArea style={{ height: 600 }} type="always" offsetScrollbars>
                {buildTabs}
              </ScrollArea>
            </Tabs.List>
            {buildContent}
          </Tabs>
        ) : (
          <h2>No Builds Available</h2>
        )
      ) : (
        <h2>Loading</h2>
      )}
    </Stack>
  );
}
