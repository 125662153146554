import React, { useEffect, useState } from "react";
import { Tabs, Stack } from "@mantine/core";
import * as apiService from "../../../api-service";
import { IconCircleCheck } from "@tabler/icons";
import AutomationPipelineRunsModule from "./ModuleAutomationRuns";

export default function AutomationPipelineModule({ userToken, companyId, projectId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pipelines, setPipelines] = useState([]);

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationPipelines({
          userIdToken: userToken,
          companyId: companyId,
          projectId: projectId,
        })
        .then((data) => {
          setPipelines(data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, [userToken, companyId, projectId]);

  const buildTabs = pipelines.map(({ id, name }) => (
    <Tabs.Tab key={`${id}`} value={`${id}`} style={{ maxWidth: 200 }} icon={<IconCircleCheck size={20} />}>
      {name}
    </Tabs.Tab>
  ));

  const buildContent = pipelines.map(({ id }) => (
    <Tabs.Panel key={`${id}`} value={`${id}`} pl="xs">
      <Stack ml={-6}>
        <AutomationPipelineRunsModule
          userToken={userToken}
          companyId={companyId}
          projectId={projectId}
          pipelineId={id}
        />
      </Stack>
    </Tabs.Panel>
  ));

  return (
    <Stack p={16}>
      {!isLoading ? (
        pipelines.length > 0 ? (
          <Tabs color="violet" variant="pills" radius="xl" defaultValue={`${pipelines[0].id}`} ml={-32} mt={-16}>
            <Tabs.List>{buildTabs}</Tabs.List>
            {buildContent}
          </Tabs>
        ) : (
          <h2>No Pipelines Available</h2>
        )
      ) : (
        <h2>Loading</h2>
      )}
    </Stack>
  );
}
