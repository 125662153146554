import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  TextInput,
  PasswordInput,
  LoadingOverlay,
  Checkbox,
  Space,
  Title,
  MultiSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons";
import { generate } from "@wcj/generate-password";
import * as apiService from "../../api-service";

function ClientCreate({ userToken, companyId, emailDomain, setModalVisible }) {
  const [formLoaderIsVisible, setFormLoaderIsVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isDisclosureVisible, setDisclosureVisible] = useState(true);

  const [sendVerification, setSendVerification] = useState(true);

  const [projects, setProjects] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationProjects({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setProjects(data);
          setProjectNames(
            data.map((i) => {
              return i.name;
            })
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [userToken, companyId]);

  function submitNewClient({ name, email, password }) {
    if (userToken && companyId) {
      if (email.includes("@" + emailDomain)) {
        // domain strict enabled, does not include company domain
        form.setFieldError(
          "email",
          "Email must not use company domain: @" + emailDomain
        );
      } else {
        setFormLoaderIsVisible(true);

        apiService
          .createUser({
            userIdToken: userToken,
            companyId: companyId,
            name: name,
            email: email,
            password: password,
            role: "client",
            sendVerification: sendVerification,
            automationProjects: selectedProjectIds,
          })
          .then(() => setModalVisible(false))
          .catch((e) => setModalVisible(false));
      }
    }
  }

  const form = useForm({
    initialValues: { name: "", email: "", password: generate({ length: 12 }) },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) =>
        value.length < 2 ? "Full Name must have at least 4 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
      password: (value) => (/^\S+$/.test(value) ? null : "Password is empty"),
    },
  });

  return (
    <Stack>
      <LoadingOverlay visible={formLoaderIsVisible} overlayBlur={2} />
      <form
        onSubmit={form.onSubmit((values) => {
          submitNewClient({
            name: values.name,
            email: values.email,
            password: values.password,
          });
        })}
      >
        <Stack>
          <TextInput
            mt="sm"
            label="Full Name"
            placeholder="Joe Bloggs"
            description="First and Last name"
            {...form.getInputProps("name")}
            required
          />
          <TextInput
            mt="sm"
            label="Email"
            placeholder="example@domain.com"
            {...form.getInputProps("email")}
            required
          />
          <Stack>
            <PasswordInput
              label="Password"
              description="Password must include at least one letter, number and special character"
              placeholder="Password"
              {...form.getInputProps("password")}
              icon={<IconLock size={16} />}
              visible={isDisclosureVisible}
              onVisibilityChange={setDisclosureVisible}
              required
            />
            <Button
              color={"cyan"}
              onClick={() => {
                // Create a random password
                const randomPassword = generate({ length: 12 });

                // Set the generated password as state
                form.setFieldValue("password", randomPassword);
                setDisclosureVisible(true);
              }}
            >
              Regenerate Password
            </Button>
          </Stack>

          <Space spacing={8} />
          <Title order={3}>Options</Title>
          <Stack>
            <MultiSelect
              data={projectNames}
              label="Automation Projects"
              description="Select projects the client has access to."
              placeholder="Search for a project"
              searchable
              nothingFound="No projects found"
              onChange={(e) => {
                const projectIds = [];
                // convert project names to their IDs
                e.forEach((t) => {
                  projects.forEach((p) => {
                    if (p.name === t) {
                      projectIds.push(p.id);
                    }
                  });
                });
                setSelectedProjectIds(projectIds);
              }}
              clearable
            />
            <Checkbox
              label="Send Email Verification"
              description="Send an email for the user to verify."
              checked={sendVerification}
              onChange={(e) => {
                setSendVerification(e.target.checked);
              }}
            />
          </Stack>
          {/* Login error text */}
          {loginError && <p style={{ color: "red" }}>{loginError}</p>}
          <Button
            type="submit"
            mt="xl"
            onClick={() => {
              setLoginError(null);
            }}
          >
            New Client
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default ClientCreate;
