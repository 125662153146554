import React, { useState } from "react";
import { Button, MultiSelect, Stack, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { requestNewHoliday } from "../../../services/Firestore";
import moment from "moment";

export default function BookerCreateHoliday({
  userToken,
  companyId,
  companyData,
  employees,
  onCompletion,
  currentUser,
  allEvents,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment()
      .add(companyData["holiday_review_days"] ?? 1, "day")
      .day() === 0
      ? moment().add((companyData["holiday_review_days"] ?? 1) + 1, "day")
      : moment()
          .add(companyData["holiday_review_days"] ?? 1, "day")
          .day() === 6
      ? moment().add((companyData["holiday_review_days"] ?? 1) + 2, "day")
      : moment()
          .startOf("day")
          .add(companyData["holiday_review_days"] ?? 1, "day")
          .toDate()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [employeeApprovals] = useState([
    ...((companyData["employee_approvals_from"] ?? [])?.filter((e) => e.id === currentUser.uid)[0]?.admins ?? []),
    (companyData["emails_cc"] ?? []).map((rEmail) => {
      return employees.filter((emp) => emp.email === rEmail)[0].uid;
    })[0],
  ]);
  const [extraEmployees, setExtraEmployees] = useState([]);

  const [isDateAlreadyBooked, setDateAlreadyBooked] = useState(
    allEvents.filter(
      (d) =>
        d.data().type === "holiday" &&
        moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment().format("DDMMYYYY") &&
        d.data().created_by === currentUser.uid
    ).length > 0
  );

  // Amount of holiday created by user of selected year
  const [totalBookedOffHoliday] = useState(
    allEvents
      .filter(
        (d) =>
          d.data().type === "holiday" &&
          d.data().created_by === currentUser.uid &&
          moment(d.data().date_start.toDate()).isBetween(
            moment(selectedStartDate).startOf("year"),
            moment(selectedStartDate).endOf("year")
          ) &&
          moment(d.data().date_end.toDate()).isBetween(
            moment(selectedStartDate).startOf("year"),
            moment(selectedStartDate).endOf("year")
          )
      )
      .map((d) => {
        // remove weekends
        return Number(
          moment(d.data().date_end.toDate()).diff(moment(d.data().date_start.toDate()), "days") >= 9
            ? moment(d.data().date_end.toDate()).diff(moment(d.data().date_start.toDate()), "days") - 2
            : moment(d.data().date_end.toDate()).diff(moment(d.data().date_start.toDate()), "days") === 0
            ? // if only booked off one day, add 1 to 0
              moment(d.data().date_end.toDate()).diff(moment(d.data().date_start.toDate()), "days") + 1
            : moment(d.data().date_end.toDate()).diff(moment(d.data().date_start.toDate()), "days")
        );
      })
      .reduce((a, b) => a + b, 0)
  );

  return (
    <Stack>
      <DatePickerInput
        label="Start Date"
        description={
          <Text>
            You can book you holiday from{" "}
            <Text span fw="bold">
              {companyData["holiday_review_days"] ?? 1} days
            </Text>{" "}
            in advance.
          </Text>
        }
        valueFormat="DD MMMM YYYY"
        dropdownType="modal"
        value={selectedStartDate}
        error={isDateAlreadyBooked ? "You have already have a booking. Please select another date." : null}
        disabled={isLoading}
        excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
        onChange={(date) => {
          setSelectedStartDate(date);
          setDateAlreadyBooked(
            allEvents.filter(
              (d) =>
                d.data().type === "holiday" &&
                moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment(date).format("DDMMYYYY") &&
                d.data().created_by === currentUser.uid
            ).length > 0
          );
        }}
        getDayProps={(date) => {
          if (
            allEvents.filter(
              (d) =>
                d.data().type === "holiday" &&
                moment(d.data().date_start.toDate()).format("DDMMYYYY") === moment(date).format("DDMMYYYY") &&
                d.data().created_by === currentUser.uid
            ).length > 0
          ) {
            return { disabled: true };
          }
          return {};
        }}
        minDate={moment()
          .startOf("day")
          .add(companyData["holiday_review_days"] ?? 1, "day")
          .toDate()}
        maxDate={moment().endOf("year").toDate()}
        popoverProps={{ zIndex: 1000000 }}
        required
      />

      <DatePickerInput
        label="End Date"
        description="Select end date if booking more than one day, otherwise, leave blank."
        placeholder="Select end date..."
        valueFormat="DD MMMM YYYY"
        dropdownType="modal"
        value={selectedEndDate}
        disabled={isLoading}
        excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
        onChange={(date) => setSelectedEndDate(date)}
        minDate={moment(selectedStartDate).startOf("day").add(1, "day").toDate()}
        maxDate={moment().endOf("year").toDate()}
        popoverProps={{ zIndex: 1000000 }}
        clearable
      />

      <MultiSelect
        label="Additional Reviewers"
        description="Include another employee to review your holiday"
        placeholder="Select employees..."
        data={employees
          // exclude employes already listed in all employees and those selected
          .filter((d) => !employeeApprovals.includes(d.uid) && d.uid !== currentUser.uid)
          .map((e) => {
            return {
              value: e.uid,
              label: e.displayName,
            };
          })}
        value={extraEmployees}
        searchable
        onChange={(value) => setExtraEmployees(value)}
      />

      <Text size="sm">
        Your holiday requests will be sent to{" "}
        {[...employeeApprovals, extraEmployees[0]].map((id) => {
          return employees
            .filter((em) => id === em.uid)
            .map((a) => {
              return (
                <Text key={a.uid} span fw="bold">
                  {a.displayName},{" "}
                </Text>
              );
            });
        })}
      </Text>

      <Text size="sm" align="right" color="dimmed">
        <Text span fw="bold">
          {((companyData["annual_leave"] ?? 28) +
            (companyData["employee_approvals_from"] ?? [])?.filter((e) => e?.id === currentUser.uid)[0]?.offset ?? 0) -
            totalBookedOffHoliday}{" "}
        </Text>
        days remaining for {moment(selectedStartDate).format("YYYY")}.
      </Text>
      <Text size="sm" align="right" mt={-16} color="cyan">
        <Text span fw="bold">
          {selectedEndDate
            ? moment(selectedEndDate).diff(moment(selectedStartDate), "days") + 2
            : moment(selectedStartDate).diff(moment(selectedStartDate), "days") + 1}{" "}
        </Text>{" "}
        days booked.
      </Text>
      <Text size="sm" align="right" mt={-16} color="orange">
        <Text span fw="bold">
          {((companyData["annual_leave"] ?? 28) +
            (companyData["employee_approvals_from"] ?? [])?.filter((e) => e?.id === currentUser.uid)[0]?.offset ?? 0) -
            totalBookedOffHoliday -
            (selectedEndDate
              ? moment(selectedEndDate).diff(moment(selectedStartDate), "days") + 2
              : moment(selectedStartDate).diff(moment(selectedStartDate), "days") + 1)}{" "}
        </Text>{" "}
        days would remain for {moment(selectedStartDate).format("YYYY")}.
      </Text>

      <Button
        size="lg"
        disabled={isDateAlreadyBooked}
        loading={isLoading}
        onClick={() => {
          setIsLoading(true);
          requestNewHoliday(
            userToken,
            companyData,
            companyId,
            currentUser.displayName,
            selectedStartDate,
            selectedEndDate,
            currentUser.uid,
            employees,
            extraEmployees
          ).then(() => onCompletion());
        }}
      >
        Request Holiday
      </Button>
    </Stack>
  );
}
