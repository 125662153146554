import React, { useState } from "react";
import {
  Title,
  Text,
  Stack,
  Flex,
  Paper,
  Button,
  Accordion,
  Badge,
  Alert,
  Blockquote,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCircleX } from "@tabler/icons";
import moment from "moment";
import { deleteEvent } from "../../../../services/Firestore";
import { ShowNotificationSuccess } from "../../../../components/Notifications";

export default function DrawItemDeclined({ companyId, currentUser, allHolidays, fetchEvents }) {
  const [isLoading, setIsLoading] = useState("");

  const allDeclinedItems = allHolidays
    .filter((d) => d.data().state === 0)
    .sort(function (a, b) {
      if (a.data().date_start.toDate() < b.data().date_start.toDate()) return -1;
      if (a.data().date_start.toDate() > b.data().date_start.toDate()) return 1;
      return 0;
    });

  return (
    <>
      {allDeclinedItems.length > 0 && (
        <Accordion.Item value="declined">
          <Accordion.Control>
            <Flex justify="space-between" align="center">
              <Stack spacing={0}>
                <Title order={4} weight={800} color="red">
                  Declined
                </Title>
                <Text size="sm" color="dimmed"></Text>
              </Stack>
              <Badge size="lg" color="red">
                {allDeclinedItems.length}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {allDeclinedItems.map((d) => {
              return (
                <Paper key={d.id} p="md" withBorder mb="md">
                  <Stack>
                    {moment(d.data().date_end.toDate()).format("DDMMYYYY") ===
                      moment(d.data().date_start.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("dddd, DD MMM yyyy")}
                      </Title>
                    )}
                    {moment(d.data().date_start.toDate()).format("DDMMYYYY") !==
                      moment(d.data().date_end.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("DD MMM yyyy")} -{" "}
                        {moment(d.data().date_end.toDate()).format("DD MMM yyyy")}
                      </Title>
                    )}

                    {d.data()?.declined_by?.message && (
                      <Alert title="Holiday Declined" icon={<IconCircleX />} color="red">
                        <Blockquote
                          color="red"
                          cite={`– ${d.data()?.declined_by?.name ?? "Unknown"}`}
                          icon={null}
                          ml={-20}
                          mb={-8}
                        >
                          {d.data()?.declined_by?.message}
                        </Blockquote>
                      </Alert>
                    )}

                    {d.data().created_by === currentUser?.uid && (
                      <Button
                        color="red"
                        loading={isLoading}
                        onClick={() => {
                          setIsLoading(true);
                          deleteEvent(companyId, d.id).then(() => {
                            fetchEvents();
                          });
                        }}
                      >
                        Remove
                      </Button>
                    )}

                    <Button
                      color="red"
                      onClick={() =>
                        modals.openConfirmModal({
                          title: <Text weight={700}>Remove Holiday</Text>,
                          centered: true,
                          closeOnClickOutside: false,
                          withCloseButton: false,
                          children: (
                            <Text>
                              You can try requesting the holiday again to the admins.
                            </Text>
                          ),
                          labels: { confirm: "Remove", cancel: "Dismiss" },
                          confirmProps: { color: "red" },
                          onConfirm: () =>
                            deleteEvent(companyId, d.id).then(() => {
                              modals.closeAll();
                              fetchEvents();
                              ShowNotificationSuccess("Holiday Removed");
                            }),
                        })
                      }
                    >
                      Remove Holiday
                    </Button>
                  </Stack>
                </Paper>
              );
            })}
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
}
