import React, { useState } from "react";
import {
  Title,
  Text,
  Stack,
  Flex,
  Paper,
  Loader,
  Button,
  Group,
  Accordion,
  Badge,
  Tooltip,
  Modal,
  Textarea,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCircleCheck } from "@tabler/icons";
import moment from "moment";
import { approveHoliday, remindHoliday, declineHoliday } from "../../../../services/Firestore";
import _ from "lodash";
import { ShowNotificationSuccess } from "../../../../components/Notifications";

export default function DrawItemRequests({
  userToken,
  companyId,
  companyData,
  currentUser,
  allHolidays,
  employeeApprovalsFrom,
  approvalsFrom,
  employees,
  fetchEvents,
}) {
  const [isLoading, setIsLoading] = useState("");

  const allPendingEvents = allHolidays
    .filter((d) => d.data().state === 2 && moment().isBefore(moment(d.data().date_start.toDate())))
    .filter((d) => approvalsFrom.includes(d.data().created_by))
    .sort(function (a, b) {
      if (a.data().created.toDate() < b.data().created.toDate()) return -1;
      if (a.data().created.toDate() > b.data().created.toDate()) return 1;
      return 0;
    });

  const [isModalDeclineOpen, setModalDeclineOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const [tempHolidayId, setTempHolidayId] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [tempCreatedBy, setTempCreatedBy] = useState("");

  return (
    <>
      <Modal
        opened={isModalDeclineOpen}
        centered
        onClose={() => setModalDeclineOpen(false)}
        title="Decline Holiday Request"
      >
        <>
          <Textarea
            label="Reason"
            value={declineReason}
            onChange={(event) => setDeclineReason(event.currentTarget.value)}
            required
            autosize
            minRows={1}
            maxRows={6}
          />
          <Flex align="end" justify="end">
            <Button
              color="red"
              disabled={declineReason.trim().length === 0}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                declineHoliday(
                  userToken,
                  companyData,
                  companyId,
                  tempHolidayId,
                  tempStartDate,
                  tempEndDate,
                  currentUser,
                  declineReason,
                  employees,
                  tempCreatedBy
                ).then(() => {
                  fetchEvents();
                  setModalDeclineOpen(false);
                });
              }}
              mt="md"
            >
              Decline Holiday
            </Button>
          </Flex>
        </>
      </Modal>

      {allPendingEvents.length > 0 && (
        <Accordion.Item value="respond">
          <Accordion.Control>
            <Flex justify="space-between" align="center">
              <Stack spacing={0}>
                <Title order={4} weight={800} color="orange">
                  Requests
                </Title>
                <Text size="sm" color="dimmed">
                  Approve or Decline holidays.
                </Text>
              </Stack>
              <Badge size="lg" color="orange">
                {allPendingEvents.length}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {allPendingEvents.map((d) => {
              return (
                <Paper key={d.id} p="md" withBorder mb="md">
                  <Stack>
                    <Title order={5} pb="md">{`${d.data().title} is requesting:`}</Title>
                    {moment(d.data().date_end.toDate()).format("DDMMYYYY") ===
                      moment(d.data().date_start.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("dddd, DD MMM yyyy")}
                      </Title>
                    )}
                    {moment(d.data().date_start.toDate()).format("DDMMYYYY") !==
                      moment(d.data().date_end.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("DD MMM yyyy")} -{" "}
                        {moment(d.data().date_end.toDate()).format("DD MMM yyyy")}
                      </Title>
                    )}
                    <Group position="center">
                      {[
                        ...(employeeApprovalsFrom.filter((e) => e.id === d.data().created_by)[0]?.admins ?? []),
                        (companyData["emails_cc"] ?? []).map((rEmail) => {
                          return employees.filter((emp) => emp.email === rEmail)[0]?.uid;
                        })[0],
                      ].map((admin) => {
                        return employees
                          .filter((em) => admin === em.uid)
                          .map((a) => {
                            return (
                              <Flex key={a.uid} align="center" justify="center" direction="column" gap={4}>
                                <Text fw="bold">{a.displayName}</Text>
                                {d.data().approved_by.includes(a.uid) && <IconCircleCheck color="green" />}
                                {!d.data().approved_by.includes(a.uid) && <Loader size="sm" color="orange" />}
                              </Flex>
                            );
                          });
                      })}
                    </Group>

                    {/* DATE */}
                    {d.data().approved_by.length === 0 && (
                      <Text size="sm" color="dimmed" align="center">
                        Requested on {moment(d.data().created.toDate()).format("ddd, DD MMM YYYY, HH:mm.")}
                      </Text>
                    )}
                    {d.data().approved_by.length > 0 && (
                      <Text size="sm" color="dimmed" align="center">
                        Last update on {moment(d.data().updated.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                      </Text>
                    )}

                    {!d.data().approved_by.includes(currentUser?.uid) && (
                      <Group grow>
                        <Button
                          color="red"
                          loading={isLoading}
                          onClick={() => {
                            setTempHolidayId(d.id);
                            setTempStartDate(d.data().date_start.toDate());
                            setTempEndDate(d.data().date_end.toDate());
                            setTempCreatedBy(d.data().created_by);
                            setModalDeclineOpen(true);
                          }}
                        >
                          Decine
                        </Button>
                        <Button
                          color="green"
                          loading={isLoading}
                          onClick={() => {
                            setIsLoading(true);
                            const newApprovedList = [...d.data().approved_by, currentUser.uid];
                            approveHoliday(
                              userToken,
                              companyData,
                              companyId,
                              d.id,
                              d.data().date_start.toDate(),
                              d.data().date_end.toDate(),
                              newApprovedList,
                              _.isEqual(newApprovedList, approvalsFrom),
                              employees,
                              currentUser.uid
                            ).then(() => fetchEvents());
                          }}
                        >
                          Approve
                        </Button>
                      </Group>
                    )}

                    <Tooltip
                      label={`You can send a reminder from ${moment(d.data().updated.toDate())
                        .add(1, "day")
                        .format("ddd DD MMM, HH:mm")}`}
                      withArrow
                      color="blue"
                      arrowSize={8}
                      position="bottom"
                      multiline
                      width={250}
                      disabled={moment().isAfter(moment(d.data().updated.toDate()).add(1, "day"))}
                    >
                      {moment().isBefore(moment(d.data().updated.toDate()).add(1, "day")) ? (
                        <Button
                          data-disabled
                          sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                          disabled={moment(d.data().updated.toDate()).isBefore(
                            moment(d.data().updated.toDate()).add(1, "day")
                          )}
                        >
                          Send Reminder to Admins
                        </Button>
                      ) : (
                        <Button
                          color="blue"
                          onClick={() => {
                            modals.openConfirmModal({
                              title: <Text weight={700}>Send Reminder</Text>,
                              centered: true,
                              closeOnClickOutside: false,
                              withCloseButton: false,
                              children: <Text>You can send a reminder to all assigned admins once every 24 hours.</Text>,
                              labels: { confirm: "Send Reminder", cancel: "Dismiss" },
                              confirmProps: { color: "blue" },
                              onConfirm: () =>
                                remindHoliday(
                                  userToken,
                                  companyData,
                                  companyId,
                                  d.id,
                                  d.data().title,
                                  d.data().date_start.toDate(),
                                  d.data().date_end.toDate(),
                                  employees,
                                  d.data().created_by
                                ).then(() => {
                                  fetchEvents();
                                  ShowNotificationSuccess("Reminder sent!");
                                }),
                            });
                          }}
                        >
                          Send Reminder to Admins
                        </Button>
                      )}
                    </Tooltip>
                  </Stack>
                </Paper>
              );
            })}
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
}
