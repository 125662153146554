import { Paper, Title, Space } from "@mantine/core";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";

export default function ModuleBrowserRelease() {
  return (
    <Paper shadow="sm" radius="md" p="sm" withBorder>
      <Title order={2} weight={800} lineClamp={1}>
        {osName} - {osVersion}
      </Title>
      <Space />
      <Title order={5} weight={300} color="dimmed">
        {browserName} - v{browserVersion}
      </Title>
    </Paper>
  );
}
