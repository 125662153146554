import React, { useEffect, useState } from "react";
import { createStyles, Skeleton, Progress, Text, SimpleGrid, rem, Stack, Title, Paper } from "@mantine/core";
import * as apiService from "../../../api-service";
import { format } from "date-fns";

export default function TestrailModuleRuns({ userToken, companyId, projectId, selectedRun, suiteId, runInfo }) {
  const [isLoading, setIsLoading] = useState(false);

  const [allRuns, setAllRuns] = useState([]);

  const [activeRun, setActiverun] = useState(null);

  const useStyles = createStyles((theme) => ({
    progressLabel: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      lineHeight: 1,
      fontSize: theme.fontSizes.sm,
    },

    stat: {
      borderBottom: `${rem(3)} solid`,
      paddingBottom: rem(5),
    },

    statCount: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      lineHeight: 1.3,
    },

    diff: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      display: "flex",
      alignItems: "center",
    },

    icon: {
      color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4],
    },
  }));
  const { classes } = useStyles();

  useEffect(() => {
    if (projectId) {
      setIsLoading(true);

      if (userToken) {
        apiService
          .getTestrailProjectRuns({
            userIdToken: userToken,
            companyId: companyId,
            projectId: projectId,
          })
          .then((data) => {
            setAllRuns(data);
            if (data[0]?.suite_id) {
              suiteId(data[0].suite_id);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      }
    } else {
      setAllRuns([]);
    }
  }, [companyId, suiteId, userToken, projectId]);

  const allTestrail = allRuns.map(
    ({
      id,
      name,
      passed_count,
      blocked_count,
      untested_count,
      retest_count,
      failed_count,
      custom_status3_count,
      updated_on,
    }) => (
      <Paper
        shadow="sm"
        radius="md"
        p="md"
        style={{
          backgroundColor: activeRun === id ? "#248BE6" : "white",
          cursor: "pointer",
        }}
        onClick={() => {
          setActiverun(id);
          selectedRun(id);
          runInfo(allRuns.filter((i) => i.id === id)[0]);
        }}
      >
        <Text fz="xl" fw={700} style={{ color: activeRun === id ? "white" : "#248BE6" }}>
          {name}
        </Text>
        <Text fz="sm" style={{ color: activeRun === id ? "white" : "#858E96" }}>
          {format(new Date(updated_on * 1000), "dd/MM/yyyy")} at {format(new Date(updated_on * 1000), "HH:mm")}
        </Text>
        <Progress
          sections={[
            {
              value:
                (passed_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "green",
              label: "Pass",
              tooltip: `Passed - ${passed_count}`,
            },
            {
              value:
                (untested_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "gray",
              label: "Untested",
              tooltip: `Untested - ${untested_count}`,
            },
            {
              value:
                (failed_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "red",
              label: "Failed",
              tooltip: `Failed - ${failed_count}`,
            },
            {
              value:
                (blocked_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "black",
              label: "Blocked",
              tooltip: `Blocked - ${blocked_count}`,
            },
            {
              value:
                (retest_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "yellow",
              label: "Retests",
              tooltip: `Retests - ${retest_count}`,
            },
            {
              value:
                (custom_status3_count /
                  (passed_count +
                    blocked_count +
                    untested_count +
                    retest_count +
                    failed_count +
                    custom_status3_count)) *
                100,
              color: "black",
              label: "N/A",
              tooltip: `N/A - ${custom_status3_count}`,
            },
          ]}
          size={34}
          classNames={{ label: classes.progressLabel }}
          mt={24}
        />
        <Text fz="sm" mt="md" style={{ color: activeRun === id ? "white" : "#858E96" }}>
          {passed_count} Passed, {blocked_count} Blocked, {untested_count} Untested, {retest_count} Retest,{" "}
          {failed_count} Failed and {custom_status3_count} N/A. Total:{" "}
          <Text span fw={500}>
            {passed_count + blocked_count + untested_count + retest_count + failed_count + custom_status3_count}
          </Text>
        </Text>
      </Paper>
    )
  );

  if (isLoading)
    return (
      <SimpleGrid cols={1}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => {
          return <Skeleton key={index} height={200} />;
        })}
      </SimpleGrid>
    );

  return (
    <>
      {allTestrail.length > 0 ? (
        <SimpleGrid cols={1}>{allTestrail}</SimpleGrid>
      ) : (
        <Stack mt={32}>
          <Title order={2} align="center">
            No Runs Available
          </Title>
          <Title order={5} color="gray" align="center">
            Please contact the company admin.
          </Title>
        </Stack>
      )}
    </>
  );
}
