import React, { useEffect, useState } from "react";
import { Tabs, Stack, Input, ScrollArea, Loader } from "@mantine/core";
import * as apiService from "../../../api-service";
import { IconCircleCheck, IconCircleX, IconCircleMinus } from "@tabler/icons";

export default function AutomationBuildModule({ userToken, companyId, projectId }) {
  const [isLoading, setIsLoading] = useState(true);

  const [builds, setBuilds] = useState([]);
  // const [buildLogs, setBuildLogs] = useState([]);
  // const [buildLog, setBuildLog] = useState('');

  // const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (userToken) {
      apiService
        .getAutomationBuilds({
          userIdToken: userToken,
          companyId: companyId,
          projectId: projectId,
        })
        .then((data) => {
          setBuilds(data);
          setIsLoading(false);
        })
        .catch((data) => {
          console.error(data);
          setIsLoading(false);
        });
    }
  }, [userToken, companyId, projectId]);

  const buildResultIcon = (result) => {
    if (result) {
      if (result === "canceled") {
        return <IconCircleMinus color="gray" size={20} />;
      } else if (result === "succeeded") {
        return <IconCircleCheck color="green" size={20} />;
      }
    }
    return <IconCircleX color="red" size={20} />;
  };

  const buildTabs = builds.map(({ id, buildNumber, status, result }) => (
    <Tabs.Tab
      key={`${id}`}
      value={`${buildNumber}`}
      icon={status === "inProgress" ? <Loader color="yellow" size="xs" /> : buildResultIcon(result)}
    >
      {buildNumber}
    </Tabs.Tab>
  ));

  const buildContent = builds.map(({ id, buildNumber, status, startTime, queueTime, finishTime }) => (
    <Tabs.Panel key={`${id}`} value={`${buildNumber}`} pl="xs">
      <Stack pl={16} pr={16}>
        <Input.Wrapper label="ID">
          <p>{id}</p>
        </Input.Wrapper>
        <Input.Wrapper label="Status">
          <p>{status}</p>
        </Input.Wrapper>
        <Input.Wrapper label="Start Time">
          <p>{startTime}</p>
        </Input.Wrapper>
        <Input.Wrapper label="Queue Time">
          <p>{queueTime}</p>
        </Input.Wrapper>
        <Input.Wrapper label="Finish Time">
          <p>{finishTime}</p>
        </Input.Wrapper>
      </Stack>
    </Tabs.Panel>
  ));

  return (
    <Stack p={16}>
      {!isLoading ? (
        builds.length > 0 ? (
          <Tabs variant="outline" orientation="vertical" defaultValue={builds[0].buildNumber}>
            <Tabs.List>
              <ScrollArea style={{ height: 600 }} type="always" offsetScrollbars>
                {buildTabs}
              </ScrollArea>
            </Tabs.List>
            {buildContent}
          </Tabs>
        ) : (
          <h2>No Builds Available</h2>
        )
      ) : (
        <h2>Loading</h2>
      )}
    </Stack>
  );
}
