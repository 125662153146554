import React, { useEffect, useState } from "react";
import { Accordion, Skeleton, SimpleGrid, Title, Box, Badge, Paper, Center, Stack, Text } from "@mantine/core";
import * as apiService from "../../../api-service";

const statusColor = (index) => {
  switch (index) {
    // Pass
    case 1:
      return "teal";
    // Blocked
    case 2:
      return "dark";
    // Retest
    case 4:
      return "yellow";
    // Failed
    case 5:
      return "red";
    // N/A
    case 8:
      return "black";
    // 3 - Untested
    default:
      return "gray";
  }
};

export default function TestrailRunTests({ userToken, companyId, projectId, runId, suiteId, runInfo }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTests, setIsLoadingTests] = useState(false);

  const [allTests, setAllTests] = useState([]);
  const [allSections, setAllSections] = useState([]);
  const [allStatuses, setStatuses] = useState([]);

  useEffect(() => {
    if (runId && suiteId) {
      setIsLoading(true);
      setIsLoadingTests(true);

      if (userToken) {
        // Get Sections
        apiService
          .getTestrailProjectRunTestSections({
            userIdToken: userToken,
            companyId: companyId,
            projectId: projectId,
            suiteId: suiteId,
          })
          .then((sections) => {
            setAllSections(sections);
            setIsLoading(false);
          });

        // Get Statuses
        apiService
          .getTestrailStatuses({ userIdToken: userToken, companyId: companyId })
          .then((data) => setStatuses(data));

        // Get Tests
        apiService
          .getTestrailProjectRunTests({
            userIdToken: userToken,
            companyId: companyId,
            runId: runId,
          })
          .then((data) => {
            setAllTests(data);
            setIsLoading(false);
            setIsLoadingTests(false);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      }
    } else {
      setAllSections([]);
    }
  }, [userToken, companyId, runId, projectId, suiteId]);

  function AccordionMain({ item }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }} ml={16}>
        {testBadge(item.status_id)}
        <Accordion.Control>{item.title}</Accordion.Control>
      </Box>
    );
  }
  function testBadge(id) {
    const status = allStatuses.filter((s) => s.id === id)[0];
    if (status) {
      return (
        <Badge variant="filled" color={statusColor(id)} w={106}>
          {status.label}
        </Badge>
      );
    }
  }

  if (isLoading)
    return (
      <SimpleGrid cols={1}>
        <Skeleton height={120} />
      </SimpleGrid>
    );

  const sectionHasTests = (display_order) => allTests.filter((r) => r.sections_display_order === display_order);

  return (
    <SimpleGrid cols={1}>
      {runInfo && (
        <Paper shadow="md" p="lg" withBorder>
          <Center>
            <Stack>
              <Title c={"teal"} align="center">
                {(
                  (runInfo.passed_count /
                    (runInfo.passed_count +
                      runInfo.untested_count +
                      runInfo.retest_count +
                      runInfo.failed_count +
                      runInfo.custom_status3_count)) *
                  100
                ).toFixed(0)}
                %
              </Title>
              <Text color="dimmed" align="center" mt={-16}>
                Passed
              </Text>
              <Text align="center">
                {runInfo.passed_count +
                  runInfo.untested_count +
                  runInfo.retest_count +
                  runInfo.failed_count +
                  runInfo.custom_status3_count -
                  (runInfo.passed_count +
                    runInfo.untested_count +
                    runInfo.retest_count +
                    runInfo.failed_count +
                    runInfo.custom_status3_count)}
                /
                {runInfo.passed_count +
                  runInfo.untested_count +
                  runInfo.retest_count +
                  runInfo.failed_count +
                  runInfo.custom_status3_count}{" "}
                untested
              </Text>
            </Stack>
          </Center>
        </Paper>
      )}
      {allSections?.map(({ name, display_order }) => {
        return (
          <div key={display_order}>
            <Title order={3} color={sectionHasTests(display_order).length > 0 ? "black" : "gray"}>
              {name}
            </Title>
            {isLoadingTests ? (
              <SimpleGrid cols={1} pt={8}>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </SimpleGrid>
            ) : (
              sectionHasTests(display_order).length > 0 && (
                <Paper mt={8}>
                  <Accordion variant="contained" radius="md">
                    {sectionHasTests(display_order).map((i) => (
                      <Accordion.Item key={i.id} value={i.title}>
                        <AccordionMain item={i} />
                        {i.custom_steps && <Accordion.Panel>{i.custom_steps}</Accordion.Panel>}
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Paper>
              )
            )}
          </div>
        );
      })}
    </SimpleGrid>
  );
}
