import React, { useEffect, useState } from "react";
import { Container, Paper, Center, Loader, Title, Stack, Text, PasswordInput, Button } from "@mantine/core";
import * as apiService from "../api-service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconCircleCheck, IconCircleX } from "@tabler/icons";
import { ShowNotificationError, ShowNotificationSuccess } from "../components/Notifications";

export default function Action() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [result, setResult] = useState(null);

  const [searchParams] = useSearchParams();
  const [mode] = useState(searchParams.get("mode"));
  const [code] = useState(searchParams.get("oobCode"));

  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  useEffect(() => {
    document.title = "Verifying - Cell Software Portal";
  }, []);

  useEffect(() => {
    if (mode && code) {
      if (mode === "verifyEmail") {
        setIsLoading(true);
        apiService
          .verifyEmail({
            code: code,
          })
          .then((data) => {
            if (data.error) {
              setResult(data.error);
            } else if (data.response) {
              setResult(data.response);
            } else {
              setResult(data);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setResult(e);
            setIsLoading(false);
          });
      }
    } else {
      // code nnot found
      setResult("code-not-found");
      setIsLoading(false);
    }
  }, [searchParams, mode, code]);

  if (mode === "resetPassword") {
    return (
      <Container size={420} my={40}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <>
              <Title pb={16}>Reset Password</Title>
              <PasswordInput
                label="New Password"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.currentTarget.value)}
                placeholder="New password"
                required
                mt="md"
              />
              <PasswordInput
                label="Repeat New Password"
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.currentTarget.value)}
                placeholder="Repeast password"
                required
                mt="md"
              />
              <Button
                fullWidth
                mt="xl"
                disabled={newPassword1.length === 0 && newPassword2.length === 0}
                onClick={() => {
                  if (newPassword1.length > 0 && newPassword2.length > 0 && newPassword1 === newPassword2) {
                    setIsLoading(true);
                    apiService
                      .resetPassword({
                        code: code,
                        password: newPassword2,
                      })
                      .then(() =>
                        ShowNotificationSuccess("Password Changed", "Redirecting back to login...", () => navigate("/"))
                      )
                      .catch((e) => {
                        ShowNotificationError("An Error Occoured", e);
                        setIsLoading(false);
                      });
                  } else {
                    // not match
                    ShowNotificationError("Passwords do not match");
                  }
                }}
              >
                Reset Password
              </Button>
            </>
          )}
        </Paper>
      </Container>
    );
  } else if (mode === "verifyEmail") {
    return (
      <Container style={{ paddingTop: 18 }}>
        <Paper shadow="md" p="lg" withBorder>
          {isLoading ? (
            <Center>
              <Stack align={"center"}>
                <Loader />
                <Title>Verifying...</Title>
              </Stack>
            </Center>
          ) : result === "success" ? (
            // success
            <Center>
              <Stack align={"center"}>
                <IconCircleCheck size={64} color="teal" />
                <Title>Success</Title>
                <Text>Email has been verifed.</Text>
              </Stack>
            </Center>
          ) : (
            // Error
            <Center>
              <Stack align={"center"}>
                <IconCircleX size={64} color="red" />
                <Title>
                  {result === "success" ? "Success" : result === "invalid-action-code" ? "Code Expired" : "Error"}
                </Title>
                <Text>
                  {result === "success" ? "" : result === "invalid-action-code" ? "Please request another." : result}
                </Text>
              </Stack>
            </Center>
          )}
        </Paper>
      </Container>
    );
  } else {
    // nothing detected
  }
}
