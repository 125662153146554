import React, { useEffect, useState } from "react";
import { auth } from "../firebase-config";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Button, Paper, Container, Stack, TextInput, PasswordInput, LoadingOverlay, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons";

function Login() {
  useEffect(() => {
    document.title = "Cell Software Portal - Login";
  });

  const [formLoaderIsVisible, setFormLoaderIsVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const form = useForm({
    initialValues: { email: "", password: "" },

    // functions will be used to validate values at corresponding key
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
      password: (value) => (/^\S+$/.test(value) ? null : "Password is empty"),
    },
  });

  const signInWithPassword = (email, password) => {
    setFormLoaderIsVisible(true);
    form.clearErrors();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        if (userCredential.user != null) {
          window.location.reload(false);
        } else {
          alert("Error signing in");
        }
      })
      .catch((error) => {
        setFormLoaderIsVisible(false);

        if (error.message.includes("invalid-email") || error.message.includes("missing-email")) {
          form.setFieldError("email", "Invalid email");
        } else if (error.message.includes("user-not-found")) {
          form.setFieldError("email", "The user does not exist. Please contact the business admin.");
        } else if (error.message.includes("wrong-password")) {
          form.setFieldError("password", "Incorrect password.");
        } else if (error.message.includes("too-many-requests")) {
          form.reset();
          setLoginError(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
          );
        } else {
          alert(error.message);
        }
      });
  };

  return (
    <Container size={420} my={40}>
      <Title align="center">Welcome back!</Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack spacing={16}>
          <LoadingOverlay visible={formLoaderIsVisible} overlayBlur={2} />
          <form
            onSubmit={form.onSubmit((values) => {
              signInWithPassword(values.email, values.password);
            })}
          >
            <Stack spacing={0}>
              <TextInput mt="sm" label="Email" placeholder="example@domain.com" {...form.getInputProps("email")} />
              <PasswordInput
                label="Password"
                placeholder="Password"
                {...form.getInputProps("password")}
                icon={<IconLock size={16} />}
              />
              {/* Login error text */}
              {loginError && <p style={{ color: "red" }}>{loginError}</p>}
              <Button mt="xl" type="submit" size="md" onClick={() => setLoginError(null)}>
                Login
              </Button>
              <Button
                variant="subtle"
                mt={16}
                size="sm"
                onClick={() => {
                  sendPasswordResetEmail(auth, form.getInputProps("email").value)
                    .then(() => {
                      // Password reset email sent!
                      alert("Password reset email sent. Please check your spam folder.");
                    })
                    .catch((error) => {
                      if (error.message.includes("invalid-email") || error.message.includes("missing-email")) {
                        form.setFieldError("email", "Enter a valid email to send a reset email to.");
                      } else if (error.message.includes("user-not-found")) {
                        form.setFieldError("email", "The user does not exist. Please contact the business admin.");
                      } else if (error.message.includes("too-many-requests")) {
                        form.reset();
                        setLoginError(
                          "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
                        );
                      } else {
                        alert(error.message);
                      }
                    });
                }}
              >
                Forgot Password
              </Button>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
}

export default Login;
