import React from "react";
import { Title, Text, Stack, Flex, Paper, Loader, Button, Group, Accordion, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCircleCheck, IconCircleMinus } from "@tabler/icons";
import moment from "moment";
import { deleteEvent } from "../../../../services/Firestore";
import { ShowNotificationSuccess } from "../../../../components/Notifications";

export default function DrawItemApproved({ companyId, currentUser, allHolidays, employees, fetchEvents }) {
  const allApprovedItems = allHolidays
    .filter((d) => d.data().state === 1 && moment().isBefore(moment(d.data().date_start.toDate())))
    .sort(function (a, b) {
      if (a.data().date_start.toDate() < b.data().date_start.toDate()) return -1;
      if (a.data().date_start.toDate() > b.data().date_start.toDate()) return 1;
      return 0;
    });

  return (
    <>
      {allApprovedItems.length > 0 && (
        <Accordion.Item value="approved">
          <Accordion.Control>
            <Flex justify="space-between" align="center">
              <Stack spacing={0}>
                <Title order={4} weight={800} color="teal">
                  Approved
                </Title>
                <Text size="sm" color="dimmed"></Text>
              </Stack>
              <Badge size="lg" color="teal">
                {allApprovedItems.length}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {allApprovedItems.map((d) => {
              return (
                <Paper key={d.id} p="md" withBorder mb="md">
                  <Stack>
                    {moment(d.data().date_end.toDate()).format("DDMMYYYY") ===
                      moment(d.data().date_start.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("dddd, DD MMM yyyy")}
                      </Title>
                    )}
                    {moment(d.data().date_start.toDate()).format("DDMMYYYY") !==
                      moment(d.data().date_end.toDate()).format("DDMMYYYY") && (
                      <Title order={2} weight={200} align="center">
                        {moment(d.data().date_start.toDate()).format("DD MMM yyyy")} -{" "}
                        {moment(d.data().date_end.toDate()).format("DD MMM yyyy")}
                      </Title>
                    )}

                    <Group position="center">
                      {d.data().approved_by.map((admin) => {
                        return employees
                          .filter((em) => admin === em?.uid)
                          .map((a) => {
                            return (
                              <Flex key={a.uid} align="center" justify="center" direction="column" gap={4}>
                                <Text fw="bold">{a.displayName}</Text>
                                {d.data().approved_by.includes(a.uid) && <IconCircleCheck color="green" />}
                                {!d.data().approved_by.includes(a.uid) &&
                                  (moment(d.data().date_start.toDate()).format("DDMMYYYY") >=
                                  moment().format("DDMMYYYY") ? (
                                    <IconCircleMinus color="grey" />
                                  ) : (
                                    <Loader size="sm" color="orange" />
                                  ))}
                              </Flex>
                            );
                          });
                      })}
                    </Group>

                    {d.data()?.state === 1 && (
                      <Text size="sm" color="dimmed" align="center">
                        Approved on {moment(d.data().updated.toDate()).format("ddd, DD MMM YYYY - HH:mm")}
                      </Text>
                    )}
                    {moment().isBefore(moment(d.data().date_start.toDate())) &&
                      d.data().created_by === currentUser?.uid && (
                        <Button
                          color="red"
                          onClick={() =>
                            modals.openConfirmModal({
                              title: <Text weight={700}>Cancel Approved Holiday</Text>,
                              centered: true,
                              closeOnClickOutside: false,
                              withCloseButton: false,
                              children: (
                                <>
                                  <Text>
                                    You can book these dates again, however, will need to be approved by all admins.
                                  </Text>
                                  <Text align="right" size="xs" pt="md" color="dimmed">
                                    Admins will be notified this has been cancelled.
                                  </Text>
                                </>
                              ),
                              labels: { confirm: "Cancel Holiday", cancel: "Keep Holiday" },
                              confirmProps: { color: "red" },
                              onConfirm: () =>
                                deleteEvent(companyId, d.id).then(() => {
                                  modals.closeAll();
                                  fetchEvents();
                                  ShowNotificationSuccess(
                                    "Holiday Deleted",
                                    "An email has been sent to to notify this has been deleted."
                                  );
                                }),
                            })
                          }
                        >
                          Cancel Holiday
                        </Button>
                      )}
                  </Stack>
                </Paper>
              );
            })}
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
}
