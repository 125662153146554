import React, { useState } from "react";
import { Button, Stack, Select } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { addNewParking } from "../../../services/Firestore";
import moment from "moment";

export default function BookerCreateParking({
  companyId,
  parkingSpace,
  onCompletion,
  userClaims,
  selectedDate,
  currentUser,
  allEvents,
  employees,
  selectedWeek,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [dateTimeStart, setDateTimeStart] = useState(moment().startOf("day").add(9, "hour").toDate());
  const [dateTimeEnd, setDateTimeEnd] = useState(moment().startOf("day").add(17, "hour").toDate());
  const [selectedParking, setSelectedParking] = useState(parkingSpace[0].id);
  const [assignedTo, setAssignedTo] = useState({ name: currentUser.displayName, id: currentUser.uid });
  const [newEmployees, setNewEmployees] = useState(employees);

  const isSpaceAlreadyBookedStart =
    allEvents
      .filter((d) => d.data().type === "parking")
      .filter(
        (d) =>
          moment(dateTimeStart).isBetween(d.data().date_start.toDate(), d.data().date_end.toDate()) ||
          moment(d.data().date_start.toDate()).isBetween(dateTimeStart, dateTimeEnd)
      )
      .filter((d) => d.data().space === selectedParking).length > 0;
  const isSpaceAlreadyBookedEnd =
    allEvents
      .filter((d) => d.data().type === "parking")
      .filter(
        (d) =>
          moment(dateTimeEnd).isBetween(d.data().date_start.toDate(), d.data().date_end.toDate()) ||
          moment(d.data().date_end.toDate()).isBetween(dateTimeStart, dateTimeEnd)
      )
      .filter((d) => d.data().space === selectedParking).length > 0;

  return (
    <Stack>
        <DateTimePicker
          label="Arriving"
          placeholder="Pick date and time"
          valueFormat="DD MMM YYYY HH:mm"
          dropdownType="modal"
          error={
            isSpaceAlreadyBookedStart
              ? "Someone is already parked here then. Try selecting another arrival date/time."
              : null
          }
          disabled={isLoading}
          value={dateTimeStart}
          onChange={(d) => {
            if (moment(dateTimeEnd).isBefore(moment(d))) {
              // is end date is before new selected date
              const hrs = moment(dateTimeEnd).hours();
              const mins = moment(dateTimeEnd).minutes();
              const newDate = moment(d).startOf("day");
              newDate.add(hrs, "hour");
              newDate.add(mins, "minute");
              setDateTimeEnd(newDate);
            }
            setDateTimeStart(d);
          }}
          minDate={moment(selectedDate).toDate()}
          maxDate={moment().add(2, "month").toDate()}
          popoverProps={{ zIndex: 1000000 }}
        />
        <DateTimePicker
          label="Leaving"
          placeholder="Pick date and time"
          error={
            isSpaceAlreadyBookedEnd
              ? "Someone is already parked here then. Try selecting another leaving date/time."
              : null
          }
          valueFormat="DD MMM YYYY HH:mm"
          dropdownType="modal"
          disabled={isLoading}
          value={dateTimeEnd}
          // onChange={(d) => setDateTimeEnd(d)}
          onChange={(d) => {
            if (moment(moment(d)).isBefore(dateTimeStart)) {
              // is end date is before new selected date
              const hrs = moment(dateTimeStart).hours();
              const mins = moment(dateTimeStart).minutes();
              const newDate = moment(d).startOf("day");
              newDate.add(hrs, "hour");
              newDate.add(mins, "minute");
              setDateTimeStart(newDate);
            }
            setDateTimeEnd(d);
          }}
          minDate={moment(dateTimeStart).toDate()}
          maxDate={moment().add(2, "month").toDate()}
          popoverProps={{ zIndex: 1000000 }}
        />
      <Select
        label="Parking Spot"
        value={selectedParking}
        error={isSpaceAlreadyBookedStart ? "Someone is already parked here. Try selecting another spot." : null}
        placeholder="Select a parking spot..."
        data={parkingSpace.map((s) => {
          return { value: s.id, label: s.title };
        })}
        // error={isRoomFull ? "This room is full. Please select another room." : null}
        disabled={isLoading}
        onChange={(i) => {
          setSelectedParking(i);
          // setRoomFull(dayRoomsBooked.filter((r) => r === i).length >= rooms.filter((r) => r.id === i)[0].max_bookings);
        }}
        maxDropdownHeight={100}
      />
      {(userClaims["access"] === "admin" || userClaims["access"] === "super") && (
        <Select
          label="Assigned To Employee/Client"
          description="If you are booking for yourself, no need to change this."
          value={assignedTo.id}
          maxDropdownHeight={150}
          searchable
          creatable
          onCreate={(query) => {
            const item = { label: query, value: query };
            setNewEmployees((current) => [...current, item]);
            return item;
          }}
          getCreateLabel={(query) => `+ Add ${query}`}
          disabled={isLoading}
          data={newEmployees.map((r) => {
            return {
              label: r.displayName ?? r.label,
              value: r.uid ?? r.value,
            };
          })}
          onChange={(id) =>
            setAssignedTo({ name: newEmployees.filter((e) => e.uid === id)[0]?.displayName ?? id, id: id })
          }
        />
      )}
      <Button
        mt={8}
        disabled={!selectedParking || isSpaceAlreadyBookedStart || isSpaceAlreadyBookedEnd}
        loading={isLoading}
        size="lg"
        onClick={() => {
          setIsLoading(true);
          addNewParking(
            companyId,
            assignedTo.name,
            moment(dateTimeStart).toDate(),
            moment(dateTimeEnd ?? dateTimeStart).toDate(),
            currentUser.uid,
            selectedParking,
            currentUser.uid !== assignedTo.id ? currentUser.displayName : ""
          ).then(() => onCompletion());
        }}
      >
        Book Space
      </Button>
    </Stack>
  );
}
