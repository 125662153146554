import React, { useState, useEffect } from "react";
import { Button, Stack, Input, MultiSelect } from "@mantine/core";

export default function SMSAdd({ sims, completed, editItem }) {
  const [fieldName, setFieldName] = useState("");
  const [fieldNumber, setFieldNumber] = useState("");
  const [groupError, setGroupError] = useState("");
  let numberError = "The number already exists.";

  const [groupData, setGroupData] = useState(sims.map((i) => i.group));
  const [fieldGroup, setFieldGroup] = useState("");

  useEffect(() => {
    if (editItem) {
      setFieldName(editItem["label"]);
      setFieldNumber(editItem["value"]);
      if (editItem["group"].length > 0) {
        setFieldGroup([editItem["group"]]);
      }
    }
  }, [editItem]);

  function addNewNumber() {
    completed({
      description: fieldNumber,
      group: fieldGroup[0] ?? "",
      label: fieldName,
      value: fieldNumber,
    });
  }

  function capitaliseFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function capitaliseWords(text) {
    return text
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  return (
    <Stack>
      <Input.Wrapper label="Name" required>
        <Input
          placeholder={""}
          value={fieldName}
          onChange={(e) => setFieldName(capitaliseFirst(e.currentTarget.value))}
          autoComplete="no"
          radius="lg"
          mt={8}
        />
      </Input.Wrapper>

      <Input.Wrapper
        label="Phone Number"
        description="Replace '+' with '00'. Example: '+44' should be '0044'."
        required
        error={groupError}
      >
        <Input
          placeholder={"00447123456789"}
          value={fieldNumber}
          disabled={editItem}
          onChange={(e) => {
            setFieldNumber(e.currentTarget.value.trim());
            const numbers = sims.map((i) => i.value);
            setGroupError(numbers.includes(e.currentTarget.value.trim()) ? numberError : "");
          }}
          onBlur={(e) => {
            const numbers = sims.map((i) => i.value);
            setGroupError(numbers.includes(e.target.value) ? numberError : "");
          }}
          autoComplete="no"
          error={groupError}
          type={"number"}
          radius="lg"
          mt={8}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Associated Group" description="Group multiple numbers together">
        <MultiSelect
          mt={8}
          data={groupData}
          placeholder="Search or type a new group"
          maxSelectedValues={1}
          searchable
          creatable
          clearable
          value={fieldGroup}
          getCreateLabel={(query) => `+ Create '${capitaliseWords(query)}' group`}
          onChange={setFieldGroup}
          onBlur={() => setGroupData(sims.map((i) => i.group))}
          onCreate={(query) => {
            setGroupData((current) => [...current, capitaliseWords(query)]);
            return capitaliseWords(query);
          }}
        />
      </Input.Wrapper>

      <Button
        mt={16}
        disabled={fieldNumber.length === 0 || fieldName.length === 0 || groupError !== ""}
        onClick={() => addNewNumber()}
      >
        {editItem ? "Update Number" : "Add Number"}
      </Button>
    </Stack>
  );
}
