import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Grid,
  SimpleGrid,
  Group,
  rem,
  useMantineTheme,
  Text,
  Title,
  Card,
  Tooltip,
  Button,
  ColorPicker,
  SegmentedControl,
  Flex,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { ReactComponent as Template } from "../../images/durian_artwork_template.svg";
import { ReactComponent as TemplateEmpty } from "../../images/durian_artwork_template_empty.svg";
import { ReactComponent as TemplateNamed } from "../../images/durian_artwork_template_names.svg";
import { IconCreditCard, IconUpload } from "@tabler/icons";
import ConvertApi from "convertapi-js";

export default function CardArtworkVerification({ companyData }) {
  const theme = useMantineTheme();

  const openRef = useRef(null);
  const openPDFRef = useRef(null);

  useEffect(() => {
    document.title = "Card Artwork Verification - Cell Software Portal";
  },[]);

  const [files, setFiles] = useState([]);
  const [bgColor, setBgColor] = useState("#000000");
  const [templayeStyle, setTemplateStyle] = useState("default");
  let dropzoneAccepts = ["image/png"];
  let pdfLimit = 1;
  const [isPDFLoading, setPDFLoading] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      // go through all files
      files.forEach((f, index) => {
        checkImageSize(f, (valid, width, height) => {
          setFileDim((prevValue) => [
            ...prevValue,
            { index: index, valid: valid, size: `${width ?? 0} x ${height ?? 0}` },
          ]);
        });
      });
    } else {
      setFileDim([]);
    }
  }, [files]);

  const [fileDim, setFileDim] = useState([]);

  const previews = files.map((file, index) => {
    return (
      <Card key={index} shadow="xs" padding="lg" radius="0px">
        <Card.Section pb="xs">
          {templayeStyle === "named" ? (
            <TemplateNamed
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          ) : templayeStyle === "none" ? (
            <TemplateEmpty
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          ) : (
            <Template
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          )}
        </Card.Section>
        <Flex>
          <Text>{file.name}</Text>
        </Flex>
        <Grid>
          <Grid.Col span={6}>
            <Text color="blue">Dimensions</Text>
            {fileDim.map((d) => {
              if (d.index === index) {
                return (
                  <Tooltip.Floating
                    key={index}
                    label="The dimensions of the card MUST be 1536px x 969px."
                    color="red"
                    disabled={d?.valid ?? false}
                  >
                    <Title order={3} color={d?.valid ? "green" : "red"}>
                      {d?.size}
                    </Title>
                  </Tooltip.Floating>
                );
              } else {
                return null;
              }
            })}
          </Grid.Col>
          <Grid.Col span={6}>
            <Text color="blue">Size</Text>
            <Tooltip.Floating
              label="The size of the card MUST be 4MB or below."
              color="red"
              disabled={file.size <= 4000000}
            >
              <Title order={3} color={file.size <= 4000000 ? "green" : "red"}>
                {formatBytes(file.size)}
              </Title>
            </Tooltip.Floating>
          </Grid.Col>
        </Grid>
      </Card>
    );
  });

  //   function for getting image size
  function checkImageSize(file, completion) {
    var fr = new FileReader();
    fr.onload = function () {
      // File is loaded
      var img = new Image();
      img.onload = function () {
        // The image is loaded; sizes are available
        if (img.width === 1536 && img.height === 969) {
          completion(true, img.width, img.height);
        } else {
          completion(false, img.width, img.height);
        }
      };
      img.src = fr.result; // Is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(file);
  }

  // Format size
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <Paper p="lg" shadow="xs" withBorder>
            <Text>
              Use this tool to determine whether an artwork passes or fails against Apple's Guidelines.{" "}
              <b>This tool only supports PNG.</b>
              <br />
              Each card art must adhere to the following requirements:
              <ul>
                <li>be either vector PDF or a PNG file</li>
                <li>size no larger than 4MB</li>
                <li>have a resolution of 1536 × 969</li>
                <li>have squared (not rounded) corners</li>
                <li>
                  exclude elements that are relevant only for physical cards (which include the card number, embossed
                  characters, hologram, chip contacts)
                </li>
                <li>
                  the Bank, Co-brand & Network logos to be <u>inside</u> the red areas
                </li>
                <li>
                  must be in landscape orientation; if the physical card has a portrait orientation, it must be modified
                  for presentation in landscape orientation
                </li>
                <li>contactless indicator may be added (to help indicate where Apple Pay can be used)</li>
              </ul>
            </Text>
            {companyData["convert-api"] !== null && (
              <Button mt={16} loading={isPDFLoading} onClick={() => openPDFRef.current()}>
                Convert PDF to PNG
              </Button>
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Paper p="lg" shadow="xs" withBorder>
            <Grid>
              <Grid.Col span={6}>
                <Title color="blue" order={4} pb={8}>
                  Template Style
                </Title>
                <SegmentedControl
                  value={templayeStyle}
                  onChange={setTemplateStyle}
                  disabled={files.length === 0}
                  fullWidth
                  data={[
                    { label: "Default", value: "default" },
                    { label: "Names", value: "named" },
                    { label: "None", value: "none" },
                  ]}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <Title color="blue" order={4} pb={8}>
                  Background Colour
                </Title>
                <ColorPicker
                  swatchesPerRow={7}
                  format="hex"
                  onChange={setBgColor}
                  value={bgColor}
                  fullWidth
                  swatches={[
                    "#25262b",
                    "#868e96",
                    "#fa5252",
                    "#e64980",
                    "#be4bdb",
                    "#7950f2",
                    "#4c6ef5",
                    "#228be6",
                    "#15aabf",
                    "#12b886",
                    "#40c057",
                    "#82c91e",
                    "#fab005",
                    "#fd7e14",
                  ]}
                />
              </Grid.Col>
            </Grid>
          </Paper>
          {files.length > 0 && (
            <Button mt={16} disabled={isPDFLoading} onClick={() => openRef.current()}>
              Select more files...
            </Button>
          )}
        </Grid.Col>
      </Grid>
      <Dropzone
        accept={["application/pdf"]}
        hidden
        maxFiles={pdfLimit}
        onDrop={(dropfiles) => {
          // setFileDim([]);
          // setFiles([]);
          setPDFLoading(true);

          dropfiles.forEach((f) => {
            let convertApi = ConvertApi.auth(companyData["convert-api"]);
            let params = convertApi.createParams();
            params.add("File", f);
            params.add("PageRange", "1");
            params.add("ImageHeight", "969");
            params.add("ImageWidth", "1536");
            params.add("ImageWidth", "1536");
            convertApi.convert("pdf", "png", params).then((i, index) => {
              const img = i.dto.Files[0];

              const link = document.createElement("a");
              link.href = img.Url;
              link.setAttribute("download", img.FileName);
              // Append to html link element page
              document.body.appendChild(link);
              // Start download
              link.click();
              // Clean up and remove the link
              link.parentNode.removeChild(link);
              setPDFLoading(false);
            });
          });
        }}
        openRef={openPDFRef}
      ></Dropzone>
      <Dropzone.FullScreen
        accept={dropzoneAccepts}
        loading={isPDFLoading}
        onDrop={(files) => {
          setFileDim([]);
          setFiles(files);
        }}
      >
        <Text align="center">
          <Title>Drop Card Artworks Here</Title>
        </Text>
        <Text size="sm" color="dimmed" inline mt={7} align="center">
          Drop-in as many as you like. When there are cards already listed, you can still drop more onto this page.
        </Text>
      </Dropzone.FullScreen>
      {files.length > 0 ? (
        <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]} mt={previews.length > 0 ? "xl" : 0}>
          {previews}
        </SimpleGrid>
      ) : (
        <Dropzone
          mt={16}
          accept={dropzoneAccepts}
          loading={isPDFLoading}
          onDrop={(files) => {
            setFileDim([]);
            setFiles(files);
          }}
          openRef={openRef}
        >
          <Group position="center" spacing="xl" style={{ minHeight: rem(344) }}>
            <Dropzone.Accept>
              <IconUpload
                size="3.2rem"
                stroke={1.5}
                color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
              />
            </Dropzone.Accept>
            <Dropzone.Idle>
              <IconCreditCard size="3.2rem" stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Drag & Drop card artworks or click to Browse...
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Drop-in as many as you like. When there are cards already listed, you can still drop more onto this
                page.
              </Text>
            </div>
          </Group>
        </Dropzone>
      )}
    </>
  );
}
