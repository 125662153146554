import { updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import React, { useState } from "react";
import { Button, Input, Tooltip, Flex, Stack, PasswordInput, Tabs } from "@mantine/core";
import { IconAlertCircle, IconLock } from "@tabler/icons";
import { ShowNotificationError, ShowNotificationSuccess } from "../../components/Notifications";

function Profile({ currentUser }) {
  const [newName, setNewName] = useState(currentUser?.displayName);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [isPasswordVisible, setPasswordToggle] = useState(false);

  const setDisplayName = (newName) => {
    updateProfile(currentUser, {
      displayName: newName,
    })
      .then(() => {
        // window.location.reload(false);
        setNewPassword(null);
        setCurrentPassword(null);
        setNewPasswordRepeat(null);
      })
      .catch((error) => {
        ShowNotificationError("Error", error);
      });
  };

  return (
    <Tabs defaultValue="details" variant="outline" radius="md" orientation="vertical">
      <Tabs.List>
        <Tabs.Tab value="details" icon={<IconAlertCircle size={14} />}>
          Details
        </Tabs.Tab>
        <Tabs.Tab value="security" icon={<IconLock size={14} />}>
          Security
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="details">
        <Stack p={16}>
          <Input.Wrapper label="Display Name" required>
            <Input
              placeholder={currentUser?.displayName}
              onChange={(e) => {
                if (e.target.value === "") {
                  setNewName(currentUser?.displayName);
                } else {
                  setNewName(e.target.value);
                }
              }}
              autoComplete="given-name"
              radius="lg"
              m={8}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Email" required>
            <Input
              placeholder={currentUser?.email}
              disabled={true}
              radius="lg"
              m={8}
              rightSection={
                <Tooltip label="Unable to be edited" position="top-end" withArrow>
                  <div>
                    <IconAlertCircle size={18} style={{ display: "block", opacity: 0.5 }} />
                  </div>
                </Tooltip>
              }
            />
          </Input.Wrapper>
          <Flex justify="center">
            <Button disabled={newName === currentUser?.displayName} onClick={() => setDisplayName(newName)}>
              Update Details
            </Button>
          </Flex>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="security">
        <Stack p={16}>
          <Input.Wrapper label="Current Password" required>
            <PasswordInput onChange={(e) => setCurrentPassword(e.target.value)} radius="lg" m={8} />
          </Input.Wrapper>
          <Input.Wrapper label="New Password" required>
            <PasswordInput
              visible={isPasswordVisible}
              onChange={(e) => setNewPassword(e.target.value)}
              onVisibilityChange={(e) => setPasswordToggle(!isPasswordVisible)}
              autoComplete="new-password"
              radius="lg"
              m={8}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Confirm New Password" required>
            <PasswordInput
              visible={isPasswordVisible}
              onChange={(e) => setNewPasswordRepeat(e.target.value)}
              onVisibilityChange={(e) => setPasswordToggle(!isPasswordVisible)}
              autoComplete="new-password"
              radius="lg"
              m={8}
            />
          </Input.Wrapper>
          <Flex justify="center">
            <Button
              onClick={() => {
                if (
                  currentPassword.length &&
                  newPassword.length &&
                  newPassword.length &&
                  newPassword === newPasswordRepeat
                ) {
                  const cred = EmailAuthProvider.credential(currentUser.email, currentPassword);
                  reauthenticateWithCredential(currentUser, cred)
                    .then((credential) =>
                      updatePassword(credential.user, newPassword)
                        .then(() => {
                          setNewPassword("");
                          setCurrentPassword("");
                          setNewPasswordRepeat("");
                          ShowNotificationSuccess("Password Updated");
                        })
                        .catch((error) => ShowNotificationError("Error", error.message))
                    )
                    .catch((error) => ShowNotificationError("Error", error));
                } else {
                  ShowNotificationError("Passwords do not match");
                }
              }}
            >
              Update Password
            </Button>
          </Flex>
        </Stack>
      </Tabs.Panel>
    </Tabs>
  );
}

export default Profile;
