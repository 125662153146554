import React, { useState } from "react";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase-config";
import { Button, Flex, Stack, Table, TextInput, Title } from "@mantine/core";
import _ from "lodash";
import { ShowNotificationSuccess } from "../../../components/Notifications";
import isEmail from "is-email";

export default function BusinessEditSendGrid({ companyData, companyId }) {
  const [isLoading, setLoading] = useState(false);

  const [fromDetails, setFromDetails] = useState(companyData["sendgrid"]["from"]);
  const [accessKey, setAccessKey] = useState(companyData["sendgrid"]["key"]);
  const [templateIds, setTemplateIds] = useState(companyData["sendgrid"]["template_ids"] ?? []);

  const SendGridTemplateIds = {
    HolidayApproved: "holiday_approved",
    HolidayCanceled: "holiday_canceled",
    HolidayDeclined: "holiday_declined",
    HolidayRemind: "holiday_remind",
    HolidayRequest: "holiday_request",
  };

  async function submitSMSUpdates() {
    setLoading(true);

    updateDoc(doc(firestore, "companies", companyId), {
      sendgrid: {
        key: accessKey,
        from: fromDetails,
        template_ids: templateIds,
      },
      updated: serverTimestamp(),
    }).then(() => {
      // complete
      setLoading(false);
      ShowNotificationSuccess("SendGrid Updated");
    });
  }

  const rows = Object.entries(SendGridTemplateIds)
    .sort((a, b) => a > b)
    .map(([key, value]) => (
      <tr key={key}>
        <td>{key.replace("Holiday", "Holiday ")}</td>
        <td>
          <TextInput
            placeholder="X-XXXXXX"
            disabled={isLoading}
            value={templateIds[value]}
            onChange={(e) => {
              switch (value) {
                case SendGridTemplateIds.HolidayApproved:
                  setTemplateIds({ ...templateIds, holiday_approved: e.currentTarget.value.trim() });
                  break;
                case SendGridTemplateIds.HolidayCanceled:
                  setTemplateIds({ ...templateIds, holiday_canceled: e.currentTarget.value.trim() });
                  break;
                case SendGridTemplateIds.HolidayDeclined:
                  setTemplateIds({ ...templateIds, holiday_declined: e.currentTarget.value.trim() });
                  break;
                case SendGridTemplateIds.HolidayRemind:
                  setTemplateIds({ ...templateIds, holiday_remind: e.currentTarget.value.trim() });
                  break;
                case SendGridTemplateIds.HolidayRequest:
                  setTemplateIds({ ...templateIds, holiday_request: e.currentTarget.value.trim() });
                  break;
                default:
                  alert("Unassigned Template Value");
                  break;
              }
            }}
            autoComplete="no"
            radius="lg"
          />
        </td>
      </tr>
    ));

  return (
    <Stack p={16} spacing={28}>
      <Title order={2}>SendGrid</Title>

      <TextInput
        label="API Key"
        placeholder={accessKey}
        disabled={isLoading}
        value={accessKey}
        onChange={(e) => setAccessKey(e.currentTarget.value.trim())}
        autoComplete="no"
        radius="lg"
      />

      <Stack spacing={4}>
        <TextInput
          label="From Name"
          placeholder={fromDetails["name"]}
          disabled={isLoading}
          value={fromDetails["name"]}
          onChange={(e) => setFromDetails({ ...fromDetails, name: e.currentTarget.value.trim() })}
          autoComplete="no"
          radius="lg"
        />
        <TextInput
          error={isEmail(fromDetails["email"]) ? "" : "Invalid Email"}
          label="From Email"
          placeholder={fromDetails["email"]}
          disabled={isLoading}
          value={fromDetails["email"]}
          onChange={(e) => setFromDetails({ ...fromDetails, email: e.currentTarget.value.trim() })}
          autoComplete="no"
          radius="lg"
        />
      </Stack>

      <Table withBorder>
        <thead>
          <tr>
            <th>Email Template Name</th>
            <th>SendGrid Template ID</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <Flex>
        <Button
          mt={16}
          disabled={
            !isEmail(fromDetails["email"])
              ? true
              : accessKey === companyData["sendgrid"]["key"] &&
                _.isEqual(companyData["sendgrid"]["from"], fromDetails) &&
                _.isEqual(companyData["sendgrid"]["template_ids"], templateIds)
          }
          onClick={submitSMSUpdates}
        >
          Update Details
        </Button>
      </Flex>
    </Stack>
  );
}
