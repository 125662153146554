import { Card, Image, Text, Badge, Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";

function CardItem({ id, title, imageUrl, description, badgeTitle, buttonTitle }) {
  const navigate = useNavigate();
  
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      {imageUrl && (
        <Card.Section>
          <Image src={imageUrl} height={200} alt="Norway" />
        </Card.Section>
      )}

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>{title}</Text>
        {badgeTitle && (
          <Badge color="green" variant="light">
            {badgeTitle}
          </Badge>
        )}
      </Group>

      <Text size="sm" color="dimmed">
        {description}
      </Text>

      {buttonTitle && (
        <Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={() => {
          if (id) {
            navigate(`/automations/${id}`)
          }
        }}>
          {buttonTitle}
        </Button>
      )}
    </Card>
  );
}

export default CardItem;
