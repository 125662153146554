import React, { useEffect } from "react";
import { createStyles, Title, Text, Button, Container, Group, rem } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(38),

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(500),
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

export default function PageNotFound() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Page Not Found - Cell Software Portal";
  });

  return (
    <Container className={classes.root}>
      {/* <div className={classes.label}>404</div> */}
      <Title className={classes.title}>Nothing to see here.</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        The page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to
        another URL. If you think this is an error, please contact support.
      </Text>
      <Group position="center">
        <Button variant="subtle" size="md" onClick={() => navigate(`/`)}>
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}
