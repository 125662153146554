import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  Modal,
  Center,
  Loader,
  Text,
  Badge,
  ActionIcon,
  Flex,
  CopyButton,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconX,
  IconCopy,
  IconPencil,
  IconTrash,
} from "@tabler/icons";
import { openConfirmModal, openModal } from "@mantine/modals";
import * as apiService from "../../api-service";
import ClientCreate from "./Create";
import ClientEdit from "./Edit";
import { ShowNotificationSuccess } from "../../components/Notifications";

function Clients({
  userId,
  userToken,
  companyId,
  companyData,
  userAccess,
  navButton,
}) {
  const [isCreateOpen, setCreateOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [members, setMembers] = useState([]);
  const [automationProjects, setAutomationProjects] = useState([]);
  const [testrailProjects, setTestrailProjects] = useState([]);
  const [JiraProjects, setJiraProjects] = useState([]);

  useEffect(() => {
    document.title = "Clients - Cell Software Portal";

    if (userAccess !== "employee") {
      navButton(
        <Button size="md" onClick={() => setCreateOpen(true)}>
          New Client
        </Button>
      );
    }

    return () => {
      navButton(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userToken) {
      apiService
        .getClients({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setMembers(data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
        });

      apiService
        .getAutomationProjects({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => setAutomationProjects(data))
        .catch((e) => {
          console.error(e);
        });

      apiService
        .getTestrailProjects({ userIdToken: userToken, companyId: companyId })
        .then((d) => setTestrailProjects(d))
        .catch((e) => {
          console.error(e);
        });

      apiService
        .getJiraBoards({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => setJiraProjects(data))
        .catch((e) => {
          console.error(e);
        });
    }
  }, [userToken, companyId, isCreateOpen]);

  const setCreateModalVisible = (bool, isCreated = true) => {
    setCreateOpen(bool);
    ShowNotificationSuccess(isCreated ? "Client Created" : "Client Deleted");
  };

  const openEditModal = ({ uid, name, claims }) => {
    openModal({
      title: "Update Client",
      centered: true,
      closeOnClickOutside: false,
      children: (
        <ClientEdit
          userToken={userToken}
          companyId={companyId}
          userId={uid}
          name={name}
          customClaims={claims}
          setModalVisible={setCreateModalVisible}
          projects={automationProjects}
          initTestrail={testrailProjects}
          jiraBoards={JiraProjects}
        />
      ),
    });
  };

  // Full process of deleting a user
  const confirmDelete = ({ uid }) => {
    openConfirmModal({
      title: <Text weight={700}>Delete Client?</Text>,
      centered: true,
      children: (
        <Text size="sm">
          Once deleted, they will no longer have access to your business.
        </Text>
      ),
      labels: { confirm: "Delete Client", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        // Get index of item
        deleteClient({ uid });
      },
    });
  };

  function deleteClient({ uid }) {
    if (userToken) {
      apiService
        .deleteUser({
          userIdToken: userToken,
          companyId: companyId,
          uid,
        })
        .then(() => window.location.reload(false))
        .catch((e) => console.error(e));
    }
  }

  const rows = members
    .sort(function (a, b) {
      if (a.email.toLowerCase() < b.email.toLowerCase()) return -1;
      if (a.email.toLowerCase() > b.email.toLowerCase()) return 1;
      return 0;
    })
    .map(({ uid, displayName, email, customClaims, emailVerified }) => (
      <tr key={uid}>
        <td>{displayName}</td>
        <td>{email}</td>
        <td>
          {emailVerified ? (
            <IconCheck size={26} color="green" />
          ) : (
            <IconX size={26} color="red" />
          )}
        </td>
        <td>
          {customClaims["automation_projects"]?.map((p) => {
            return automationProjects.map((ap) => {
              if (ap.id === p) {
                return (
                  <Badge key={ap.id} color={"cyan"} mr={8} variant={"light"}>
                    {ap.name}
                  </Badge>
                );
              } else {
                return "";
              }
            });
          })}
        </td>
        <td>
          {customClaims["jira_boards"]?.map((p) => {
            return JiraProjects.map((ap) => {
              if (ap.location.projectId === p) {
                return (
                  <Badge
                    key={ap.location.projectName}
                    color={"cyan"}
                    mr={8}
                    variant={"light"}
                  >
                    {ap.location.projectName}
                  </Badge>
                );
              } else {
                return "";
              }
            });
          })}
        </td>
        <td>
          {customClaims["testrail_projects"]?.map((p) => {
            return testrailProjects.map((ap) => {
              if (ap.id === p) {
                return (
                  <Badge key={ap.id} mr={8} variant={"light"}>
                    {ap.name}
                  </Badge>
                );
              } else {
                return "";
              }
            });
          })}
        </td>
        <td>
          {userAccess !== "employee" && (
            <Flex gap={8}>
              <Tooltip label="Edit Client" withArrow openDelay={500}>
                <ActionIcon
                  variant="subtle"
                  onClick={() =>
                    openEditModal({
                      uid: uid,
                      name: displayName,
                      claims: customClaims,
                      setModalVisible: setCreateModalVisible,
                    })
                  }
                >
                  <IconPencil size="1.25rem" />
                </ActionIcon>
              </Tooltip>
              <CopyButton value={uid} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy User ID"}
                    withArrow
                    openDelay={500}
                  >
                    <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                      {copied ? (
                        <IconCheck size="1.5rem" />
                      ) : (
                        <IconCopy size="1.25rem" />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
              <Tooltip
                label="Delete Client"
                withArrow
                color="red"
                openDelay={500}
              >
                <ActionIcon
                  variant="subtle"
                  color="red"
                  onClick={() => confirmDelete({ uid })}
                >
                  <IconTrash size="1.25rem" />
                </ActionIcon>
              </Tooltip>
            </Flex>
          )}
        </td>
      </tr>
    ));

  return (
    <>
      {/* Create modal */}
      <Modal
        centered
        closeOnClickOutside={false}
        opened={isCreateOpen}
        onClose={() => setCreateOpen(false)}
        title="Add A New Client"
      >
        <ClientCreate
          userToken={userToken}
          companyId={companyId}
          emailDomain={
            companyData.data() === undefined
              ? ""
              : companyData.data()["email_domain"]
          }
          setModalVisible={setCreateModalVisible}
        />
      </Modal>
      {/* Clients main */}
      <Paper shadow="md" p="lg" withBorder>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Verifed</th>
              <th>Automation Access</th>
              <th>Jira Access</th>
              <th>TestRail Access</th>
              {userAccess !== "employee" && <th />}
            </tr>
          </thead>
          <tbody>{!isLoading && rows}</tbody>
        </Table>
        {isLoading && (
          <Center mt="lg">
            <Loader size="lg" />
          </Center>
        )}
      </Paper>
    </>
  );
}

export default Clients;
