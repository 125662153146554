import { notifications } from "@mantine/notifications";
import { IconAlertTriangle, IconCircleCheck, IconCircleX, IconInfoCircle } from "@tabler/icons";

export function ShowNotificationSuccess(title, message, onClose) {
  notifications.show({
    title: title,
    message: message,
    icon: <IconCircleCheck />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    withCloseButton: false,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.teal[6],
        borderColor: theme.colors.teal[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.teal[7] },
      },
    }),
  });
}

export function ShowNotificationWarning(title, message, onClose) {
  notifications.show({
    title: title,
    message: message,
    icon: <IconAlertTriangle />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.orange[6],
        borderColor: theme.colors.orange[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.orange[7] },
      },
    }),
  });
}

export function ShowNotificationInfo(title, message, onClose) {
  notifications.show({
    title: title,
    message: message,
    icon: <IconInfoCircle />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.white[6],
        borderColor: theme.colors.white[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.white[7] },
      },
    }),
  });
}

export function ShowNotificationError(title, message, onClose) {
  notifications.clean();
  notifications.show({
    title: title,
    message: message,
    icon: <IconCircleX />,
    radius: "lg",
    autoClose: false,
    withCloseButton: true,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.red[6],
        borderColor: theme.colors.red[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.red[7] },
      },
    }),
  });
}
