import React, { useState, useEffect } from "react";
import { Button, Input, Stack, Switch, Title, Tabs, MultiSelect, Text, Flex } from "@mantine/core";
import { firestore } from "../../../firebase-config";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { IconSettings, IconUserSearch } from "@tabler/icons";
import * as apiService from "../../../api-service";
import _ from "lodash";

export default function BusinessEditJira({ companyData, companyId, userToken }) {
  const [isJiraBoardsEnabled, setIsJiraBoardsEnabled] = useState(companyData["jira"]["boards_enabled"] ?? false);
  const [jiraAPIKey, setJiraApiKey] = useState(companyData["jira"]["access_token"]);
  const [jiraEmail, setJiraEmail] = useState(companyData["jira"]["email"]);
  const [jiraDomain, setJiraDomain] = useState(companyData["jira"]["domain"]);

  const [isLoading, setLoading] = useState(false);

  const [allProjects, setAllProjects] = useState([]);
  const [selectedDurians, setSelectedDurians] = useState(companyData["durian_projects"] ?? []);

  async function submitJiraUpdates() {
    setLoading(true);
    updateDoc(doc(firestore, "companies", companyId), {
      jira: {
        email: jiraEmail,
        domain: jiraDomain,
        access_token: jiraAPIKey,
        boards_enabled: isJiraBoardsEnabled,
      },
      updated: serverTimestamp(),
    }).then(() => setLoading(false));
  }

  async function submitDurianUpdates() {
    setLoading(true);
    updateDoc(doc(firestore, "companies", companyId), {
      durian_projects: selectedDurians,
      updated: serverTimestamp(),
    }).then(() => setLoading(false));
  }

  useEffect(() => {
    if (userToken && isJiraBoardsEnabled) {
      apiService
        .getJiraBoards({
          userIdToken: userToken,
          companyId: companyId,
        })
        .then((data) => {
          setAllProjects(data);
          setLoading(false);
        });
    }
  }, [userToken, companyId, isJiraBoardsEnabled]);

  return (
    <>
      <Title pl="md">Jira Boards</Title>
      <Tabs variant="pills" radius="md" defaultValue="settings" p={16}>
        <Tabs.List>
          <Tabs.Tab value="settings" icon={<IconSettings size="1rem" />}>
            Settings
          </Tabs.Tab>
          <Tabs.Tab value="groups" icon={<IconUserSearch size="1rem" />}>
            Durian
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="settings" pt="sm">
          <Stack p="lg">
            <Input.Wrapper
              label="Primary User API Key"
              description="The primary user to be used to gain access to Jira."
              required
            >
              <Input
                disabled={isLoading}
                value={jiraAPIKey}
                onChange={(e) => setJiraApiKey(e.currentTarget.value.trim())}
                autoComplete="no"
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper
              label="Primary User Email"
              required
              description="Must be the email the API key was generated from."
            >
              <Input
                disabled={isLoading || !isJiraBoardsEnabled}
                value={jiraEmail}
                onChange={(e) => setJiraEmail(e.currentTarget.value.trim())}
                autoComplete="email"
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper
              label="Domain"
              description="The domain where your Jira is hosted. E.g. 'xxxx.atlassian.net'"
              required
            >
              <Input
                disabled={isLoading || !isJiraBoardsEnabled}
                value={jiraDomain}
                onChange={(e) => setJiraDomain(e.currentTarget.value.trim())}
                autoComplete="no"
                radius="lg"
                m={8}
              />
            </Input.Wrapper>

            <Input.Wrapper label="Enable Jira" mb={16}>
              <Switch checked={isJiraBoardsEnabled} onChange={(e) => setIsJiraBoardsEnabled(e.currentTarget.checked)} />
            </Input.Wrapper>

            <Button
              loading={isLoading}
              disabled={
                isJiraBoardsEnabled === companyData["jira"]["boards_enabled"] &&
                jiraAPIKey === companyData["jira"]["access_token"] &&
                jiraDomain === companyData["jira"]["domain"] &&
                jiraEmail === companyData["jira"]["email"]
              }
              onClick={submitJiraUpdates}
            >
              Update Details
            </Button>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="groups" pt="sm">
          <Stack p="lg">
            <Text>Select all the Jira projects below that are related to Durian only employees.</Text>
            <MultiSelect
              data={allProjects.map((p) => {
                return { value: p.id, label: p.location.displayName };
              })}
              value={selectedDurians}
              onChange={setSelectedDurians}
              label="Jira Projects"
              placeholder="List all available projects"
              searchable
              nothingFound="No projects found"
              clearable
            />

            <Flex>
              <Button
                loading={isLoading}
                disabled={_.isEqual(companyData["durian_projects"] ?? [], selectedDurians)}
                onClick={submitDurianUpdates}
              >
                Update Details
              </Button>
            </Flex>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
