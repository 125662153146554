import React, { useState, useEffect } from "react";
import { Button, Stack, TextInput, LoadingOverlay, Space, Title, MultiSelect } from "@mantine/core";
import { useForm } from "@mantine/form";
import * as apiService from "../../api-service";

function ClientEdit({ userToken, companyId, userId, name, customClaims, projects, initTestrail, jiraBoards }) {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const [projectNames, setProjectNames] = useState([]);
  const [selectedProjectNames, setSelectedProjectNames] = useState(
    projects.map((i) => {
      return i.name;
    })
  );

  const [testrailProjects, setTestrailProjects] = useState([]);
  const [selectedTestrailProjects, setSelectedTestrailProjects] = useState(undefined);

  const [jiraProjects, setJiraProjects] = useState([]);
  const [selectedJiraProjects, setSelectedJiraProjects] = useState(undefined);

  useEffect(() => {
    setProjectNames(
      projects.map((i) => {
        return i.name;
      })
    );
    setTestrailProjects(
      initTestrail.map((i) => {
        return i.name;
      })
    );
    setJiraProjects(
      jiraBoards.map((i) => {
        return i.location.projectName;
      })
    );

    const newList = [];
    customClaims["automation_projects"]?.forEach((ap) => {
      const pro = projects.find((pro) => {
        return ap === pro.id;
      });
      if (pro) {
        newList.push(pro.name);
      }
    });
    setSelectedProjectNames(newList);

    const newTestrail = [];
    customClaims["testrail_projects"]?.forEach((ap) => {
      const pro = initTestrail.find((pro) => {
        return ap === pro.id;
      });
      if (pro) {
        newTestrail.push(pro.name);
      }
    });
    setSelectedTestrailProjects(newTestrail);

    const newJira = [];
    customClaims["jira_boards"]?.forEach((ap) => {
      const pro = jiraBoards.find((pro) => {
        return ap === pro.location.projectId;
      });
      if (pro) {
        newJira.push(pro.location.projectName);
      }
    });
    setSelectedJiraProjects(newJira);
  }, [companyId, userId, customClaims, projects, initTestrail, jiraBoards]);

  function updateClient({ formName }) {
    setIsLoading(true);

    const autoProjectIds = [];
    // convert project names to their IDs
    selectedProjectNames.forEach((t) => {
      projects.forEach((p) => {
        if (p.name === t) {
          autoProjectIds.push(p.id);
        }
      });
    });

    const testrailIds = [];
    // convert project names to their IDs
    selectedTestrailProjects.forEach((t) => {
      initTestrail.forEach((p) => {
        if (p.name === t) {
          testrailIds.push(p.id);
        }
      });
    });

    const boardIds = [];
    // convert project names to their IDs
    selectedJiraProjects.forEach((t) => {
      jiraBoards.forEach((p) => {
        if (p.location.projectName === t) {
          boardIds.push(p.location.projectId);
        }
      });
    });

    if (userToken) {
      apiService
        .updateUser({
          userIdToken: userToken,
          companyId: companyId,
          uid: userId,
          name: formName,
          autoProjects: autoProjectIds,
          testrail: testrailIds,
          jira_boards: boardIds,
        })
        .then(() => window.location.reload(false))
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  }

  const form = useForm({
    initialValues: { name: name },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) => (value.length < 2 ? "Full Name must have at least 4 letters" : null),
    },
  });

  return (
    <Stack>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <form
        onSubmit={form.onSubmit((values) => {
          updateClient({ formName: values.name });
        })}
      >
        <Stack>
          <TextInput
            mt="sm"
            label="Full Name"
            placeholder="Joe Bloggs"
            description="First and Last name"
            {...form.getInputProps("name")}
            required
          />
          <Space spacing={8} />
          <Title order={3}>Options</Title>
          {/* Automation */}
          <MultiSelect
            data={projectNames}
            label="Automation Projects"
            description="Select the projects this client should be able to access."
            placeholder={selectedProjectNames === undefined ? "Loading..." : "Search for a project"}
            value={selectedProjectNames}
            disabled={selectedProjectNames === undefined}
            searchable
            nothingFound="No projects found"
            onChange={(e) => setSelectedProjectNames(e)}
            clearable
          />
          {/* JIRA */}
          <MultiSelect
            data={jiraProjects}
            label="Jira Boards"
            description="Select the boards this client should have access to."
            placeholder={selectedJiraProjects === undefined ? "Loading..." : "Search for a Jira board"}
            value={selectedJiraProjects}
            disabled={selectedJiraProjects === undefined}
            searchable
            nothingFound="No boards found"
            onChange={(e) => setSelectedJiraProjects(e)}
            clearable
          />
          {/* Testrail */}
          <MultiSelect
            data={testrailProjects}
            label="TestRail Projects"
            description="Select the projects this client should be able to access."
            placeholder={selectedTestrailProjects === undefined ? "Loading..." : "Search for a project"}
            value={selectedTestrailProjects}
            disabled={selectedTestrailProjects === undefined}
            searchable
            nothingFound="No projects found"
            onChange={(e) => setSelectedTestrailProjects(e)}
            clearable
          />
          {/* Login error text */}
          {loginError && <p style={{ color: "red" }}>{loginError}</p>}
          <Button type="submit" mt="xl" onClick={() => setLoginError(null)}>
            Update
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default ClientEdit;
