import React, { useState } from "react";
import {
  ScrollArea,
  Accordion,
} from "@mantine/core";
import DrawItemApproved from "./states/approved";
import DrawItemRequests from "./states/requests";
import DrawItemPending from "./states/pending";
import DrawItemDeclined from "./states/declined";
import DrawItemHistory from "./states/history";

export default function BookerHolidayDraw({
  allEvents,
  currentUser,
  companyData,
  companyId,
  employees,
  fetchEvents,
  initSelectedTab,
  userToken,
}) {
  const [employeeApprovalsFrom] = useState(companyData["employee_approvals_from"] ?? []);
  const [approvalsFrom] = useState([
    ...(employeeApprovalsFrom.filter((e) => e.id === currentUser?.uid)[0]?.admins ?? []),
    (companyData["emails_cc"] ?? []).map((rEmail) => {
      return employees.filter((emp) => emp.email === rEmail)[0]?.uid;
    })[0],
  ]);

  const allHolidays = allEvents.filter((d) => d.data().type === "holiday");

  const [selectedTab, setSelectedTab] = useState(initSelectedTab ?? "approved");

  return (
    <ScrollArea.Autosize>
      <Accordion value={selectedTab} onChange={setSelectedTab}>
        {/* REQUESTS */}
        <DrawItemRequests
          userToken={userToken}
          companyId={companyId}
          companyData={companyData}
          currentUser={currentUser}
          allHolidays={allHolidays}
          employees={employees}
          employeeApprovalsFrom={employeeApprovalsFrom}
          approvalsFrom={approvalsFrom}
          fetchEvents={() => fetchEvents()}
        />

        {/* PENDING */}
        <DrawItemPending
          companyId={companyId}
          companyData={companyData}
          currentUser={currentUser}
          allHolidays={allHolidays}
          employees={employees}
          employeeApprovalsFrom={employeeApprovalsFrom}
          approvalsFrom={approvalsFrom}
          fetchEvents={() => fetchEvents()}
        />

        {/* APPROVED */}
        <DrawItemApproved
          companyId={companyId}
          currentUser={currentUser}
          allHolidays={allHolidays}
          employees={employees}
          fetchEvents={() => fetchEvents()}
        />

        {/* DECLINED */}
        <DrawItemDeclined
          companyId={companyId}
          currentUser={currentUser}
          allHolidays={allHolidays}
          fetchEvents={() => fetchEvents()}
        />

        {/* HISTORY/PAST */}
        <DrawItemHistory allHolidays={allHolidays} employees={employees} />
      </Accordion>
    </ScrollArea.Autosize>
  );
}
