import React, { useEffect, useState } from "react";
import { Paper, Title, Stack, Menu, ActionIcon, Flex, Skeleton } from "@mantine/core";
import * as apiService from "../../api-service";
import { IconCheck, IconDotsVertical } from "@tabler/icons";

export default function ModuleAppleReleases() {
  const [isLoading, setIsLoading] = useState(true);
  const [releases, setReleases] = useState([]);

  const allOs = [
    "iOS",
    "iPadOS",
    "watchOS",
    "macOS",
    "tvOS",
    "Xcode"
  ];
  const [currentOs, setCurrentOs] = useState(localStorage.getItem("release_apple_selected_os") ?? "iOS");
  const [showBeta, setShowBeta] = useState(JSON.parse(localStorage.getItem("release_apple_show_beta")) ?? false);

  useEffect(() => {
    apiService
      .getAppleReleases({
        os: currentOs.toLocaleLowerCase(),
        showBeta: showBeta,
      })
      .then((data) => {
        localStorage.setItem("release_apple_show_beta", showBeta);
        setReleases(data);
        setIsLoading(false);
      })
      .catch((data) => {
        console.error(data);
        setIsLoading(false);
      });
  }, [currentOs, showBeta]);

  return (
    <Paper shadow="sm" radius="md" p="sm" withBorder miw={200}>
      <Stack spacing={0}>
        {isLoading && (
          <Stack spacing="xs" p={2}>
            <Skeleton height={28} radius="sm" />
            <Skeleton height={18} radius="sm" />
          </Stack>
        )}
        {!isLoading && (
          <Flex justify="space-between" align="center" gap={16}>
            <Title order={2} weight={800} lineClamp={1}>
              {(releases.length > 0 ? releases[0]?.title : "Unavailable").substring(0, (releases.length > 0 ? releases[0]?.title : "Unavailable").lastIndexOf("("))}
            </Title>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon color="blue" size="lg" disabled={isLoading}>
                  <IconDotsVertical size={28} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Select Operating System</Menu.Label>
                {allOs.map((i) => {
                  return (
                    <Menu.Item
                      key={i}
                      icon={currentOs === i ? <IconCheck size={16} /> : <></>}
                      disabled={currentOs === i}
                      onClick={() => {
                        setCurrentOs(i);
                        localStorage.setItem("release_apple_selected_os", i);
                      }}
                    >
                      {i}
                    </Menu.Item>
                  );
                })}
                <Menu.Divider />
                <Menu.Label>Build Types</Menu.Label>
                <Menu.Item icon={showBeta ? <IconCheck size={16} /> : <></>} onClick={() => setShowBeta(!showBeta)}>
                  Show Betas/RC
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        )}
        {!isLoading && releases[0]?.pubDate && (
          <Title order={5} weight={300} color="dimmed">
            {releases[0]?.pubDate.substring(0, 17)}
          </Title>
        )}
      </Stack>
    </Paper>
  );
}
