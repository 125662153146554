import {
  Image,
  Grid,
  Badge,
  Paper,
  Stack,
  Text,
  Title,
  Group,
  Avatar,
  TypographyStylesProvider,
  Accordion,
  Blockquote,
  Box,
  Card,
  rem,
  createStyles,
  getStylesRef,
  ScrollArea,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import moment from "moment";
import Moment from "react-moment";

export default function JiraBoardTicket({ userToken, companyId, userClaims, ticketData }) {
  const dateFormat = "MMMM DD yyyy • HH:mm";
  const cardHeight = 150;

  const useStyles = createStyles((theme) => ({
    card: {
      position: "relative",
      height: rem(cardHeight),
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],

      [`&:hover .${getStylesRef("image")}`]: {
        transform: "scale(1.03)",
      },
    },

    image: {
      ...theme.fn.cover(),
      ref: getStylesRef("image"),
      backgroundSize: "cover",
      transition: "transform 500ms ease",
    },

    overlay: {
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)",
    },

    content: {
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 1,
    },

    title: {
      color: theme.white,
      marginBottom: rem(5),
    },

    author: {
      color: theme.colors.dark[2],
    },
  }));
  const { classes } = useStyles();

  if (!ticketData) {
    return <></>;
  }

  function AccordionComments({ name, number, color }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }} ml="lg">
        <Badge variant="filled" color={color} w={44}>
          {number}
        </Badge>

        <Accordion.Control>
          <Title order={5}>{name}</Title>
        </Accordion.Control>
      </Box>
    );
  }

  return (
    <Paper p="md" shadow="md" radius="lg" mr={-8}>
      <Stack>
        <Title order={2}>{ticketData.fields.summary}</Title>
        <Grid grow align="center" mb={-2}>
          <Grid.Col span={1}>
            <Group>
              <Image src={ticketData.fields.issuetype.iconUrl} alt="issue type" width={16} height={16} />
              <Text fz="sm" ml={-8} weight={700}>
                {ticketData.key}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={1}>
            <Group>
              <Image src={ticketData.fields.priority.iconUrl} alt="issue type" width={16} height={16} />
              <Text fz="sm" ml={-8} weight={700}>
                {ticketData.fields.priority.name}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={5}>
            <Badge mt={-4} variant="filled" color={ticketData.fields.status.statusCategory.colorName}>
              {ticketData.fields.status.name}
            </Badge>
          </Grid.Col>
        </Grid>

        {/* DETAILS */}

        <Grid grow mb={8}>
          <Grid.Col span={6}>
            <Stack>
              <Text fz="sm" color="dimmed">
                Assigned To
              </Text>
              <Group mt={-10}>
                <Avatar
                  src={Object.values(ticketData.fields.assignee.avatarUrls)[0]}
                  radius="lg"
                  alt="issue type"
                  size="sm"
                />
                <Text fz="sm" ml={-8} weight={700} lineClamp={1}>
                  {ticketData.fields.assignee.displayName}
                </Text>
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Text fz="sm" color="dimmed">
                Created By
              </Text>
              <Group mt={-10}>
                <Avatar
                  src={Object.values(ticketData.fields.reporter.avatarUrls)[0]}
                  radius="lg"
                  alt="issue type"
                  size="sm"
                />
                <Text fz="sm" ml={-8} weight={700} lineClamp={1}>
                  {ticketData.fields.reporter.displayName}
                </Text>
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>

        <TypographyStylesProvider>
          <div dangerouslySetInnerHTML={{ __html: ticketData.fields.description }} />
        </TypographyStylesProvider>

        {/* Attachments */}

        <Accordion
          variant="contained"
          multiple
          hidden={ticketData.fields.attachment?.length === 0 && ticketData.fields.comment?.comments.length === 0}
        >
          {ticketData.fields.attachment?.length > 0 && (
            <Accordion.Item value="attachment">
              {/* <Accordion.Control>Comments</Accordion.Control> */}
              <AccordionComments name="Attachments" number={ticketData.fields.attachment.length} color="orange" />
              <Accordion.Panel>
                <ScrollArea h={cardHeight}>
                  {ticketData.fields.attachment.map((a) => {
                    return (
                      <Card
                        p="lg"
                        shadow="lg"
                        className={classes.card}
                        radius="md"
                        onClick={() =>
                          modals.open({
                            title: `${a.filename}`,
                            children: (
                              <>
                                <Image src={a.content} />
                                <Text mt={16} color="dimmed" align="right">
                                  {moment(a.updated).format(dateFormat)}
                                </Text>
                              </>
                            ),
                            centered: true,
                          })
                        }
                      >
                        <div className={classes.image} style={{ backgroundImage: `url(${a.thumbnail})` }} />
                        <div className={classes.overlay} />
                        <div className={classes.content}>
                          <div>
                            <Text size="lg" className={classes.title} weight={500}>
                              {a.filename}
                            </Text>
                            <Group position="apart" spacing="xs">
                              <Text size="sm" className={classes.author}>
                                {moment(a.updated).format(dateFormat)}
                              </Text>
                            </Group>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </ScrollArea>
              </Accordion.Panel>
            </Accordion.Item>
          )}

          {/* Comments */}

          {ticketData.fields.comment?.comments.length > 0 && (
            <Accordion.Item value="comments">
              {/* <Accordion.Control>Comments</Accordion.Control> */}
              <AccordionComments name="Comments" number={ticketData.fields.comment.comments.length} color="violet" />
              <Accordion.Panel>
                {ticketData.fields.comment.comments
                  .slice(0)
                  .reverse()
                  .map((c) => {
                    return (
                      <Blockquote
                        ml={-20}
                        cite={`- ${c.author.displayName} • ${moment(c.updated).format(dateFormat)}`}
                        icon={
                          <Avatar src={Object.values(c.author.avatarUrls)[0]} radius="lg" alt="issue type" size="sm" />
                        }
                      >
                        <TypographyStylesProvider>
                          <div dangerouslySetInnerHTML={{ __html: c.body }} />
                        </TypographyStylesProvider>
                      </Blockquote>
                    );
                  })}
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>

        {/* Created Updated */}

        <Grid grow>
          <Grid.Col span={6}>
            <Stack>
              <Text fz="sm" color="dimmed">
                Updated
              </Text>
              <Text fz="sm" mt={-10} weight={700} lineClamp={1}>
                <Moment format={dateFormat}>{ticketData.fields.updated}</Moment>
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Text fz="sm" color="dimmed">
                Created
              </Text>
              <Text fz="sm" mt={-10} weight={700} lineClamp={1}>
                <Moment format={dateFormat}>{ticketData.fields.created}</Moment>
              </Text>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  );
}
