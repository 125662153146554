import React, { useState } from "react";
import { Button, Stack, TextInput, PasswordInput, LoadingOverlay, Checkbox, Space, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons";
import { generate } from "@wcj/generate-password";
import * as apiService from "../../api-service";

function EmployeeCreate({ userToken, companyId, emailDomain, setModalVisible }) {
  const [formLoaderIsVisible, setFormLoaderIsVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isDisclosureVisible, setDisclosureVisible] = useState(true);

  const [sendVerification, setSendVerification] = useState(true);
  const [isUserAdmin, setUserAdmin] = useState(false);

  function submitNewEmployee({ name, email, password }) {
    if (userToken && companyId) {
      if (!email.includes("@" + emailDomain)) {
        // domain strict enabled, does not include company domain
        form.setFieldError("email", "Email needs to use company domain: @" + emailDomain);
      } else {
        setFormLoaderIsVisible(true);

        apiService
          .createUser({
            userIdToken: userToken,
            companyId: companyId,
            name: name,
            email: email,
            password: password,
            role: isUserAdmin ? "admin" : "employee",
            sendVerification: sendVerification,
          })
          .then((data) => {
            navigator.clipboard.writeText(password);
            setModalVisible(false);
          })
          .catch((e) => {
            console.error(e);
            setFormLoaderIsVisible(false);
          });
      }
    } else {
      alert("Unable to perform action. Please login again.");
    }
  }

  const form = useForm({
    initialValues: { name: "", email: "", password: generate({ length: 12 }) },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) => (value.length < 2 ? "Full Name must have at least 4 letters" : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
      password: (value) => (/^\S+$/.test(value) ? null : "Password is empty"),
    },
  });

  return (
    <Stack>
      <LoadingOverlay visible={formLoaderIsVisible} overlayBlur={2} />
      <form
        onSubmit={form.onSubmit((values) => {
          submitNewEmployee({
            name: values.name,
            email: values.email,
            password: values.password,
          });
        })}
      >
        <Stack>
          <TextInput
            mt="sm"
            label="Full Name"
            placeholder="Joe Bloggs"
            description="First and Last name"
            {...form.getInputProps("name")}
            required
          />
          <TextInput mt="sm" label="Email" placeholder="example@domain.com" {...form.getInputProps("email")} required />
          <Stack>
            <PasswordInput
              label="Password"
              description="Take note of this password. You won't be able to see this again. Password must include at least one letter, number and special character"
              placeholder="Password"
              {...form.getInputProps("password")}
              icon={<IconLock size={16} />}
              visible={isDisclosureVisible}
              onVisibilityChange={setDisclosureVisible}
              required
            />
            <Button
              color={"cyan"}
              onClick={() => {
                // Create a random password
                const randomPassword = generate({ length: 12 });

                // Set the generated password as state
                form.setFieldValue("password", randomPassword);
                setDisclosureVisible(true);
              }}
            >
              Regenerate Password
            </Button>
          </Stack>

          <Space spacing={8} />
          <Title order={3}>Options</Title>
          <Stack>
            <Checkbox
              label="Send Email Verification"
              description="Send an email for them to verify their account. A verified email is not required to login."
              checked={sendVerification}
              onChange={(e) => {
                setSendVerification(e.target.checked);
              }}
            />
            <Checkbox
              label="Admin Privileges"
              description="Should this user have admin privileges?"
              checked={isUserAdmin}
              onChange={(e) => {
                setUserAdmin(e.target.checked);
              }}
            />
          </Stack>
          {/* Login error text */}
          {loginError && <p style={{ color: "red" }}>{loginError}</p>}
          <Button
            type="submit"
            mt="xl"
            onClick={() => {
              setLoginError(null);
            }}
          >
            Create Employee
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default EmployeeCreate;
