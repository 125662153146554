import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Grid,
  Text,
  Title,
  SegmentedControl,
  Button,
  TypographyStylesProvider,
  ScrollArea,
  Flex,
  Accordion,
  Stack,
  Group,
  Loader,
  Highlight,
  Badge,
  rem,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { tagsFormat, tagsHtml } from "../../components/html-tags";
import { RemotePrams, getRemoteJSON } from "../../services/RemoteConfig";
import { IconCreditCard, IconUpload } from "@tabler/icons";

export default function HtmlVerification() {
  const [isLoading, setIsLoading] = useState(false);

  const [segmentView, setSegmentView] = useState("code");

  const [isChecking, setIsChecking] = useState(false);
  const [failedResults, setFailedResults] = useState([]);
  const [accordianSelected, setAccordianSelected] = useState("guildlines");
  const [isValidated, setIsValidated] = useState(false);

  const paperHeight = 750;
  const openRef = useRef(null);
  const [files, setFiles] = useState([]);
  let dropzoneAccepts = ["text/html", "text/plain"];
  let dropzoneLimit = 1;

  useEffect(() => {
    document.title = "HTML Verification - Cell Software Portal";
  },[]);
  // State to store the input value from the textarea
  const [htmlCode, setHtmlCode] = useState("");

  useEffect(() => {
    if (files.length === 1) {
      setFailedResults([]);
      setIsValidated(false);

      var reader = new FileReader();
      reader.onload = function () {
        setIsLoading(false);
        setHtmlCode(reader.result);
      };
      reader.readAsText(files[0]);
    }
  }, [files]);

  let tagsAllowed = getRemoteJSON(RemotePrams.HTML_ALLOWED_TAGS);
  // list of all invalid wording - MUST BE LOWERCASE
  let tagsNotAllowed = getRemoteJSON(RemotePrams.HTML_NOT_ALLOWED_TAGS);

  // Step 1
  function validateDocument() {
    if (htmlCode.length > 0) {
      setIsValidated(true);
      // file imported
      if (htmlCode.includes("</")) {
        // is html document
        validateTags();
      } else {
        // assume txt document
        validateNotAllowedTags();
      }
    } else {
      alert("Please import a TXT or HTML file");
    }
  }

  const validateTags = () => {
    setIsChecking(true);

    // check all found tags
    var temp = document.createElement("div");
    temp.innerHTML = htmlCode;

    var all = temp.getElementsByTagName("*");
    var tags = [];
    for (var i = 0, max = all.length; i < max; i++) {
      var tagname = all[i].tagName;
      if (tags.indexOf(tagname) === -1) {
        tags.push(tagname);
      }
    }

    tags.forEach((t) => {
      if (
        !tagsAllowed.includes(t) &&
        !(failedResults.includes(`<${t.toLocaleLowerCase()}`) && failedResults.includes(`</${t.toLocaleLowerCase()}>`))
      ) {
        // tag is not in the allowed list
        // failedResults.push(`<${t.toLocaleLowerCase()}`);
        failedResults.push(`</${t.toLocaleLowerCase()}>`);
      }
    });

    validateNotAllowedTags();
  };

  const validateNotAllowedTags = () => {
    setIsChecking(true);

    tagsNotAllowed.forEach((w) => {
      if (htmlCode.toLowerCase().includes(w.toLocaleLowerCase()) && !failedResults.includes(w)) {
        // found invalid word
        failedResults.push(w);
      }
    });

    setIsChecking(false);
    compileResults();
  };

  // Comppile and show results to user
  const compileResults = () => {
    if (failedResults.length > 0) {
      // if there are fails
      setAccordianSelected("results");
    }
  };

  function AccordionMain({ name, badge }) {
    return (
      <Flex align="center" ml={badge ? 16 : 0}>
        {badge && (
          <Badge variant="filled" color="red" w={40}>
            {badge}
          </Badge>
        )}
        <Accordion.Control>
          <Title order={3}>{name}</Title>
        </Accordion.Control>
      </Flex>
    );
  }

  return (
    <Grid>
      <Dropzone.FullScreen
        accept={dropzoneAccepts}
        loading={isLoading}
        maxFiles={dropzoneLimit}
        onDrop={(files) => {
          setIsLoading(true);
          setFiles(files);
        }}
        openRef={openRef}
      >
        <Text align="center">
          <Title>Drop The Document Here</Title>
        </Text>
        <Text size="sm" color="dimmed" inline mt={7} align="center">
          Only one can be validated at a time.
        </Text>
      </Dropzone.FullScreen>
      <Grid.Col span={8}>
        <Paper p="lg" shadow="xs" withBorder>
          {files.length === 0 ? (
            <Dropzone
              accept={dropzoneAccepts}
              loading={isLoading}
              maxFiles={dropzoneLimit}
              onDrop={(files) => {
                setIsLoading(true);
                setFiles(files);
              }}
              openRef={openRef}
            >
              <Group position="center" spacing="xl" style={{ minHeight: rem(344) }}>
                <Dropzone.Accept>
                  <IconUpload size="3.2rem" stroke={1.5} color="gray" />
                </Dropzone.Accept>
                <Dropzone.Idle>
                  <IconCreditCard size="3.2rem" stroke={1.5} />
                </Dropzone.Idle>

                <div>
                  <Text size="xl" inline>
                    Drag & Drop card artworks or click to Browse...
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Drop-in as many as you like. When there are cards already listed, you can still drop more onto this
                    page.
                  </Text>
                </div>
              </Group>
            </Dropzone>
          ) : (
            <>
              <Flex mb={16}>
                <Title order={2} lineClamp={2}>
                  {files[0]?.name ?? "Select A File"}
                </Title>
                <Button disabled={isLoading} variant="outline" ml={32} onClick={() => openRef.current()}>
                  Browse...
                </Button>
              </Flex>
              <ScrollArea
                pt={8}
                pb={8}
                h={paperHeight - files[0]?.type === "text/html" ? paperHeight - 50 : paperHeight - 100}
                type="always"
                offsetScrollbars
              >
                {segmentView === "code" && (
                  <Highlight
                    highlight={failedResults}
                    highlightColor="red"
                    // sx={(theme) => ({
                    //   '& [data-highlight="@list"]': { backgroundColor: "red" },
                    //   '& [data-highlight="font-family"]': { backgroundColor: "yellow" },
                    // })}
                  >
                    {htmlCode}
                  </Highlight>
                )}
                {segmentView === "prev" && (
                  <TypographyStylesProvider>
                    <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
                  </TypographyStylesProvider>
                )}
              </ScrollArea>
            </>
          )}

          {files[0]?.type === "text/html" && (
            <Flex justify={"flex-end"} mt={8}>
              <SegmentedControl
                data={[
                  { label: "HTML", value: "code" },
                  { label: "Preview", value: "prev" },
                ]}
                onChange={setSegmentView}
                value={segmentView}
              />
            </Flex>
          )}
        </Paper>
      </Grid.Col>
      <Grid.Col span={4}>
        <Paper p="md" shadow="sm" withBorder mb={16}>
          <Group grow>
            <Stack spacing={0}>
              <Text size={15} color="dimmed">
                Result
              </Text>
              <Title order={2} color={!isValidated ? "primary" : failedResults.length === 0 ? "green" : "red"}>
                {!isValidated ? "-" : failedResults.length === 0 ? "Passed" : "Failed"}
              </Title>
              {isChecking && <Loader variant="dots" color="orange" />}
            </Stack>
            <Button disabled={htmlCode.length === 0 ? true : isValidated} onClick={validateDocument}>
              Validate
            </Button>
          </Group>
        </Paper>

        <Accordion multiple variant="separated" value={accordianSelected} onChange={setAccordianSelected}>
          {failedResults.length > 0 && (
            <Accordion.Item value="results">
              <Paper shadow="sm" withBorder>
                <AccordionMain name="Results" badge={`${failedResults.length}`} />
                <Accordion.Panel mt={-16}>
                  {failedResults.map((t) => {
                    return (
                      <Paper key={t} p="sm" withBorder mt={8}>
                        <Flex align="center" gap={16}>
                          <Badge variant="filled" color="red">
                            REMOVE
                          </Badge>
                          <Text>{t.split("/")}</Text>
                        </Flex>
                      </Paper>
                    );
                  })}
                </Accordion.Panel>
              </Paper>
            </Accordion.Item>
          )}

          <Accordion.Item value="guildlines">
            <Paper shadow="sm" withBorder>
              <AccordionMain name="Guidelines" />
              <Accordion.Panel>
                <ScrollArea h={paperHeight} offsetScrollbars>
                  <Text>
                    <Title order={3}>HTML and block tags permitted</Title>
                    <Accordion variant="contained" m={"16px 0px 16px"}>
                      {tagsHtml.map((t) => {
                        return (
                          <Accordion.Item key={t.id} value={t.id}>
                            <Accordion.Control>&lt;{t.id.toUpperCase()}&gt;</Accordion.Control>
                            <Accordion.Panel>{t.description}</Accordion.Panel>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                    <Title order={3}>Formatting tags permitted</Title>
                    <Accordion variant="contained" m={"16px 0px 16px"}>
                      {tagsFormat.map((t) => {
                        return (
                          <Accordion.Item key={t.id} value={t.id}>
                            <Accordion.Control>&lt;{t.id.toUpperCase()}&gt;</Accordion.Control>
                            <Accordion.Panel>{t.description}</Accordion.Panel>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                    <Title order={3}>The T&Cs must not</Title>
                    <ul>
                      <li>utilise any internal or external hyperlinks </li>
                      <li>specify any specific font-style font-family, @font-face, @....</li>
                      <li>reference any external CSS files</li>
                    </ul>
                  </Text>
                </ScrollArea>
              </Accordion.Panel>
            </Paper>
          </Accordion.Item>
        </Accordion>
      </Grid.Col>
    </Grid>
  );
}
