import { Paper, Group, Avatar, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";

export function ProjectButton({ isActive, link, image, title, description, didClick, icon, ...others }) {
  return (
    <Paper
      p="md"
      shadow="sm"
      radius="md"
      style={{ backgroundColor: isActive ? "#248BE6" : "white", cursor: "pointer" }}
      onClick={() => didClick()}
    >
      <Group>
        <Avatar src={image} radius="xl" />
        <div style={{ flex: 1, maxWidth: 250 }}>
          <Text color={isActive ? "white" : "black"} size="sm" weight={600}>
            {title}
          </Text>
          <Text color={isActive ? "white" : "dimmed"} size="xs">
            {description}
          </Text>
        </div>
        {icon || <IconChevronRight color={isActive ? "white" : "gray"} size="1.25rem" stroke={2} />}
      </Group>
    </Paper>
  );
}
