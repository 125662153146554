import React, { useEffect, useState } from "react";
import {
  Image,
  SimpleGrid,
  Skeleton,
  Badge,
  Paper,
  Stack,
  Text,
  Accordion,
  Box,
  Title,
  Group,
  Loader,
  Grid,
  Avatar,
} from "@mantine/core";
import * as apiService from "../../../api-service";

export default function JiraBoardColumns({ userToken, companyId, userClaims, boardId, selectedTicket }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIssues, setIsLoadingIssues] = useState(false);

  const [config, setConfig] = useState({});
  const [issues, setIssues] = useState([]);

  const [selectedIssue, setSelectedIssue] = useState(null);

  useEffect(() => {
    if (userToken && companyId && boardId) {
      setIsLoading(true);
      apiService
        .getJiraBoardConfig({
          userIdToken: userToken,
          companyId: companyId,
          boardId: boardId,
        })
        .then((data) => {
          setConfig(data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });

      setIsLoadingIssues(true);
      apiService
        .getJiraBoardIssues({
          userIdToken: userToken,
          companyId: companyId,
          boardId: boardId,
        })
        .then((data) => {
          setIssues(data);
          setIsLoadingIssues(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoadingIssues(false);
        });
    }
  }, [userToken, companyId, userClaims, boardId]);

  function AccordionMain({ name, badge }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }} ml={16}>
        <Badge variant="filled" color={"blue"} w={48}>
          {isLoadingIssues ? <Loader color="white" size="xs" style={{ paddingTop: 4 }} /> : badge}
        </Badge>

        <Accordion.Control>
          <Title order={4}>{name}</Title>
        </Accordion.Control>
      </Box>
    );
  }

  if (isLoading)
    return (
      <SimpleGrid cols={1}>
        {[0, 1, 2, 3, 4, 5, 6].map((index) => {
          return <Skeleton key={index} radius="md" height={56} />;
        })}
      </SimpleGrid>
    );

  return (
    boardId && (
      <Paper shadow="sm" radius="md">
        <Accordion variant="contained" radius="md" defaultValue="To Do">
          {config?.columnConfig?.columns?.map((c) => {
            return (
              <Accordion.Item key={c.name} value={c.name}>
                <AccordionMain
                  name={c.name}
                  badge={issues.filter((i) => c.statuses.map((s) => s.id).includes(i.fields.status.id)).length}
                />
                <Accordion.Panel>
                  {isLoadingIssues ? (
                    <SimpleGrid cols={1}>
                      <Skeleton radius="md" height={72} />
                    </SimpleGrid>
                  ) : (
                    <Stack>
                      {issues
                        .filter((i) => c.statuses.map((s) => s.id).includes(i.fields.status.id))
                        .map((i) => {
                          return (
                            <Paper
                              key={i.id}
                              p="sm"
                              shadow="sm"
                              radius="md"
                              style={{
                                backgroundColor: selectedIssue === i.id ? "#248BE6" : "white",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedIssue(i.id);
                                selectedTicket(i);
                              }}
                            >
                              <Title order={5} pb={8} color={selectedIssue === i.id ? "white" : "black"}>
                                {i.fields.summary}
                              </Title>
                              <Grid mt={-8} align="center">
                                <Grid.Col span={6}>
                                  <Group>
                                    <Image src={i.fields.issuetype.iconUrl} alt="issue type" width={16} height={16} />
                                    <Text
                                      fz="sm"
                                      ml={-8}
                                      color={selectedIssue === i.id ? "white" : "black"}
                                      weight={700}
                                    >
                                      {i.key}
                                    </Text>
                                  </Group>
                                </Grid.Col>
                                <Grid.Col span={1} offset={4.5}>
                                  <Avatar
                                    src={Object.values(i?.fields?.assignee?.avatarUrls[0] ?? "")}
                                    radius="lg"
                                    alt="issue type"
                                    size="sm"
                                  />
                                </Grid.Col>
                              </Grid>
                            </Paper>
                          );
                        })}
                    </Stack>
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Paper>
    )
  );
}
